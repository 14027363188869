/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'autoarrange': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M3.969.53v.529H1.852v.53h2.117v.528l.529-.397.53-.397-.53-.397-.53-.397zm1.588 0v1.588h.263V.53h-.263zM1.059 1.852V3.97h-.53l.397.529.397.53.397-.53.397-.53h-.529V1.853h-.53zm1.587.793V3.704h1.06v-1.06h-1.06zm2.115 0V3.704h1.06v-1.06H4.76zM2.91 2.91h.53v.53h-.53v-.53zm2.118 0h.529v.53h-.53v-.53zM2.646 4.762V5.821h1.06V4.762h-1.06zm2.115 0V5.821h1.06V4.762H4.76zm-1.851.266h.53v.53h-.53v-.53zm2.118 0h.529v.53h-.53v-.53zm-4.499.53v.263h1.588v-.264H.53z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
