/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'datamap_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M1.192.544a.267.267 0 00-.266.263v1.016c0 .144.121.264.266.264H2.28l.408.612a.792.792 0 00-.288.451l-.549.29v-.25a.132.132 0 00-.132-.133H.66a.132.132 0 00-.132.132v.728c0 .073.06.132.132.132H1.72a.132.132 0 00.132-.132V3.74l.542-.288a.796.796 0 00.43.579l-.446.88H1.72a.132.132 0 00-.133.132v.529c0 .073.06.132.133.132h.794a.132.132 0 00.132-.132v-.53a.133.133 0 00-.011-.052l.448-.883c.03.004.061.01.093.01a.783.783 0 00.45-.142l.497.744.002-.001a.308.308 0 00-.023.112v.635a.32.32 0 00.316.319h.954a.32.32 0 00.317-.319v-.635a.319.319 0 00-.317-.316h-.954a.312.312 0 00-.1.02l-.5-.75a.79.79 0 00-.145-1.074l.735-.974h1.28a.132.132 0 00.133-.133V.808a.132.132 0 00-.132-.132H4.366a.132.132 0 00-.133.132v.719l-.792 1.05a.781.781 0 00-.265-.049.784.784 0 00-.253.046L2.59 2.07a.262.262 0 00.187-.248V.807a.265.265 0 00-.263-.263zm0 .661h1.322v.618H1.192zm1.984 1.587c.294 0 .53.235.53.53 0 .293-.236.528-.53.528a.528.528 0 01-.53-.529v-.003h.002l-.001-.004a.528.528 0 01.529-.523zm1.242 2.196h.954v.476h-.954z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
