<template>
	<v-container class="EDC-Row">
		<v-row class="EDC-Row" style="padding-bottom:2px !important;">
			<v-col class="EDC-Col" cols="2" align="left">
				<v-row class="EDC-Row">
					<v-col class="EDC-Col" cols="2" v-if="showNewVersion">
						<svgicon class="svg-icon-graph-toolbar svg-fill-graph-toolbar" name="New" @click="$emit('createNewVersion')" :original="true" title="Create New Version"></svgicon>
					</v-col>
					<v-col class="EDC-Col" cols="2">
						<svgicon class="svg-icon-graph-toolbar svg-fill-graph-toolbar" name="save_v2" @click="$emit('onSave')" :original="true" title="Save Changes"></svgicon>
					</v-col>
					<v-col class="EDC-Col" cols="2">
						<svgicon :class="{'svg-icon-graph-toolbar':true,'svg-fill-graph-toolbar':!isRedoActive,'svg-fill-graph-toolbar-active':isRedoActive}" name="Reverse_Operation" title="Undo (Ctrl + Z)" @click="performUndoRedo('KeyZ')"></svgicon>
					</v-col>
					<v-col class="EDC-Col" cols="2">
						<svgicon :class="{'svg-icon-graph-toolbar':true,'svg-fill-graph-toolbar':!isUndoActive,'svg-fill-graph-toolbar-active':isUndoActive}" name="Reverse_Operation" title="Redo (Ctrl + Y)" style="transform:rotateY(180deg)" @click="performUndoRedo('KeyY')"></svgicon>
					</v-col>
					<v-col class="EDC-Col" cols="2" v-if="showAutoArrange">
						<svgicon class="svg-icon-graph-toolbar svg-fill-graph-toolbar" name="autoarrange" title="Auto Arrange" @click="$emit('autoArrangeBusinessObject')"></svgicon>
					</v-col>
					<v-col class="EDC-Col" cols="2">
						<svgicon class="svg-icon-graph-toolbar svg-fill-graph-toolbar" name="object_notes" title="Add Object Description" @click="$emit('showDescription')"></svgicon>
					</v-col>
				</v-row>
			</v-col>
			<v-col class="EDC-Col" v-if="showTemplateOption" cols="2" align="left">
				<v-checkbox class="customCheckbox" hide-details dense label="Is Template" v-model="isTemplateSelected" @change="templateOptionChanged"></v-checkbox>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scopped>
	.customCheckbox >>> label {
		top:4px !important;
	}
</style>
<script>
	import config from "../../config.json";
	import {
		post as postToServer
	} from "./../../methods/serverCall.js";
	export default {
		name:'edcGraphToolbar',
		props:{
			redoCount:{
				type:Number,
				default:0
			},
			undoCount:{
				type:Number,
				default:0
			},
			showAutoArrange:{
				type:Boolean,
				default:false
			},
			showNewVersion:{
				type:Boolean,
				default:true
			},
			isTemplate:{
				type:Boolean,
				default:false
			},
			erpCode:{
				type:String,
				default:''
			}
		},
		data(){
			return{
				showTemplateOption:false,
				isTemplateSelected:false
			}
		},
		computed:{
			isRedoActive(){
				return this.redoCount > 0
			},
			isUndoActive(){
				return this.undoCount > 0
			}
		},
		watch:{
			redoCount:{
				handler(newvalue){

				}
			},
			undoCount:{
				handler(newvalue){

				}
			},
			showAutoArrange:{
				handler(newvalue){
					
				}
			},
			showNewVersion:{
				handler(newvalue){
					
				}
			},
			isTemplate:{
				handler(newValue){
					this.isTemplateSelected = newValue
				}
			},
			erpCode:{
				handler(newValue){
					this.manageShowTemplateOption()
				}
			}
		},
		mounted(){
			var _this = this
			// in case of edit we have to check the erp details
			setTimeout(()=>{
				_this.checkErpAvailablity()
			},2000)
			this.isTemplateSelected = this.isTemplate

		},
		methods:{
			performUndoRedo(key){
				this.$emit('performUndoRedo',{"ctrlKey":true,'code':key})
			},
			templateOptionChanged(){
				this.$emit('templateOptionChanged',this.isTemplateSelected)
			},
			checkErpAvailablity(){
				let _this = this
				let data = {"client_id":this.$session.get('client_id')}
				let end_point = config.REPOSITORY_API_URL + '/fetch_all_erp_version'
				postToServer(_this, end_point, data, true).then(response => {
					if(response && response.length){
						_this.showTemplateOption = true
						_this.manageShowTemplateOption()
					}
				}).catch(error=>{

				})
			},
			manageShowTemplateOption(){
				if(this.erpCode === 'CST')
					this.showTemplateOption = false
			}
		}
	}
</script>