<template>
	<v-container class="EDC-Row" style="border:1px solid black;width:20%;overflow: hidden;" :style="{'height':containerHeight}">
		<v-row class="EDC-Row" style="height:40px;background-color:#E0E0E0;position:relative;top:0px;">
			<v-col class="EDC-Col" cols="10">
				<v-text-field v-model="tableSearch"  id="tbl_searchbox" dense hide-details style="padding-left:8px;" v-on:keyup="initiateServerCallTimer($event)" autocomplete="off"></v-text-field>
			</v-col>
			<v-col cols="2">
				<svgicon class="svg-icon-mini svg-fill-mini" name="Search"></svgicon>
			</v-col>
		</v-row>
		<v-row class="EDC-Row">
			<v-progress-linear v-if="indeterminate" :indeterminate="indeterminate" style="margin:0px;"></v-progress-linear>
		</v-row>
		<!-- TO DO: list height should not be hard coded-->
		<v-row class="EDC-Row  overflow-y-auto  style-2scroll" style="height:90% !important;">
			<v-col class="EDC-Col" cols="12">
				<v-treeview  id="objectTree" dense v-model="selectedObject" :items="tableListTree" selection-type="independent" :search="tableSearch" style="text-align:left;" :open="openTree">
					<template v-slot:label="{item}">
						<draggable @start="startDrag($event,item)" @end="endDrag">
							<v-row class="EDC-Row" v-if="!item.isTable">
								<v-col class="EDC-Col text-truncate" :title="item.name">
									<span>{{item.name}}</span>
								</v-col>
							</v-row> 
							<v-row v-else class="EDC-Row" style="cursor:pointer !important;">					
								<v-col cols="6"  class="EDC-Col text-truncate" :title="item.name">
									<svgicon  class="svg-icon" name="Database_Table" :original="true" title="Business Object" style="margin-right:4px !important;"></svgicon>

									{{item.name}}
								</v-col>
								<v-col class="EDC-Col text-truncate" :title="item.description">
									{{item.description}}
								</v-col>
							</v-row>
						</draggable>
					</template>
				</v-treeview>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scopped>
	.v-treeview-node__level {
		width: 8px !important;
	}
</style>
<script>
	import draggable from 'vuedraggable'
	import {getTablesByDVEnv} from "../methods/agent_api_call.js"

	export default {
		name:'TableListWithTree',
		components:{
			draggable,
		},
		props:['containerHeight','readonly','graphFor','tableListObj'],
		data(){
			return {
				envDetails:{},
				tableListTree:[],
				selectedObject:[],
				openTree:[],
				tableSearch:'',
				indeterminate:false,
				availableBusinessObjectsByDS:[],
				serverCallForTable:null,
			}
		},
		watch:{
			'containerHeight':{
				handler(newvalue){

				}
			},
			'tableListObj':{
				handler(newvalue){
					let tableDetails = _.cloneDeep(newvalue)
					this.envDetails = {"available_datasource_list":tableDetails.available_datasource_list}
					// console.log("tableDetails ",tableDetails)
					this.manageTableTree(tableDetails,false)
					if(tableDetails.available_table_list){
						this.pulloutBusinessObjects(_.cloneDeep(tableDetails.available_table_list))
					}
				}
			}
		},
		mounted(){

		},
		methods:{
			pulloutBusinessObjects(availableTableList){
				for(let i=0;i<availableTableList.length;i++){
					let currentObj = availableTableList[i]
					let availableObjectList = _.filter(currentObj.table_list,(obj)=>{
						return obj.object_type === 'BO'
					})
					currentObj['table_list'] = availableObjectList
					this.availableBusinessObjectsByDS.push(currentObj)
				}
			},
			startDrag(event,item){
				if(this.readonly)
					return
				if(item.type == 'T')
			        item.type = 'table';
			    else if(item.type == 'A')
			       item.type = 'alias';
			    else if(item.type == 'V')
			       item.type = 'view';
			    else
			       item.type = 'BO';
			     
				this.$emit('dragged-object', item);
			},
			endDrag(){

			},
			manageTableTree(response,addBO){
				var _this = this
				var tableList = []
				
				if(response.available_datasource_list){

					// if(addBO && this.availableBusinessObjectsByDS.length){
					// 	_.forEach(this.availableBusinessObjectsByDS,(boObj,indx)=>{
					// 		if(boObj && boObj.table_list && boObj.table_list.length){

					// 		}
					// 	})
					// }

					_.forEach(response.available_table_list,(obj,indx)=>{
						if(addBO && _this.availableBusinessObjectsByDS.length){
							let boLisObj = _.find(_this.availableBusinessObjectsByDS,['datasource_id',obj.datasource_id])
							if(boLisObj && boLisObj.table_list){
								obj.table_list = boLisObj.table_list.concat(obj.table_list)
							}
						}
						tableList.push(_this.getTreeObject(obj,indx))
					})
				}
				_this.tableListTree = tableList
				_this.openTree = [tableList[0].id]
			},
			getTreeObject(tableObj,indx){
				tableObj.id = tableObj.datasource_id
				tableObj.name =(indx+1) +" : "+ tableObj.datasource_name
				tableObj['children'] = []
				_.forEach(tableObj.table_list,(obj,idx)=>{
					obj.isTable = true
					obj.id = idx+tableObj.id
					obj.datasource_id = tableObj.id
					obj.name = obj.title
					obj.datasource_index = indx
					obj.table_description = obj.description?obj.description:''
					tableObj['children'].push(obj)
				})
				return tableObj
			},
			initiateServerCallTimer(input){
				var _this = this
				clearInterval(_this.serverCallForTable)
			    _this.serverCallForTable = setTimeout(function(){
			        _this.performTableSearch(input)
			    },800)
			},
			performTableSearch(event){
				var _this = this
				this.envDetails['search_text'] = this.tableSearch
				this.envDetails['description_needed'] = true
				this.envDetails['check_in_description'] = true
				this.indeterminate = true
				getTablesByDVEnv(
					_this,_this.envDetails
				).then(tableResponse => {
				 this.indeterminate = false
				 _this.manageTableTree(tableResponse,true)
				})
				.catch(errorResponse => {
					this.indeterminate = false
					alert('error in server serach')
				});
			}
		}
	}
</script>