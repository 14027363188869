<template>
    <v-flex xs12 id="criteria_output_panel" style="overflow:auto;">
                <!-- {{columns_for_filter}} -->
        <table border="0" class="table table-striped table-bordered table-fixed" 
            id="filter_column_table" style="table-layout:fixed;margin-bottom:2px !important;">
            <!-- <thead>
                <tr>
                    <th style="width:30px"></th>
                    <th style="width:70px">(</th>
                    <th style="width:125px" v-if="filtertablename && filtertablename.length <=15">{{filtertablename || 'Column name'}}</th>
                     <th style="width:125px" :title="filtertablename" v-else>{{getFilterTableName(filtertablename) || 'Column name'}}</th>
                     <th style="width:90px">Operator</th>
                    <th style="width:150px"><span v-if="isBetween">From Value</span><span v-else> Value</span></th>
                    <th style="width:150px" v-if="isBetween">To Value</th>
                    <th style="width:70px">)</th>
                    <th style="width:90px">Operation</th>
                    <th style="width:90px">Action</th>
                </tr>
                </thead> -->
                <draggable v-model="columns_for_filter" :element="'tbody'">
                <template v-for="(filter_c, idx) in columns_for_filter">
                    <tr :key="idx">
                        <td class="handle" style="width: 30px !important;font-size:15px !important;">::</td>
                        <td :class="{highlight_tbl:filter_c.error_list && filter_c.error_list.column_name}" :title="filter_c.error_list && filter_c.error_list.column_name">
                            <v-autocomplete dense hide-details :items="column_name_list" v-model="filter_c.column_name" item-text="column_name" style="padding:0px" :title="filter_c.column_name"
                                placeholder="Column name" @input="resetIsDrvTable(filter_c.column_name, idx)" hide-details :disabled="readonly || filter_c.isEditable === false"></v-autocomplete>
                            <input type="hidden" v-model="filter_c.is_drv_table">
                        </td>
                         <td :class="{highlight_tbl:filter_c.error_list && filter_c.error_list.operator}" :title="filter_c.error_list && filter_c.error_list.operator">
                            <v-autocomplete dense hide-details :items="filter_operators" item-text="name" item-value="id" v-model="filter_c.operator"
                                @input="findBetween(filter_c,true)" placeholder="Operator" style="padding:0px" :title="getOperatorNameById(filter_c.operator)" hide-details :disabled="readonly || filter_c.isEditable === false"></v-autocomplete>
                        </td>
                        <td>
                            <v-layout row>
                                <v-flex xs10 pl-3>
                                    <v-text-field dense v-model="filter_c.value1" :disabled="filter_c.operator == '_in_' || filter_c.operator == '_is_n_'  
                                        || filter_c.operator == '_is_nt_n_' || filter_c.operator == '_n_in_' || filter_c.v_type1=='Date'" 
                                        placeholder="Value" @blur="savedata(true)" solo hide-details :readonly="readonly || filter_c.isEditable === false" autocomplete="off" :title="filter_c.value1"></v-text-field>
                                </v-flex>
                                <v-flex xs2>
                                    <span @click="changeValueType(idx, 'value1', 'v_type1',filter_c.operator)" class="valueType"><i class="fa fa-edit"></i></span>
                                </v-flex>
                            </v-layout>
                        </td>
                        <td v-if="isBetween">
                            <v-layout row v-if="filter_c.operator == '_bet_'">
                                <v-flex xs10 pl-3>
                                    <v-text-field dense v-model="filter_c.value2" placeholder="Value"
                                        @blur="savedata(true)" :disabled="filter_c.v_type2 !== 'Value'" solo hide-details :readonly="readonly || filter_c.isEditable === false" autocomplete="off" :title="filter_c.value2"></v-text-field>
                                </v-flex>
                                <v-flex xs2>
                                    <span @click="changeValueType(idx, 'value2', 'v_type2',filter_c.operator)" class="valueType"><i class="fa fa-edit"></i></span>
                                </v-flex>
                            </v-layout>
                        </td>
                        <td style="width:80px !important;">
                            <toggle-button :key="idx+'toggle'" v-if="columns_for_filter.length-1 != idx" :labels="{checked: 'OR', unchecked: 'AND'}"
                                style="margin-top:0px !important;margin-bottom:0px !important;" v-model="filter_c.operation" :sync="true" :width="60" :color="{checked: '#75C791', unchecked: '#75C791', disabled: '#CCCCCC'}"
                                @change="savedata(true)" :disabled="readonly || filter_c.isEditable === false" />
                        </td>
                        <td style="width:120px !important;">
                            <!-- <v-icon @click="add_new_filter_row()" v-if="(idx+1) == columns_for_filter.length" style="color:#494949; margin-right: 10px;font-size:17px;margin-top:0px;" title="Add New Filter">fa-plus-square</v-icon> -->
                            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" @click="add_new_filter_row()" v-if="(idx+1) == columns_for_filter.length"  name="add_v2" title="Add New Filter"></svgicon>

                            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-if="(idx+1) == columns_for_filter.length && level<3" @click="addgroup" name="add_multiple_v2" title="Add New Group"></svgicon>

                            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" @click="delete_filter_row(idx)" name="delete_v2" title="Delete Filter" v-if="filter_c.isDeletable != false"></svgicon>

                            <!-- <v-icon @click="delete_filter_row(idx)" style="color:#494949;font-size:17px;margin-top:0px;" title="Delete Filter">fa-trash-alt</v-icon> -->
                        </td> 
                    </tr>
                </template>
                </draggable>
        </table>
        <v-dialog persistent eager v-model="valuetype" width="400" style="overflow-y:none;background:white">
            <value-type @close="closeValuePanel" :valuetype="valuetype" @saveValue="saveValue" 
               :columns_for_filter="columns_for_filter" :criteriaRowIndex="criteriaRowIndex" :policy_list="policy_list" 
               :showpolicies="showpolicies" :column_name_list="column_name_list" :currentKey="currentKey" 
               :currentValueTypeKey="currentValueTypeKey" :criteriaoperator='currentOperator' :parentsteptype="parentsteptype" :acceleratorObj="acceleratorObj" :readonly="readonly"></value-type>
        </v-dialog>
    </v-flex>
</template>
<script>
import ValueType from './ValueType'
import {JOIN_OPERATOR_ARRAY_JSON,JOIN_TYPE,AGGREGATE_FUNCTIONS, FLITER_JOIN_OPERATOR_ARRAY_JSON,GRAPH_TABLE_ERROR_MESSAGES} from '@/methods/flowcharts/constants.js'
import draggable from 'vuedraggable'
export default {
      data () {
        return {
            v_type:"Value",
            criteriaRowIndex:0,
            currentKey:'',
            currentValueTypeKey:'',
            currentOperator:'',
            valuetype:false,
            start_parentheses_list: [{'value':'('}, {'value':'(('}, {'value':'((('}, {'value':'(((('}],
            end_parentheses_list: [')', '))', ')))', '))))'],
            filter_operators:FLITER_JOIN_OPERATOR_ARRAY_JSON,
            isBetween:false,
        }
      },
      components:{
          'value-type':ValueType,
          draggable
      },
     props:{filtertablename:String,columns_for_filter: Array, column_name_list: Array, policy_list: Array, showpolicies: Boolean,parentsteptype:String,acceleratorObj:Object,readonly:Boolean,level:Number,grpindex:Number,parentid:Number,restrictedColumnsForFilter:Array},
          watch:{
              columns_for_filter:{
                  handler(newVal){
                    // console.log(newVal)
                    // On tiffany, we got an issue , on redesign between was not getting updated.We need to call that here. If any issue cause comment below line.Date-23rd Feb 2022
                    this.findBetween()
                  },
                  deep:true
              },
              column_name_list:{
                handler(newVal){
                    //   debugger;
                      this.column_name_list=newVal
                  },
                  deep:true
              },
              acceleratorObj(newvalue){

              },
              readonly(newvalue){

              },
              level(newvalue){
              },
              grpindex(newvalue){
              },
              parentid(nuwvalue){
              },
              policy_list(newvalue){
                console.log('criteriapolicy',newvalue)
              },
              showpolicies(newvalue){

              },
              parentsteptype(newvalue){

              }
          },
    mounted() {
      var _this =this
      setTimeout(function(){
        _this.findBetween({})
      },1000)
    },
     methods:{
         closeValuePanel(){
             this.valuetype = false;
         },
         saveValue(value, columns_for_filter, v_type){
            //  debugger;
             this.v_type = v_type;  
             this.columns_for_filter = columns_for_filter;
             this.columns_for_filter[this.criteriaRowIndex][this.currentKey] = value;
             this.columns_for_filter[this.criteriaRowIndex][this.currentValueTypeKey] = v_type;
             this.savedata(true);
         },
         changeValueType(index, key, v_type_Key,operator){
             this.valuetype = true;
             // this.currentValueTypeKey = "Value"
             this.criteriaRowIndex = index;
             this.currentKey = key;
             this.currentValueTypeKey = v_type_Key;
             this.currentOperator = operator
         },
         resetIsDrvTable(c_name, idx){
            var _this = this
            let columnWithTableName =  c_name.split('.')
            let actualColName = columnWithTableName[0]
            if(columnWithTableName.length > 1)
                actualColName = columnWithTableName[1]
            if(this.restrictedColumnsForFilter && this.restrictedColumnsForFilter.length && this.restrictedColumnsForFilter.indexOf(actualColName)>-1){
                alert('You cannot apply the filter on the column '+actualColName)
                _this.$nextTick(()=>{
                    _this.columns_for_filter[idx].column_name = ''
                })
                return
            }

            for(var i=0;i< this.column_name_list.length;i++){
              // this.column_name_list[i][c_name] sometimes come as bool and sometime string (existing design)
                if(this.column_name_list[i][c_name] === true || this.column_name_list[i][c_name] === "true"){
                  this.columns_for_filter[idx].is_drv_table = true
                  this.columns_for_filter[idx].ref_row_id = this.column_name_list[i]['ref_row_id']
                  this.columns_for_filter[idx].ref_step_id = this.column_name_list[i]['ref_step_id']
                break;
                }else{
                  this.columns_for_filter[idx].is_drv_table = false
                }
            }
            this.savedata(true)
        },
        findBetween(obj,isUserAction){
            let _this = this;
            _this.isBetween = false;
            _this.columns_for_filter && _this.columns_for_filter.map(obj=>{
                if(obj.operator == '_bet_') 
                _this.isBetween = true;
            });
            if(obj && obj.operator == '_in_'){
               obj.value1 = "";
               obj.value2 = "";
            }
            this.savedata(isUserAction);
            return _this.isBetween
        },
        add_new_filter_row(){
            if(this.readonly)
              return
            var last_index = this.columns_for_filter.length
            if(last_index>0)

              if(!this.columns_for_filter[last_index-1].column_name || !this.columns_for_filter[last_index-1].operator)
                return

             this.$emit('new',this.level,this.grpindex,this.parentid,true);
        },
        delete_filter_row(idx){
            if(this.readonly)
              return
            this.$emit('delete', idx,this.level,this.grpindex,this.parentid);
        },
        savedata(isUserAction){
            if(this.readonly)
              return
            // If user perform filter columns up down then its create an bug.
            // Bug is that operation key doesnot work with drag drop. its values not get change
            // delete operation key from last filter
            var last_index = this.columns_for_filter.length
            if(last_index>0){
              if(!this.columns_for_filter[last_index-1].column_name || !this.columns_for_filter[last_index-1].operator)
              // if(!this.columns_for_filter[last_index-1].column_name && !this.columns_for_filter[last_index-1].operator)
                return

              delete this.columns_for_filter[last_index-1].operation
            }
            // Asign operation key to all filter who has not operation key
            // -2 because we are not going to append operation for last one
            for (var i = last_index - 2; i >= 0; i--) {
              if(!this.columns_for_filter[i].operation)
                this.columns_for_filter[i]["operation"] = false
            }
            this.$emit('savedata',this.columns_for_filter,this.level,this.grpindex,this.parentid,isUserAction);
        },
        getFilterTableName(filtertablename)
        {
          if (!filtertablename)
            return false
          return filtertablename.substring(0,12) + '...'
        },
        getOperatorNameById(operator)
        {
          if (operator){
            var obj = _.find(this.filter_operators,['id',operator])
            if(obj)
            return obj.name
          }
          return false
        },
        addgroup(){
          if(this.readonly)
              return
          var last_index = this.columns_for_filter.length
          if(last_index>0)
            if(!this.columns_for_filter[last_index-1].column_name || !this.columns_for_filter[last_index-1].operator)
              return

          this.$emit('addgroup',this.level,this.grpindex,true)
        },
        getItemText(column){

        }
    }
}
</script>
<style scoped>
    #filter_column_table .v-input__slot input, .v-select-list .v-list__tile__content{
        font-size: 12px;
    }

    #filter_column_table .v-input__slot{
        margin-bottom: -10px;
        margin-top: -5px;
    }
</style>
