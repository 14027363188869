export const DEFAULT_DESCRIPTION = ''
export function getColDescription(substringIndex,column_name,all_col_description,is_drv_table)
    {
      if (all_col_description.length == 0)
        return DEFAULT_DESCRIPTION

      // incase of drv table we will match the whole column name. 
      // In case of drv table we storing whle column name
      if (is_drv_table == true || is_drv_table === 'true')
        substringIndex = 0
      let col_alias = column_name.substring(substringIndex)
      var description_obj = _.find(all_col_description,["column_name",col_alias])
      if (description_obj && description_obj.description)
        return description_obj.description
      return DEFAULT_DESCRIPTION
    }