<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
			<v-col class="EDC-Col" cols="5" style="padding-left:8vw !important;">
				<v-card class="list-card">
		            <edc-toolbar class="EDC-Custom-Toolbar" key="available_order_columns_toolbar" customLabel="Columns Available For Order" usedFor="list" bindWithElement="sourceOrderColumnList" @perform-serach="performSearch" :clearTooltipIndex="clearTooltipIndex"></edc-toolbar>
					<v-list dense id="sourceOrderColumnList" class="EDC-list-for-columns overflow-y-auto style-2scroll" max-height="300">
		                <draggable v-model="sourceOrderColumnList" @start="dragListStart($event,sourceOrderColumnList,'sourceorder')" :options="dragOptions" :move="onMove" id="dragSourceOrderColumnList"  @end="selectDragEnd" group="CC">
						     <v-list-item dense selectable v-for="(item, i) in sourceOrderColumnList" :key="i+'sourcecolumnsfororder'" :class="{'custom-list-item':true, 'not-moved-item':!item.is_moved}" v-show="!item.is_moved" :title="item.description">
								{{item.display_name}}
						     </v-list-item>
		                </draggable>
					</v-list>
		            <v-row class="EDC-Row" style="padding-top:8px !important;">
		                <v-col cols="6" class="EDC-Col" align="center">
			                    <!-- Selected Object: {{selectedNode.name || 'N/A'}} -->
		                </v-col>
		                <v-col cols="6" class="EDC-Col" align="right">
			               Total Available Columns: {{sourceOrderColumnList.filter(item => item.is_moved === false).length}}
		                </v-col>
				    </v-row>   
				</v-card>
			</v-col>
			<v-col class="EDC-Col" cols="1"></v-col>
			<v-col class="EDC-Col" cols="5" style="padding-right:8vw !important;">
				<v-card class="list-card">
		            <edc-toolbar class="EDC-Custom-Toolbar" key="selected_order_columns_toolbar" customLabel="Selected Order Columns" usedFor="list" bindWithElement="orderByColumnList" @perform-serach="performSearch" :clearTooltipIndex="clearTooltipIndex"></edc-toolbar>

					<v-list dense id="orderByColumnList" class="EDC-list-for-columns overflow-y-auto style-2scroll" max-height="300">
						<draggable v-model="orderByColumnList" @start="dragListStart($event,orderByColumnList,'orderby')" :options="dragOptions" group="DD" :move="onMove" id="dragOrderByColumnList"  @end="selectDragEnd">
							<v-list-item dense selectable v-for="(item, i) in orderByColumnList" :key="i+'ordercolumnList'" :class="{'custom-list-item':true, 'not-moved-item':item.column_name}" :title="item.description">
								<v-col cols="8" class="EDC-Col">
									{{item.display_name}}
								</v-col>
								<v-col cols="4" class="EDC-Col EDC-RowPadding" style="text-align:right !important;padding-top:4px !important;padding-bottom:4px !important;">
									<toggle-button :key="i+'ordertoggle'"  :labels="{checked: 'ASC', unchecked: 'DESC'}"
									style="margin-bottom:0px !important;margin-right:5px !important;" v-model="item.order_type" :sync="true"  :width="64" :height="20" :color="{checked: '#607D8B', unchecked: '#607D8B', disabled: '#CCCCCC'}"/>
								</v-col>
							</v-list-item>
						</draggable>
					</v-list>
		            <v-row class="EDC-Row" style="padding-top:8px !important;">
		                <v-col cols="8" class="EDC-Col" align="center">
			                    <!-- Selected Object: {{selectedNode.name || 'N/A'}} -->
		                </v-col>
		                <v-col cols="4" class="EDC-Col" align="right">
			               Selected Columns: {{orderByColumnList.length}}
		                </v-col>
				    </v-row> 
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scoped>
	.list-card{
		margin-top:2% !important;
		margin-bottom:2% !important;
		height:368px !important;
	}

	.v-list-item--dense, .v-list--dense .v-list-item {
	    min-height: 16px important;
	}

	.custom-list-item {
		margin-bottom: 0px !important;
	    color:#000000;
	    cursor:pointer !important;
	}

	.v-list-item {
		padding-left:16px !important;
		padding-top:0px !important;
		padding-right:8px !important;
		padding-bottom:0px !important;
	}


	.EDC-list-for-columns {
		max-height:336px !important;
		min-height:336px !important;
	}

	.rounded-card {
	    max-height: 700px !important;
	}

	.EDC-Custom-Toolbar {
	    padding:0px !important;
	}
</style>
<script>
   	import edctoolbar from './edctoolbar.vue'
	import draggable from 'vuedraggable'

 	export default {
 		name:'OrderByPanel',
 		components:{
 			draggable,
            'edc-toolbar':edctoolbar,
 		},
 		props:{
 			availableColumns:{
 				type:Array,
 				default:()=>{
 					return []
 				}
 			},
 			columnsForOrderBy:{
 				type:Array,
 				default:()=>{
 					return []
 				}
 			}
 		},
 		data(){
 			return{
 				sourceOrderColumnList:[],
 				orderByColumnList:[],
 				clearTooltipIndex:0,
 			}
 		},
 		watch:{
 			'availableColumns':{
 				handler(newvalue){
 					if(!newvalue)
 						return
 					this.clearTooltipIndex++
 					this.manageSourceColumns(_.cloneDeep(newvalue))
 				}
 			},
 			'columnsForOrderBy':{
 				handler(newvalue){
 					if(!newvalue)
 						return
 					this.manageOrderByColumns(_.cloneDeep(newvalue))
 				}
 			}
 		},
 		computed:{
			dragOptions(){
			    return  {
			      animation: 0,
			      group: 'description',
			    };
			},
		},
		mounted(){

		},
		methods:{
			manageSourceColumns(columns){
				this.sourceOrderColumnList = []
				for(let i=0;i<columns.length;i++){
					let display_name = columns[i].column_name + " ("+columns[i].report_alias_name+")"
					this.sourceOrderColumnList.push({"column_name":columns[i].column_name,"display_name":display_name,"is_moved":false})
				}
			},
			manageOrderByColumns(columns){
				this.orderByColumnList = []
				for(let i=0;i<columns.length;i++){
					let obj = _.find(this.sourceOrderColumnList,["column_name",columns[i].column_name])
					if(obj){
						obj.is_moved = true
						columns[i].display_name = obj.display_name
						columns[i].order_type = true
						if(columns[i].type === 'desc')
							columns[i].order_type = false
						this.orderByColumnList.push(columns[i])
					}
				}
			},
			performSearch(searchText,listid){
                var columnList = document.getElementById(listid)
                var list_items = columnList.getElementsByClassName('not-moved-item')
                var filter = searchText.toUpperCase()
                for(var i = 0; i < list_items.length; i++){
                    if (list_items[i].innerText.trim().toUpperCase().indexOf(filter) > -1) 
                      list_items[i].style.display = "";
                    else
                      list_items[i].style.display = "none";
                }
            },
            startDrag(event){

			},
			onMove ({relatedContext, draggedContext}) {

    		},
    		selectDragEnd(event){
                event.preventDefault()
                console.log('end')
                if(!event || !event.to || !event.from || !event.to.id ||!event.from.id || event.from.id === event.to.id)
                    return
                if(event.from.id === 'dragSourceColumnList'){
                    
                }
                else if(event.from.id === 'dragTargetColumnList'){
                    
                }
            }
		}
 	}
</script>