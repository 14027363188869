<template>
  <v-flex id="mainflex">
    <v-card class="rounded-card EDC-Designer-Row">
      <v-container class="EDC-Container">
        <v-row class="EDC-Row" justify="start">
          <v-col class="EDC-Col text-truncate" cols="10" align="start">
            <ul class="breadcrumb" style="display:inline-flex;">
              <li><a @click="savedata(true)">{{storedproceduredigramobj.steptype}} </a></li>
              <li>[{{stepSeqNo}}] <input type="text" style="height:12px;" v-model="stepname"  :title="stepname" :readonly="readonly" :size="nameTextboxSize" @blur="StepNameChanged"/></li>
              <!-- <li><input type="text" v-model="stepname"  :title="stepname" readonly/></li> -->
            </ul>
            <span v-if='!readonly' style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>
            <span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
            (Readonly Mode)</span>
          <span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error||redoundoMsg}}</span> 
          </v-col>
          <v-col cols="2" class="EDC-Col">
            <v-icon style="font-size:20px;float:right;padding-left:12px !important;margin-top:1%;" @click="savedata(true)" title='close'>far fa-times-circle</v-icon>
            <span style="float:right;{'color': colorCode};margin-right:5px;">
           <v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
            <showerrorlisttemplate :validation_response="validation_response" stepType="Procedure"
              @set-error="setError" :showprocess="validateProgress ? 'Y':'N'" style="margin-top:2px !important;margin-left:4px !important;" :showErrorPopUp="showErrorPopUp"></showerrorlisttemplate></span>
            <span style="font-size:13px;float:right;{'color': colorCode}" @click="validatestepdata()">
             <svgicon class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;"  title="Validate Step" @click="validatestepdata" ></svgicon>
            </span>
            <!-- <svgicon class="svg-icon-grid svg-fill-grid" name="object_notes"  style="float:right;"  title="Add Step Description" @click="descriptionPanel = true"></svgicon> -->
          </v-col>
        </v-row>
        <v-row class="EDC-Row">
          <v-col class="EDC-Col" align="left">
            <edc-graph-toolbar style="padding-left:4px !important;" :redoCount="undoStackCount" :undoCount="redoStackCount" key="selection-desinger" @performUndoRedo="$emit('keyupHandler',$event)" @showDescription="descriptionPanel = true" @onSave="$emit('saveProcessDesigner')" :showNewVersion="false"></edc-graph-toolbar>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="EDC-Container">
        <v-row class="EDC-Row">
          <v-col cols="12" class="EDC-Col">
            <v-stepper v-model="stepper" class="EDC-Stepper" non-linear>
              <v-stepper-header class="EDC-StepperHeader">
                <v-stepper-step step="1" class="EDC-StepperStep" editable>
                  Select Procedure
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="2" class="EDC-StepperStep" editable>
                  Parameters
                </v-stepper-step>
                <!-- <v-divider class="EDC-StepperDivider"></v-divider> 
                  <v-stepper-step step="3" class="EDC-StepperStep" editable>
                  Options
                </v-stepper-step> -->
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content class="EDC-Stepper-Content" step="1" elevation="0">
                  <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="7">
                       <v-checkbox :disabled="readonly" color="colorCode" label="Run On Target ?" v-model="run_on_target"
                    class="customCheckbox" @change="runOnTargetChange" dense hide-details></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="7">
                      <v-autocomplete placeholder="Select Procedure Name" :items="store_procedure_list" @change="addParamsRow"
                      v-model="procedure_name"  :title="procedure_name" :loading="loading" :search-input.sync="search" hide-no-data :disabled="readonly"></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content class="EDC-Stepper-Content" step="2" elevation="0">
                  <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="12">
                      <table v-if="params_row_list" border="0" class="table table-striped table-bordered" id="param_table">
                        <thead>
                          <tr>
                            <th>Parameter Name</th>
                            <th>Type</th>
                            <th>Input/Output</th>
                            <th>Value Type</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tr v-for="(item, idx) in params_row_list">
                          <td style="padding:1px !important;vertical-align:middle !important;" class="text-xs-left" v-model="item.Parameter_name">{{item.Parameter_name}}</td>
                          <td style="padding:1px !important;vertical-align:middle !important;" class="text-xs-left" v-model="item.Type">{{item.Type}}</td>
                          <td style="padding:1px !important;vertical-align:middle !important;" class="text-xs-left" v-model="item.Is_output">{{item.Is_output}}</td>
                          <td style="padding:1px !important;vertical-align:middle !important;">
                            <toggle-button :disabled="readonly" @change="open_env_variable_dialog(idx, item.is_env_value)" 
                            v-if="item.Type != 'date'" :labels="{checked: 'env_var', unchecked: 'value'}" :value="item.is_env_value" v-model="item.is_env_value" :sync="true" :width="80"/>
                          </td>
                          <td style="padding:1px !important;vertical-align:middle !important;" class="text-xs-left" v-model="item.has_default_value">

                            <!-- <v-tooltip bottom> -->
                              <input slot="activator" :readonly="readonly" type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==4) return false;" v-if="item.Type === 'int' && (item.is_env_value === false ||  !item.is_env_value)" v-model="item.value" :rules="[v => item.has_default_value || !!v || 'Value is required']" class="form-control" placeholder="Value"/>
                              <input :readonly="readonly" slot="activator" type="text" v-else-if="item.Type === 'varchar'  && (item.is_env_value === false ||  !item.is_env_value)" :maxlength="item.Length" name="title" v-model="item.value" :rules="[v => item.has_default_value || !!v || 'Value is required']" required="required" class="form-control" placeholder="Value">

                              <input style="background-color:red important;" :readonly="readonly" slot="activator" v-else-if="item.Type != 'varchar' && item.Type != 'int' && item.Type != 'date' && (item.is_env_value === false ||  !item.is_env_value)" :maxlength="item.Length" class="form-control" v-model="item.value" placeholder="Value"/>

                              <!-- span>Value must be less than {{item.Length}} characters</span>
                            </v-tooltip> -->
                            <input :readonly="readonly" slot="activator" v-else-if="item.Type === 'int' && item.is_env_value === true" class="form-control" v-model="item.value" placeholder="Value" readonly="readonly" v-on:dblclick="getEnvVariableData(idx)" />

                            <input :readonly="readonly" slot="activator" v-else-if="item.Type === 'varchar' && item.is_env_value === true" class="form-control" v-model="item.value" placeholder="Value" readonly="readonly" v-on:dblclick="getEnvVariableData(idx)"/>

                            <input :readonly="readonly" slot="activator" v-else-if="item.Type != 'varchar' && item.Type != 'int' && item.is_env_value === true" class="form-control" v-model="item.value" placeholder="Value" readonly="readonly" v-on:dblclick="getEnvVariableData(idx)"/>

                            <input :readonly="readonly" v-model="item.value" v-else-if="item.Type === 'date' && (item.is_env_value === false ||  !item.is_env_value)" @click="datePicker(idx)" placeholder="Value" class="form-control"/>

                            <input :readonly="readonly" slot="activator" v-else-if="item.Type === 'date' && item.is_env_value === true" class="form-control" v-model="item.value" placeholder="Value" readonly="readonly" v-on:dblclick="getEnvVariableData(idx)"/>
                            <v-dialog eager v-else-if="item.Type === 'date'" v-model="dateformat_dialog" width="600" height="500" style="overflow-y:none">
                              <dateformat :dateformatobj='dateformatobj' @submit-date="getdate(idx, ...arguments)" @close="close_date_format_popup" :acceleratorObj="acceleratorObj"></dateformat>
                            </v-dialog>
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <!-- <v-stepper-content class="EDC-Stepper-Content" step="3" elevation="0">
                  <v-row class="EDC-Row">
                    <v-checkbox :disabled="readonly" color="colorCode" label="Run On Target ?" v-model="run_on_target"
                    class="customCheckbox" @change="runOnTargetChange"></v-checkbox>
                  </v-row>
                </v-stepper-content> -->
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-container>     
    </v-card>
    <v-dialog v-model="env_var_dialog" eager id="env_var_dialog" width="600" persistent>
      <v-flex>
        <v-card class="rounded-card">
          <v-toolbar dark dense>
           <v-flex class="text-md-left">Environment Variables</v-flex>
           <v-spacer></v-spacer>
           <v-icon @click="cancelDialog" style="cursor:pointer">close</v-icon>
         </v-toolbar>
         <!-- <v-container grid-list-md align-content-space-around> -->
           <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md align-content-space-around>
              <v-layout raw wrap>
                <v-flex pl-5>
                  <v-radio-group v-model="variable" @change="showTempTablesValue" row style="width:100%" dense> 
                    <v-flex xs6>
                      <v-radio class="radioClass" label="User Id" :color="checkboxColor" value="user_id" :disbaled="readonly"></v-radio>
                    </v-flex>
                    <v-flex xs6>
                      <v-radio class="radioClass" :disbaled="readonly" label="Process id" :color="checkboxColor" value="process_id"></v-radio>
                    </v-flex>
                    <v-flex xs6>
                      <v-radio class="radioClass" :disbaled="readonly" label="Mode" :color="checkboxColor" value="mode"></v-radio>
                    </v-flex>
                    <v-flex xs6>
                      <v-radio class="radioClass" :disbaled="readonly" label="Work table name" :color="checkboxColor" value="temp_table_name"></v-radio>
                    </v-flex>
                    <v-flex xs6>
                      <v-radio class="radioClass" :disbaled="readonly" label="Operation" :color="checkboxColor" value="operation"></v-radio>
                    </v-flex>
                    <v-flex xs6>
                      <v-radio class="radioClass" :disbaled="readonly" label="Date" :color="checkboxColor" value="date"></v-radio>
                    </v-flex>
                  </v-radio-group>
                  <v-autocomplete :disbaled="readonly" v-if="show_temp_table_list === true || temp_table != ''" placeholder="Select Work Table Name" 
                   :items="cells_name_list"  :rules="tempTableRules" v-model="temp_table" required></v-autocomplete>
                   <div v-else-if="variable == 'date'">                     
                      <v-autocomplete :disbaled="readonly" autocomplete :items="date_format_list" label="Formats" item-text="name"
                          item-value="id" v-model="selectedFormat" persistent-hint :hint="item"
                          style="width:95%" @input="changeValue"></v-autocomplete>
                      <v-layout row wrap style="margin:0px">
                          <v-flex xs3 style="padding-left:0px;">
                              <v-radio-group row v-model="dateType" @change="resetPolicyValue" hide-details>
                                  <v-radio :disbaled="readonly" class="radioClass" :color="checkboxColor" label="Policy:"
                                      value="Policy"></v-radio>
                              </v-radio-group>
                          </v-flex>
                          <v-flex xs8>
                              <v-layout wrap style="width:108%;">
                                  <v-autocomplete :disbaled="readonly" clearable style="margin-left:8%;margin-right:1%;"
                                      v-model="selected_policy" :title="getSelectedPolicyTitle" :items="new_policy_list"
                                      item-text="new_policy_name" item-value="new_policy_name" label="Policy"
                                      :disabled="dateType=='Date'" return-object hide-details>
                                  </v-autocomplete>
                              </v-layout>
                          </v-flex>
                      </v-layout>
                      <v-layout row wrap style="margin:0px">
                          <v-flex xs3 style="padding-left:0px;">
                              <v-radio-group row v-model="dateType" @change="resetPolicyValue" hide-details>
                                  <v-radio :disbaled="readonly" class="radioClass" :color="checkboxColor" label="Date:"
                                      value="Date"></v-radio>
                              </v-radio-group>
                          </v-flex>
                          <v-flex xs8 style="margin-left:5%;margin-right:1%;">
                              <calender :disbaled="readonly" :input="dateFormatted" :disabled="dateType=='Policy' || dateType=='policy'"
                                  @update="setDate(...arguments)" style="width:98%"> </calender>
                          </v-flex>
                      </v-layout>      
                   </div>
                  <v-autocomplete :disbaled="readonly" v-else-if="variable == 'operation'" placeholder="Select Operation" 
                   :items="operation_list" v-model="oprationName"></v-autocomplete> 
                </v-flex>
              </v-layout>
              <!-- <v-btn color="success" large block v-on:click="close_env_dialog()" style="margin-bottom: 0px;">Submit</v-btn> -->
              <vc-button type="button" :disbaled="readonly" @click.native="close_env_dialog()"  itemText="Submit"></vc-button>
            </v-container>
          </v-form>
        </v-card>
      </v-flex>
    </v-dialog>
    <v-dialog v-model="descriptionPanel" width="1000" eager>
    <v-card>
      <rich-text-box style="backgroud:white" key="procedureRichTextBox" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj">
        
      </rich-text-box>
      <v-card-actions>
        <v-spacer></v-spacer>
        <vc-button  itemText= "Close" @click.native="descriptionPanel = false">
                
              </vc-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-flex>        
</template>
<script>
const joint = require('jointjs')
const cloneDeep = require('clone-deep');
import {COLOR_CODE} from '@/data/macros.js'
import moment from 'moment'
import {getStoredProcedureList, getStoredProcedureDetails} from "../../methods/agent_api_call.js"
import dateformat from './../../views/dateformat.vue'
import {validate_process_def_json} from "../../methods/call_validation.js"
import showerrorlisttemplate from './../../views/showerrorlisttemplate.vue'
import {VALIDATION_IN_RPOCESS_MESSAGE} from '../../methods/flowcharts/constants.js'
export const DATE_TYPE_ARRAY = [{"name":'Database Date',"id":'YYYY-MM-DD'},{"name":'JDE Julian',"id": 'JUL'}, {"name":'CCYYMMDD',"id" : 'CCYYMMDD'}, {"name":'Century Only',"id": 'CC'}, {"name":'Year Only',"id": 'YY'}, {"name":'Month Only',"id":'MM'}, {"name":'YYDDD',"id": 'YYDDD'}];
export const OPERATION_LIST=[{'text':'Archival','value':'A'},{'text':'Purge','value':'P'},{'text':'Copy','value':'C'},{'text':'Restore','value':'R'},{'text':'Selective Restore','value':'SR'},{'text':'Dispose','value':'D'},{'text':'Selective Dispose','value':'SD'},{'text':'Delete Copy','value':'DC'},{'text':'Selective Delete Copy','value':'SC'}];
import {CHECKBOX_COLOR} from '@/data/macros.js'
import Calender from '@/views/calender'
import vcButton from '@/views/button.vue'
import RichTextBox from "@/views/richTextEditor/index.vue";
import * as edcConstantObj from '../../methods/edcGraph/constants.js'
import edcGraphToolbar from '@/views/edcGraph/edcGraphToolbar.vue'
export default {
  components:{
    dateformat,
    showerrorlisttemplate,
    'calender':Calender,
    'vc-button':vcButton,
    'rich-text-box':RichTextBox,
    'edc-graph-toolbar':edcGraphToolbar,
  },
  data () {
    return {
      nameTextboxSize:20,
      stepper:1,
      oprationName:'A',
      dateFormatted: null,
      selected_policy:{},
      checkboxColor:CHECKBOX_COLOR,
      item: "",
      new_policy_list:[],
      dateType:"Policy",
      selectedFormat: 'YYYY-MM-DD',
      operation_list:OPERATION_LIST,
      date_format_list: DATE_TYPE_ARRAY,
      colorCode:COLOR_CODE,
      search: null,
      valid: true,
      loading:false,
      run_on_target:false,
      editable: true,
      tempTableRules:[
        v => !!v || 'Temp Table is required',
      ],
      // search: '',
        // stepname:this.storedproceduredigramobj.cell_name,
        stepname: '',
        selection_graph: new joint.dia.Graph,
        params_row_list: [],
        store_procedure_list: [],
        default_store_procedure_list: [],
        table_list:[],
        connection_str:"",
        schema:"",
        db_type:'',
        datasource_id:"",
        target_connection_str:"",
        target_schema:"",
        target_datasource_id:"",
        dateformat_dialog: false,
        procedure_name: '',
        validateProgress:false,
        add_row_func: true,
        procedure_param_dict:{},
        env_id: '',
        cells_name_list: [],
        env_var_dialog: false,
        variable: 'user_id',
        temp_table: '',
        show_temp_table_list: false,
        value_index: '',
        cell_id: '',  
        filter_popup_index:'',
        dateformatobj:{},
        savedstatus:'edited',
        is_error_occured:false,
        datasource_error:'',
        env_name: this.$session.get('selected_env').name,
        validation_response:{},
        vueEditorObj:{},
        descriptionPanel:false,
        pd_description:'',
        stepSeqNo:1,
        showErrorPopUp:true,
        old_stepname:'',
        autoValidateTimer:0
      }
    },
    props:['storedproceduredigramobj','savedatastatus','iserroroccured','datasourceerror',
    'policies','acceleratorObj','readonly','redoundoCommandObj','redoundoMsg','undoStack','redoStack'],
    watch: {
      policies(newvalue){
        this.new_policy_list = []
        for (var i = 0; i <= newvalue.length - 1; i++) {
            if(this.parentsteptype!="purge"){
              var arc_policy = cloneDeep(newvalue[i])
              arc_policy["new_policy_name"] = arc_policy["bus_name"]+".Archival policy"
              arc_policy["policy_sub_type"] ="Archival policy"
              this.new_policy_list.push(arc_policy)
            }
            if(this.parentsteptype!="archival"){
              var ret_policy = cloneDeep(newvalue[i])
              ret_policy["new_policy_name"] = ret_policy["bus_name"]+".Retention policy"
              ret_policy["policy_sub_type"] ="Retention policy"
              this.new_policy_list.push(ret_policy)
            }
        }
      },
      search (val) {
        val && val !== this.procedure_name && this.querySelections(val)
      },
      datasourceerror(newvalue){
      this.datasource_error =newvalue
    },
       savedatastatus(newvalue,oldvalue){
      this.savedstatus = newvalue
      },
      iserroroccured(newvalue,oldvalue){
        this.is_error_occured =newvalue
      },
      setError(errorList){
      let _this = this;
      // _this.Remove_Error_List();
      // _this.Perform_Validation(errorList);
    },
    readonly(newvalue){

    },
      storedproceduredigramobj(newvalue,o){
        this.stepper = 1
        this.pd_description = ''
        this.stepSeqNo = newvalue.stepSeqNo
        this.vueEditorObj = {'description':''}
        this.validation_response ={}
        if(this.acceleratorObj && this.acceleratorObj.acceleration_type === "jde_ed_e1")
            this.selectedFormat = 'JUL'
         this.changeValue(this.selectedFormat); 
        //Code to bind error message if user perform validation at flowchart level
        var flowchart_errors = this.$store.state.errorList
        if(flowchart_errors && flowchart_errors[newvalue.stepId]){
            this.validation_response = flowchart_errors[newvalue.stepId];
          }
          this.stepname = newvalue.cell_name
          this.old_stepname = newvalue.cell_name
          if(newvalue.cell_id){
            this.cell_id = newvalue.cell_id
          }else{
            this.cell_id = ''
          }
          if(newvalue.cells_name_list){
            this.cells_name_list = newvalue.cells_name_list
          }else{
            this.cells_name_list = []
          }
          if(newvalue.tables_detail){
            this.store_procedure_list = newvalue.tables_detail.store_procedure_list
            this.default_store_procedure_list = newvalue.tables_detail.default_store_procedure_list
            this.schema= newvalue.tables_detail.schema
            this.default_table_list = newvalue.tables_detail.default_table_list
            this.db_type= newvalue.tables_detail.db_type
            this.datasource_id = newvalue.tables_detail.datasource_id
            this.target_schema = newvalue.tables_detail.target_schema
            this.target_connection_str = newvalue.tables_detail.target_connection_str
            this.target_datasource_id = newvalue.tables_detail.target_datasource_id
          }else{
            this.store_procedure_list = []
            this.default_store_procedure_list = []
            schema = []
            default_table_list: []
          }
          if (newvalue.env_id){
            this.env_id = newvalue.env_id
          }else{
            this.env_id = 0
          }
          this.validateStepOnUserAction()
          if(newvalue.step_data && newvalue.step_data.name){
            this.add_row_func = false
             if(newvalue.step_data.description){
              this.pd_description = newvalue.step_data.description
              this.vueEditorObj = {'description':newvalue.step_data.description}
            }

            var step_name = newvalue.step_data.name.replace(' ', '');
            var cell_id = this.cell_id
            if(cell_id && !(cell_id in this.procedure_param_dict)){
              this.procedure_param_dict[cell_id] = []
              this.procedure_param_dict[cell_id].push({'procedure_name': newvalue.step_data.procedure_name, 'params_list': newvalue.step_data.params})
            }
            if(newvalue.step_data.procedure_name){
              this.stepname = newvalue.step_data.procedure_name
              this.procedure_name = newvalue.step_data.procedure_name
            }
            else{
              this.stepname = 'Procedure'
              this.procedure_name = ''
            }
            if(newvalue.step_data.params){
              this.params_row_list = newvalue.step_data.params

            }else{
              this.params_row_list = []
            }
            if(newvalue.step_data.run_on_target){
              this.run_on_target = newvalue.step_data.run_on_target
              this.querySelections('')
            }
          }
          else{
            this.params_row_list = []
            // this.stepname = ''
            this.procedure_name = ''
          }
        },
        stepname(newvalue){
          if(newvalue === '')
            this.stepname = 'Procedure'
          if(newvalue){
            this.nameTextboxSize=Math.max(20,newvalue.length>60?60:newvalue.length)
          }
          this.savedata(false)
        },
        params_row_list(newvalue){
          this.savedata()
        },
        run_on_target(newvalue){
          this.savedata(false)
        },
        acceleratorObj(newvalue){
         //  if(this.newvalue.acceleration_type === "jde_ed_e1")
         //    this.selectedFormat = 'JUL'
         // this.changeValue(this.selectedFormat); 
        },
        redoundoMsg(newvalue){

        },
        redoundoCommandObj(newvalue){
          if(!newvalue || !newvalue.action_to_take || newvalue.step_id != this.storedproceduredigramobj.stepId)
            return
          this.manageRedoUndoActions(_.cloneDeep(newvalue))
        },
        undoStack(newvalue){
          if(newvalue){

          }
        },
        redoStack(newvalue){
          if(newvalue){

          }
        }
      },
      mounted() {
        let _this = this
        if(this.acceleratorObj && _this.acceleratorObj.acceleration_type === "jde_ed_e1")
            _this.selectedFormat = 'JUL'
         _this.changeValue(_this.selectedFormat); 
      },
      computed: {
        listString() {
          return JSON.stringify(this.list, null, 2);
        },
        undoStackCount(){
          if(!this.storedproceduredigramobj || !this.storedproceduredigramobj.stepId)
            return 0
          return _.filter(this.undoStack,(obj)=>{
            return obj.step_id === this.storedproceduredigramobj.stepId
          }).length
        },
        redoStackCount(){
          if(!this.storedproceduredigramobj || !this.storedproceduredigramobj.stepId)
            return 0
          return _.filter(this.redoStack,(obj)=>{
            return obj.step_id === this.storedproceduredigramobj.stepId
          }).length
        }
      },
      methods:{
        manageRedoUndoActions(redoundoCommandObj){
          var _this = this
          if(redoundoCommandObj.action_to_take === 'step_name_changed_from_inside'){
            if(!redoundoCommandObj.update_details)
              return
            this.old_stepname = this.stepname
            this.stepname = redoundoCommandObj.update_details.new_step_name
          }
        },
        StepNameChanged(){
          this.stepname = this.stepname.trim()
          if(this.old_stepname.trim() === this.stepname)
            return false
            if(this.stepname.length ===0)
              this.stepname = this.old_stepname.trim()
            else{
              this.$emit('sendNonGraphEvent','step_name_changed_from_inside',{"action_type":"step_name_changed_from_inside","update_details":{"step_id":this.storedproceduredigramobj.stepId,"step_name":this.old_stepname,"new_step_name":this.stepname},"step_id":this.storedproceduredigramobj.stepId})
              this.old_stepname = this.stepname.trim()
              this.savedata(false)    
          }
        },
        validateStepOnUserAction(){
          // we are going to call validate object after curtain time after action get performed because graph take sometime to update its json.
            var _this = this
            _this.showErrorPopUp = false
            clearInterval(_this.autoValidateTimer)
				    _this.autoValidateTimer=setTimeout(()=>{
              _this.showErrorPopUp = true
              _this.validatestepdata()
              _this.$emit('validatePD')
            },edcConstantObj.AUTO_VALIDATOR_TIMER)
        },
        runOnTargetChange(){
          this.store_procedure_list = []
          this.params_list = []
          this.procedure_name = ''
          this.querySelections('')
          this.savedata()
          this.validateStepOnUserAction()
        },
        setDate(param){
          this.dateFormatted = param; 
          if (!param) return null
        const [year,month, day] = param.split('-');
        this.dateFormatted = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        changeValue: function(newValue) {
          var todays_date = moment(String(new Date()))

          if(newValue == "CC"){
              var year = todays_date.year().toString().substr(0,2)
              this.item = parseInt(year);
          }else if(newValue == "CCYYMMDD"){
              var year = todays_date.year().toString().substr(0,2)
              var cc_value = parseInt(year);
              var formated_date = todays_date.format("YYMMDD");
              this.item = cc_value.toString()+formated_date
          }else if(newValue == ""){
              this.item = ''
          }else{
              this.item = todays_date.format(newValue);
          }
        },
        resetPolicyValue(){
          this.selected_policy = {};
        },
        cancelDialog(){
          // this.params_row_list[this.value_index].is_env_value = false;
          this.env_var_dialog = false;
        },
        querySelections (v) {
            this.loading = true
            this.getProcedureList(v);
        },
        getProcedureList(procedure_search_string){
          var _this = this

          _this.store_procedure_list = []

          getStoredProcedureList(_this, _this.env_id, procedure_search_string).then(tableResponse =>{
            const latest_store_procedure_list = tableResponse.result.map(x => x.trim());
            _this.store_procedure_list = latest_store_procedure_list
            _this.loading = false;
          }).catch(errorResponse => {});
        },
        close_env_dialog(){
          if (this.$refs.form.validate()) {
            if(this.variable == 'temp_table_name'){
              this.params_row_list[this.value_index].type = 'work_table'
              this.params_row_list[this.value_index].value = this.temp_table
            }else if(this.variable == 'operation'){
              this.params_row_list[this.value_index].type = 'operation'
              this.params_row_list[this.value_index].value = this.oprationName
            }else if(this.variable == 'date'){
              this.params_row_list[this.value_index].type = 'date';
              this.params_row_list[this.value_index].date_format = this.selectedFormat;
              this.params_row_list[this.value_index].date_type = this.dateType;
              this.params_row_list[this.value_index].datedetails = this.dateselection();
              if(this.dateType && this.dateType.toLowerCase() === 'policy'){                
                this.params_row_list[this.value_index].value = this.selected_policy.new_policy_name;
              }else{
                this.params_row_list[this.value_index].value = this.dateFormatted;
              }
            }
            else{
              this.params_row_list[this.value_index].type = this.variable;
              this.params_row_list[this.value_index].value = this.variable
            }
            this.env_var_dialog = false
            this.temp_table = ''
            this.variable = 'process_id'
            this.show_temp_table_list = false
          }
        },
        dateselection(){
          var format = this.selectedFormat
          var date = moment(this.dateFormatted)
          var selected_date = '';
          if(format == "CC"){
            var year = date.year().toString().substr(0,2)
            selected_date = parseInt(year);
          }else if(format == "CCYYMMDD"){
            var year = date.year().toString().substr(0,2)
            var cc_value = parseInt(year);
            var formated_date = date.format("YYMMDD");
            selected_date = cc_value.toString()+formated_date
          }else if(format == "" || format == null){
            selected_date = ''
          }else{
            selected_date = date.format(format);
          }
          var data_to_pass={}
          data_to_pass["format"] = this.selectedFormat
          this.changeValue(this.selectedFormat)
          if(this.selected_policy){
            data_to_pass["is_policy"] = true
            data_to_pass["policy_name"] = this.selected_policy.bus_name
            data_to_pass["value"] = this.selectedFormat + "["+this.selected_policy.new_policy_name +["]"]
            data_to_pass["policy_sub_type_name"] = this.selected_policy.policy_sub_type
            data_to_pass["bo_id"] = this.selected_policy.bus_id
            data_to_pass["bo_name"] = this.selected_policy.bus_name
          }
          else{
            data_to_pass["actual_date"] = this.dateFormatted
            data_to_pass["value"] = selected_date;
          }
          data_to_pass["policy_type"] = this.dateType;
          return data_to_pass;
        },
        getEnvVariableData(index){
          this.value_index = index
          this.env_var_dialog = true
          var var_name = this.params_row_list[index].value
          if(var_name == 'process_id' || var_name == 'mode' || var_name == 'user_id'){
            this.variable = this.params_row_list[index].value;
            this.show_temp_table_list = false
            this.temp_table = ''
          }else if(this.params_row_list[index].type == 'operation'){
              this.oprationName = var_name;
              this.variable = 'operation'
              // this.params_row_list[this.value_index].value = this.oprationName
          }else if(this.params_row_list[index].type == 'date'){
            this.selected_policy = {}
            this.dateFormatted = ''
              this.variable = 'date';
              this.selectedFormat = this.params_row_list[index].date_format;
              this.dateType = this.params_row_list[index].date_type;
              if((this.dateType && this.dateType.toLowerCase()==='policy') && this.params_row_list[index].datedetails && this.params_row_list[index].datedetails.bo_name){             
                this.selected_policy = _.find(this.new_policy_list,(obj)=>{
                  return obj.bus_name === this.params_row_list[index].datedetails.bo_name && obj.policy_sub_type === this.params_row_list[index].datedetails.policy_sub_type_name
                }) 

              }else{
                this.dateFormatted = var_name;
              }
                this.changeValue(this.selectedFormat)
          }else{
            this.variable = 'temp_table_name'
            this.temp_table = this.params_row_list[index].value
          }
        },
        open_env_variable_dialog(index, value){
          if(this.params_row_list[index].is_env_value){
            this.dateType="Policy";
            this.selectedFormat='YYYY-MM-DD';
            if(this.acceleratorObj && this.acceleratorObj.acceleration_type === "jde_ed_e1")
            this.selectedFormat = 'JUL'
            this.temp_table = ''
            this.variable = 'user_id'
            this.show_temp_table_list = false;
            this.env_var_dialog = true;
            this.dateFormatted = null;
            this.value_index = index
            this.params_row_list[index]['value'] = ''
          }else{
            this.params_row_list[index].value = ''
          }
        },
        showTempTablesValue(e){
          if(e == 'temp_table_name'){
            this.show_temp_table_list = true
          }else{
            this.show_temp_table_list = false
          }
        },
        addParamsRow(event){
          var _self = this
          var step_name = _self.stepname.replace(' ', '');
          var cell_id = _self.cell_id
          var procedure_name = event.trim()
          this.stepname = procedure_name
          // added below line on 20th July 2021.Requirement is fetch new param list always
          _self.procedure_param_dict = {}
          if(_self.procedure_param_dict && cell_id in _self.procedure_param_dict){
            let isProcedureNameExists = _self.procedure_param_dict[cell_id].map(a=>a.procedure_name).includes(procedure_name)
            if(isProcedureNameExists){
              for (var i = 0; i < _self.procedure_param_dict[cell_id].length; i++) {
                if(_self.procedure_param_dict[cell_id][i].procedure_name == procedure_name){
                 _self.params_row_list = _self.procedure_param_dict[cell_id][i].params_list
                 break;
               }
             }
           }else{
            getStoredProcedureDetails(_self, procedure_name, _self.env_id).then(tableColumnResponse=> {
              _self.params_row_list = tableColumnResponse.result;
              if(!_self.procedure_param_dict[cell_id]){
                _self.procedure_param_dict[cell_id] = []
              }
              _self.procedure_param_dict[cell_id].push({'procedure_name': procedure_name, 'params_list': tableColumnResponse.result})
            }).catch();
          }
        }else{
          getStoredProcedureDetails(_self, procedure_name, _self.env_id).then(tableColumnResponse=> {
            _self.params_row_list = tableColumnResponse.result;
            _.forEach(_self.params_row_list, function(obj){
              _self.performDefaultSelection(obj)
            })
            if(!_self.procedure_param_dict[cell_id]){
              _self.procedure_param_dict[cell_id] = []
            }
            _self.procedure_param_dict[cell_id].push({'procedure_name': procedure_name, 'params_list': tableColumnResponse.result})
          }).catch();
        }
      },

      performDefaultSelection(obj){
        if(obj.Parameter_name.toLowerCase() === 'processid' || obj.Parameter_name.toLowerCase() ==='process_id' || obj.Parameter_name.toLowerCase() ==='@processid'|| obj.Parameter_name.toLowerCase() ==='@sp_processid'){
          obj.is_env_value = true
          obj.type = "process_id"
          obj.value = "process_id"
        }
        else if(obj.Parameter_name.toLowerCase() === 'operation' || obj.Parameter_name.toLowerCase() === 'process_type'||obj.Parameter_name.toLowerCase() === '@operation'||obj.Parameter_name.toLowerCase() === '@sp_operation'){
          obj.is_env_value = true
          obj.type = "operation"
          obj.value = "A"
        }
        else if(obj.Parameter_name.toLowerCase() === 'mode' || obj.Parameter_name.toLowerCase() ==='process_mode'||obj.Parameter_name.toLowerCase() === '@mode'||obj.Parameter_name.toLowerCase() === '@sp_mode'){
          obj.is_env_value = true
          obj.type = "mode"
          obj.value = "mode"
        }
        else if(obj.Parameter_name.toLowerCase() === 'userid' || obj.Parameter_name.toLowerCase() === 'process_exe_by'||obj.Parameter_name.toLowerCase() === '@userid'||obj.Parameter_name.toLowerCase() === '@sp_userid'){
          obj.is_env_value = true
          obj.type = "user_id"
          obj.value = "user_id"
        }

        else if((obj.Parameter_name.toLowerCase() === 'archdate' || obj.Parameter_name.toLowerCase() === 'archive_date'||obj.Parameter_name.toLowerCase() === '@archdate'||obj.Parameter_name.toLowerCase() === '@sp_archdate') && this.new_policy_list.length > 0){
          obj.is_env_value = true
          obj.date_format = this.selectedFormat 
          obj.type = "date"
          obj.date_type = "Policy"
          obj.value = this.new_policy_list[0].new_policy_name
          obj.datedetails = {
            'format':this.selectedFormat,
            'is_policy':true,
            'policy_name':this.new_policy_list[0].bus_name,
            'value':this.selectedFormat+"["+this.new_policy_list[0].new_policy_name+"]",
            'policy_sub_type_name':this.new_policy_list[0].policy_sub_type,
            'bo_id':this.new_policy_list[0].bus_id,
            'bo_name':this.new_policy_list[0].bus_name,
            'policy_type':'Policy'

          }

        }

        else if(obj.Parameter_name.toLowerCase() === 'processresumeyn' || obj.Parameter_name.toLowerCase() === 'can_resume'||obj.Parameter_name.toLowerCase() === '@processresumeyn'||obj.Parameter_name.toLowerCase() === '@sp_processresumeyn'){
          obj.is_env_value = false
          obj.value = "N"
        }

        else if((obj.Parameter_name.toLowerCase() === 'temptable' || obj.Parameter_name.toLowerCase() === 'input_table'||obj.Parameter_name.toLowerCase() === '@temptable'||obj.Parameter_name.toLowerCase() === '@sp_temptable')&& this.cells_name_list.length > 0){
          obj.is_env_value = true
          obj.type = "work_table"
          obj.value = this.cells_name_list[this.cells_name_list.length-1]
        }

        else if(obj.Parameter_name.toLowerCase() === 'processexitcode' || obj.Parameter_name.toLowerCase() === 'exit_code'||obj.Parameter_name.toLowerCase() === '@processexitcode'||obj.Parameter_name.toLowerCase() === '@sp_processexitcode'){
          obj.is_env_value = false
          obj.value = "P"
        }

        else if(obj.Parameter_name.toLowerCase() === 'processexitmsg' || obj.Parameter_name.toLowerCase() === 'exit_msg'||obj.Parameter_name.toLowerCase() === '@processexitmsg'||obj.Parameter_name.toLowerCase() === '@sp_processexitmsg'){
          obj.is_env_value = false
          obj.value = "E"
        }
        this.validateStepOnUserAction()
        return obj
      },

      datePicker(idx){
        this.filter_popup_index = idx
        var date_details = this.params_row_list[idx].datedetails
        if(date_details)
          this.dateformatobj = cloneDeep(date_details)
        else
          this.dateformatobj ={}
        this.dateformat_dialog = true
      },
      getdate(index,data){
        index = this.filter_popup_index
        var date_data = cloneDeep(data)
        this.params_row_list[index].value = date_data["value"]
        this.params_row_list[index].datedetails = date_data
        this.dateformat_dialog = false
      },
      getdata(){
        var _this =this
        this.is_error_occured =false
        var data = {'stepname':_this.stepname,
        'step_data':{'name': _this.stepname, 'procedure_name': _this.procedure_name,  'params':this.params_row_list,"run_on_target":this.run_on_target,'description':this.pd_description}
      }
      return data
    },
    setError(){

    },
    savedata(is_close){
      this.env_name = this.$session.get('selected_env').name
      let _this = this
      this.savedstatus = "edited"
      console.log(_this.selection_graph.toJSON())
      var data = this.getdata()
      console.log("data",data)
      _this.$emit('update-object',data,is_close)
    },
    
    close_date_format_popup(){
    this.dateformat_dialog = false
  },
    validatestepdata(){

      var _this = this
      if(_this.validateProgress){
          // alert(VALIDATION_IN_RPOCESS_MESSAGE)
          return false
        }
      if(this.readonly)
        return
      _this.validateProgress=true;
      var data = this.getdata()
      data['step_type'] ='Procedure'
      data['env_id'] = this.env_id
      data['steps_details'] = this.cells_name_list

        // this.$toasted.error('No Procedure Name found');
      validate_process_def_json(this, data).then(validation_response => {
        _this.validateProgress=false;
        this.validation_response = validation_response ;
        this.$store.state.errorList[this.storedproceduredigramobj.stepId] = validation_response
      }).catch(error=>{
        _this.validateProgress = false;
      })
    },
    DescriptionChange(pd_description){
      this.pd_description = pd_description
      this.savedata()
    },
    getSelectedPolicyTitle(){
      if(!this.selected_policy)
        return ''
      return this.selected_policy.new_policy_name
    }
  }
}
</script>
<style scoped>
.customCheckbox >>> label{
  top: 5px !important;
}

.vue-js-switch {
  margin-top: 7px;
}
.container .v-input{
  width: 50%;
}
.v-switch-core{

}
.v-autocomplete.v-input{
  margin:0px;
}
.upperrow{
  display:inline-block;
  height:400px;
  overflow: auto;
  border:1px solid grey;
}

.lowerrow{
  display:block;
  height:200px;
  overflow: auto;
  /* border:1px solid grey; */

}

#mainflex{
  background-color:grey;
}

.list_items {
  height:30px;
}
table.v-table thead tr, table.v-table tbody tr{
  height: 24px;
}

#param_table thead tr, table.v-table tbody tr{
  height: 24px;
}

.sticky {
  position: absolute;
}
#param_table .v-select__slot{
  margin-bottom: -6px;
}
#param_table td{
  font-size: 13px;
}

#param_table th td{
  
  vertical-align:center !important;
}
#param_table .v-input__slot{
  font-size: 12px;
  margin-bottom: -8px;
  margin-top: -5px;
}
#param_table .v-input__append-inner i{
  font-size: 12px;
}
#param_table .v-text-field{
  margin-bottom: -15px;
}
#param_table .v-text-field__slot{
  width: 65px;
}
#param_table .v-text-field__slot{
  margin-top: -5px;
}
.v-radio label{
  font-size: 15px;
}
.v-radio i{
  font-size: 20px;
}
.v-dialog__activator{
  margin-top: -10px;
}
.v-messages__message{
  margin-top: 11px;
}

input {
  height: 28px;
  font-size: 12px !important;
}

.radioClass >>> label{
    top: 3px !important;
}

</style>