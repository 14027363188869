<template>
		<v-container class="EDC-Container">
			<v-row class="EDC-Row" style="padding-top:24px !important;">
				<v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">
					<v-text-field label="Name of Business View" v-model="businessViewName" hide-details dense :disabled="isdisabledtxt"></v-text-field>
				</v-col>
				<v-col cols="3" class="EDC-Col EDC-ColsSpacing business-view-text-area">
					<v-textarea label="Description" rows=1 v-model="businessViewDescription" hide-details dense :disabled="isdisabledtxt"></v-textarea>
				</v-col>
				<v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:10px !important;">
					<v-checkbox class="customCheckbox" v-model="showDistinctRecords" label="Show Distinct Row" hide-details dense :disabled="isdisabledtxt"></v-checkbox>
				</v-col>
				<v-col cols="1" class="EDC-Col EDC-ColsSpacing" style="padding-top:15px !important;">
					Version: {{object_revision}}
				</v-col>
				<v-col class="EDC-Col" cols="2">
					<!-- <sapn class="EDC-DesignerHeader"><b>BUSINESS VIEW DESIGNER</b></sapn> -->
				</v-col>
			</v-row>
			<v-row class="EDC-Row" v-show="!gridPreview" style="padding-top:8px !important;">
				<v-col  class="EDC-Col EDC-ColsSpacing" cols="5" align="left">
					<v-row class="EDC-Row">
						<v-col  class="EDC-Col" cols="12">
							<v-card class="EDC-VCards" elevation-0 >
								<edc-toolbar class="EDC-Custom-Toolbar" key="tree_toolbar" customLabel="Search In Tree" usedFor="tree" bindWithElement="objectTree" @perform-serach="performSearch"></edc-toolbar>

								<v-treeview class="overflow-y-auto  style-2scroll" id="objectTree" dense style="text-align:left;height:360px;" v-model="selectedObject" :items="items" selection-type="independent" :load-children="fetchChildrenOfItem"  :search="tree_search" :open.sync="treeDefaultOpenIds" open-on-click>
									<template v-slot:label="{item}">
										<v-radio-group  v-model="selectedNode"  hide-details dense v-if="item.useRadio" @change="nodeRadioSelectionChange(item)" >
			                            	<v-radio class="radioClass" :disabled="disableObjectSelection" :label="item.name" hide-details dense :value="item">

			                            	</v-radio>
			                        	</v-radio-group>
										<draggable v-else-if= "item.isColumn" v-model="selectedObject" @start="startDrag($event,'tree')" @end="dragEnd" group="test" id="fromDrag" :disabled="isdisabledtxt">
										<span @click="toggleSelectedCol(item,$event)" :class="{'selected-list-item':item.isSelected}" :data-parent-id="item.parentId" :data-item-id="item.id" data-parent-type="tree">{{item.name}}</span>

								      	<!-- <v-checkbox  @change="itemChecked($event,item)" :value="item.isSelected" :label="item.name" v-model="item.isSelected" dense hide-details></v-checkbox> -->
								      </draggable>
								      	<span v-else>{{item.name}}</span>
								    </template>
								</v-treeview>
							</v-card>
						</v-col>
					</v-row>
					<v-row class="EDC-Row" style="padding-top:8px !important;">
						<v-col cols="8" class="EDC-Col" align="left">
							Selected Object: {{selectedNode.name || 'N/A'}}
						</v-col>
						<v-col cols="4" class="EDC-Col" align="right">
							Total: {{items[0] && items[0].children.length}}
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="1" align-self="center">
					<vc-button  itemText=">>"  @click.native="performSelectColumns" :disabled="isdisabledtxt"></vc-button>
				</v-col>
				<v-col cols="5" class="EDC-Col" align="left">
					<v-row class="EDC-Row">
						<v-col  class="EDC-Col" cols="12">
							<v-card class="EDC-VCards">
		                         <edc-toolbar class="EDC-Custom-Toolbar" key="selected_columns_toolbar" customLabel="Selected Columns" usedFor="list" bindWithElement="selectedColumnList" @perform-serach="performSearch"  
								 @templateOptionChanged="templateOptionChanged" :isTemplate="isTemplate" :erpCode="erp_code"></edc-toolbar>

								<v-list dense id="selectedColumnList" class="EDC-list-for-columns overflow-y-auto style-2scroll" max-height="400" data-parent-type="listItem">
									<draggable v-model="selectedColumnList" @start="startDrag($event,'list')" :options="dragOptions" :move="onMove" group="test" id="toDrag">
										<v-list-item dense selectable v-for="(item, i) in selectedColumnList" :key="i+'selctedcolumn'" class="custom-list-item" data-parent-type="listItem" :disabled="isdisabledtxt">
		                                    <v-col cols="6" class="EDC-Col" align="left" title="Column name">
										        {{item.column_display_name}}
		                                    </v-col>
		                                    <v-col cols="2" class="EDC-Col" align="left" title="Table name">
										        {{item.table_name}}
		                                    </v-col>
		                                    <v-col cols="3" class="EDC-Col column-alias" align="left">
										        <v-text-field v-model="item.col_alias" dense hide-details title="Column alias" @blur="getUniqueAlias(item.col_alias,i)"></v-text-field>
		                                    </v-col>
		                                    <v-col cols="1" class="EDC-Col" align="center">
										        <svgicon class="svg-icon-mini svg-fill-mini" name="delete_v2"  :original="true" title="Remove column" @click="removeSelectedItem(i)"></svgicon>
		                                    </v-col>
										</v-list-item>
									</draggable>
								</v-list>
							</v-card>
						</v-col>
					</v-row>
					<v-row class="EDC-Row">
						<v-col cols="4" class="EDC-Col" align="left">
						</v-col>
						<v-col cols="8" class="EDC-Col" align="right" style="padding-top:8px !important;">
							Total Selected Columns: {{selectedColumnList.length}}
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row class="EDC-Row" v-if="gridPreview" style="margin-left:-12px !important;">
				<!-- <v-card class="EDC-VCards"> -->
				<v-col class="EDC-Col" cols="12" >
					<edc-data-grid gridFor="dataviewer1" :dataList="tableList"></edc-data-grid>
				</v-col>
				<!-- </v-card>     -->
			</v-row>
			<v-row class="EDC-Row" style="padding-top:8px !important;">
			<v-col class="EDC-Col" cols="11" v-if="!gridPreview">
				<vc-button  itemText="Cancel" style="float:right;" :disabled="isdisable"></vc-button>
				<vc-button  itemText="Save" :disabled="disabledAction()" style="float:right;" @click.native="saveBusinessView"></vc-button>
				<toggle-button key="coltoggle" :labels="{checked: 'Grid', unchecked: 'List'}" title="Preview"
                style="margin-bottom:0px !important;float:right;margin-top:5px;margin-right:2px;" v-model="gridPreview" :sync="true" :width="65" :height="28" :color="{checked: '#607D8B', unchecked: '#607D8B', disabled: '#CCCCCC'}" :disabled="isdisabletoggle" @change="showPreview"/>
			</v-col>
			<v-col class="EDC-Col" cols="12" v-else style="padding-right:10px !important;">
				<vc-button  itemText="Cancel" style="float:right;" :disabled="isdisable"></vc-button>
				<vc-button  itemText="Save" :disabled="disabledAction()" style="float:right;" @click.native="saveBusinessView"></vc-button>
				<toggle-button key="coltoggle" :labels="{checked: 'Grid', unchecked: 'List'}" title="Preview"
                style="margin-bottom:0px !important;float:right;margin-top:5px;margin-right:2px;" v-model="gridPreview" :sync="true" :width="65" :height="28" :color="{checked: '#607D8B', unchecked: '#607D8B', disabled: '#CCCCCC'}" :disabled="isdisabletoggle" @change="showPreview"/>
			</v-col>
			<v-col></v-col>
		</v-row>
		<simplert :useRadius="true"
           :useIcon="true"
           ref="simplert">
        </simplert>
		<loading-panel :loader="loader"></loading-panel>


		</v-container>
</template>

<style>
.customCheckbox label {
	top:4px !important;
}

.v-treeview--dense .v-treeview-node__root {
    min-height: 24px !important;
}

.EDC-VCards {
  margin: 0px !important;
  height: 400px !important;
}
.EDC-list-for-columns {
	max-height:368px !important;
	min-height:368px !important;
}
.EDC-Custom-Toolbar {
    padding:0px !important;
}

.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 16px !important;
}

.custom-list-item {
	margin-bottom: 0px !important;
	cursor: pointer !important;
}

.v-list-item {
	padding-left:16px !important;
	padding-top:0px !important;
	padding-right:8px !important;
	padding-bottom:0px !important;
}

.column-alias .v-text-field__slot{
	height: 16px !important;
	font-size: 11px !important;
}

.column-alias .v-input input{
	font-size: 11px !important;
}
.business-view-text-area .v-textarea textarea{
	line-height: 1.65rem !important;
	min-height: 24px !important;
}
.radioClass >>> label {
	font-size: 11px !important;

}
.selected-list-item{
	background-color:var(--v-primary-base) !important;
}
</style>
<style scoped>
/* radio label styling */
.radioClass >>> label {
	top:3px !important;
	font-size: 12px !important;
	color: rgba(0,0,0,.87) !important;
	font-family: 'IBM Plex Sans','Avenir', Helvetica, Arial, sans-serif !important;

}

.selected-list-item{
	background-color:var(--v-primary-base) !important;
}
</style>

<script>
import Simplert from 'vue2-simplert'
import config from '../../config.json'
import draggable from 'vuedraggable'
import edctoolbar from './../../views/edctoolbar.vue'
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import {ALL_PUBLISHED_BUSINESS_OBJ_LIST,ALL_ROLES_FOR_GROUP,GETENVIRONMENTDETAILS,GET_PROCESS_GRAPH_DATA} from '../../data/url_constants.js';
import {getTableColumns} from '../../methods/agent_api_call.js';
import * as JDEFormatterObj from "../../methods/JDEFormatter.js";
import {
  CLIENT_SIDE,
  SERVER_SIDE
} from '../../data/macros.js';
import buttonVue from '../../views/button.vue'
import { env } from 'jointjs'
import {mapActions} from 'vuex'
export default {
	name:"BusinessViewDesigner",
	components:{
		Simplert,
		draggable,
        'edc-toolbar':edctoolbar,
	},
	data(){
		return{
			isdisabledtxt: false,
			isdisabletoggle:false,
			isdisablesave:true,
			isdisable:false,
			loader:false,
			businessViewId:'',
			businessViewName:'Untitled Business View',
			businessViewDescription:'',
			showDistinctRecords:true,
			env_id: this.$session.get("selected_env").id,
			env_name: this.$session.get("selected_env").name,
			object_revision:this.$route.params.object_revision?this.$route.params.object_revision:"N/A",
			selectedObject:[],
			default_items:[{
	          id: 1,
	          name: 'Business Objects',
	          type:'boheader',
	          children:[],
	          isRoot:true
	        },{
	          id: 2,
	          name: 'Data Maps',
	          type:'dmheader',
	          children:[],
	          isRoot:true
	        }],
	        items:[],
	        treeDefaultOpenIds:[],
	        selectedColumnList:[],
	        selectedNode:{},
	        tree_search:'',
	        clientId:this.$session.get("client_id"),
	        loggedInUser:this.$session.get("email"),
	        boObjectType:"BO",
	        dmObjectType:"DM",
			gridPreview:false,
			erp_code: this.$route.params.erpCode,
			erp_version: this.$route.params.erpVersion,
			ds_info:{},
	        tableList:{
		      headers:[],
		      itemkey:'',
		      rows:[],
		      total_count:0,
		      hideCaption:true,
		      tableLoading:true,
		      selected_rows:[],
		      tableDescription:'',
		      sorting_type: SERVER_SIDE,
		      filterType: SERVER_SIDE,
		      paginationType: SERVER_SIDE,
		      is_in_tab:true,
		      showContextMenu:true,
		      hideNonSelectedRows:true,
		      hideRowTimer:null,
		      hideRowDelay:3000, // kept 1 second less compare serverCallDelay
		      showDescriptionAsHeader:false,
		      gridFor:'dataviewer1',
		      hideShowColumns:true,
		      inputJson:{},
		      hideFilter:true,
		      editObjectDetails:{},
		      hidePagination:true,
		      hideRowInfo:true,
		      hideItemPerPage:true,
		      hideSelect:true,
		      hideExport:true,
		      hideAdvanceFilter:true,
		      hideInlineFilter:true,
					isReadOnly:false
		    },
		    dragObject:{},
		    lastSelectedColumn:{},
			allDatasourceList:[],
			isTemplate:false,
		}
	},
	computed:{
		dragOptions(){
		    return  {
		      animation: 0,
		      group: 'description',
		    };
		},
		// disabledAction(){
		// 	return !this.selectedColumnList.length
		// },
		disableObjectSelection(){
			return this.selectedNode && this.selectedNode.id && this.selectedColumnList.length>0
		},
	},
	mounted(){
		var _this = this

		_this.setDesignerFor({"designerFor":'Business View Designer'})
		var client_id = _this.$session.get("client_id")
		if(_this.$route.params.bv_for === _this.boObjectType){
			this.items = [this.default_items[0]]
	    	this.GetBusinessObjectList(this.items[0])
		}
		if(_this.$route.params.bv_for === _this.dmObjectType){
			this.items = [this.default_items[1]]
	    	this.getDataMapList(this.items[0])
		}
		let env_id = this.env_id
		_this.getEnvDetails(env_id)
		if (this.$session.get('copy')){
				_this.copyGraph()
		}
		else if(this.$route.params.business_view_id && !this.$session.get('create_new_version')){
			this.businessViewId = this.$route.params.business_view_id
			this.getBusinessViewForEdit()
			if (this.$session.get('is_read_only') || this.$route.params.is_read_only){
				_this.disabledAction()
			}
			if(!_this.$route.query.create_new_version){
			var data = {
						object_id: this.businessViewId,
						object_revision: this.object_revision,
						object_type_code: 'BV',
						env_id: this.env_id,
						env_name: this.env_name
					};
			if (!this.$session.get('is_read_only') && !this.$route.params.is_read_only)
				_this.checkobjectversion(data)
			}
		}
		else if(this.$session.get('create_new_version')){
			this.businessViewId = this.$route.params.business_view_id
			this.getBusinessViewForEdit()
		}

		var colList = document.getElementById("selectedColumnList");
		colList.addEventListener("drop", drop_on_cols_list);
		colList.addEventListener("dragover", allowDrop_on_cols_list);
		function drop_on_cols_list(event){
		}
		function allowDrop_on_cols_list(event) {
			event.preventDefault();
		}

	},
	methods:{
		...mapActions(['setDesignerFor']),
		getEnvDetails(env_id){
			var _this = this
			var environment_end_point = config.ENVIRONMENT_API_URL + '/get_datasource_details_with_env_id_for_dv'
			let data = {"env_id":env_id,"client_id":_this.$session.get("client_id")}
	    	postToServer(_this, environment_end_point,data, true).then(envResponse => {
				_this.allDatasourceList = envResponse.all_datasource_details
	        }).catch(error=>{
	        	alert('load env failed')
	        })
		},
		checkobjectversion(record){
	            let _this = this;
	            if(record.object_version){
	                var object_revision = record.object_version
	            }else{
	                var object_revision =  record.object_revision
	            }
	            var inputJson =  {"object_id": record.object_id, "object_revision": object_revision, "env_id": this.env_id}
	            postToServer(this, config.PUBLISHER_URL + '/object_published_in_multiple_env', inputJson).then(response => {
                    if(response){
                        if(response.status == 'CREATE_NEW_VERSION'){
                        	this.isReadOnly = true
                            this.ShowConfirm(record)
                        } else if (response.status == 'PENDING_APPROVAL'){
                        	this.isReadOnly = true
                            alert("Object is in Approval pending state, Can not edit object")
                        } else {
                            _this.isReadOnly = false
                        }
                    } else {
                       this.errorMsg = response
                    }
	            }).catch(ProcessDocError => {
                    if(ProcessDocError){
                        this.loader = null
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.tableList.rows = [];
                        this.tableList.total_count =0;
                        this.snackbartext = ProcessDocError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                });
	        },
        ShowConfirm(object){
			var _this = this
        	let confirmFn = function () {
        	_this.SendObjectForNewVersion(object)
			}
			let closefn = function(){
				_this.disabledActionfn()
			}
        	let obj = {
          		title: 'Published Object',
          		message: 'This Object has been published. You can create a new version or open it in read only mode',
          		type: 'info',
				useConfirmBtn: true,
				useCloseBtn: true,
				onConfirm: confirmFn,
				onClose: closefn,
          		customCloseBtnText:'Read Only',
          		customConfirmBtnText:'New Version'
        	}
        	_this.$refs.simplert.openSimplert(obj)
		  },
		SendObjectForNewVersion(object) {
      	  	this.getBusinessViewForEdit(object)
			  },
		disabledActionfn(){
			var _this = this
			_this.isdisable=true
			_this.disabledAction()
		},
		disabledAction(){
			var _this = this
			if(this.$session.get('is_read_only')){
				_this.isdisable = true
			}
			if (_this.isdisable){
				return _this.isdisabletoggle = false, _this.isdisablesave = true, _this.isdisabledtxt = true


			}
			else if(this.selectedColumnList.length){
				return _this.isdisablesave = false , _this.isdisabletoggle = false
			}
			else{
				return _this.isdisablesave=true , _this.isdisabletoggle = true
			}
			// return !this.selectedColumnList.length
		},
		itemChecked(event,item){
			console.log(item)
		},
		fetchChildrenOfItem(item){
			if(!item || !item.type)
				return
			if(item.type === 'boheader')
				return this.GetBusinessObjectList(item)
			if(item.type === 'dmheader')
				return this.getDataMapList(item)
			if(item.type === this.boObjectType)
				return this.getBORelatedTableData(item)
			if(item.type === this.dmObjectType)
				return this.getDMRelatedData(item)
			if(item.type === 'tbl')
				return this.getTableColumns(item)
		},
		getObjectById(list,id){
			var _this = this
			for (var i = 0; i< list.length; i++) {
				if(list[i].id == id)
					return list[i]
				if(list[i].children)
					return _this.getObjectById(list,id)
			}
			return {}
		},
		GetBusinessObjectList(parent,selectedDetails) {
			var parentId = parent.id
		    var user_id = this.$session.get('user_id')
		    var client_id = this.clientId
		    var get_bo_list_url = config.PUBLISHER_URL + '/published_objects_in_env_for_dv'
		    var data = {"client_id": client_id, "env_id": this.env_id,'filter':[{
				'column_name':'object_type',
				'value1':'BO',
				'operator':'_eq_'
			}],'page_size':500}
		    var _this = this
		    _this.loader = true

			return postToServer(_this, get_bo_list_url, data).then(response => {
		     	_this.loader = false
				// list we receive its all bo's are together. We need to diffrenticate its by the datasource name
				var boByDS=[]
				_.forEach(_this.allDatasourceList,(eachDS,dsIndex)=>{
					let businessDS = _.cloneDeep(eachDS.business_data)
					businessDS.datasource_index = dsIndex
					let dsUniqueId = "ds"+parentId.toString()+dsIndex.toString()
					businessDS.id = dsUniqueId
					businessDS.name = businessDS.datasource_name
					let datasource_id = businessDS.datasource_id
					let allBOsInDS = _.filter(response.result,(obj)=>{
						return obj.ds_id === datasource_id
					})
					if(!allBOsInDS)
						return
					let sorted_response = _.sortBy(allBOsInDS,'object_name')
					sorted_response.forEach((obj,index)=>{
						obj.type =_this.boObjectType
						obj.isBO = true
						obj.useRadio = true
						obj.parentId = parentId
						obj.id = "bo"+dsUniqueId+index
						obj.name = obj.object_name,
						obj.object_version = obj.object_version
						obj.datasource_index = dsIndex
						obj.datasource_id = datasource_id
						obj.children = []
					})
					businessDS.children = _.cloneDeep(sorted_response)
					boByDS.push(businessDS)
				})
		     	parent.children = boByDS
	    		// this.selectedObject = [parent]
			    if(selectedDetails){
					let dsChild = parent.children[selectedDetails.datasource_index]
					if(!dsChild){
						alert('datasource index '+datasource_index.toString()+' not found')
						return
					}
			    	dsChild.children.forEach((obj,index)=>{
			    		if(obj.object_id == selectedDetails.selected_object_id){
			    			_this.getBORelatedTableData(obj,'',[1,dsChild.id,obj.id])
							_this.selectedNode = obj
			    		}
			    	})
			    }
			    _this.treeDefaultOpenIds = [1]
		    }).catch(error_response => {
		    	_this.loader = false

		      if(error_response){

		      }
		      else {

		      }
		    });
		},
		getDataMapList(parent,selectedDetails){
			var parentId = parent.id
		    var user_id = this.user_id
		    var client_id = this.clientId
		    var get_bo_list_url = config.PUBLISHER_URL + '/published_objects_in_env'
		    var data = {"client_id": client_id, "env_id": this.env_id,'filter':[{"column_name":"object_type",'operator':"_eq_","value1":'DM'}],"page_size":500}
		    var _this = this
		    return postToServer(_this, get_bo_list_url, data, true).then(response => {
		      response = _.sortBy(response.result,'object_name')
		      _this.loader = false
		    	response.forEach((obj,index)=>{
		    		obj.type =_this.dmObjectType
		    		obj.isDM = true
		    		obj.useRadio = true
		    		obj.parentId = parentId
		    		obj.id = "dm"+parentId.toString()+index
					obj.name = obj.object_name
					obj.object_version = obj.object_version
		    		obj.children = []
		    	})
		     	parent.children = response
			    if(selectedDetails){
			    	parent.children.forEach((obj,index)=>{
			    		if(obj.object_id == selectedDetails.selected_object_id){
			    			_this.getDMRelatedData(obj,selectedDetails,[2,obj.id])
							_this.selectedNode = obj
			    		}
			    	})
			    }
			    _this.treeDefaultOpenIds = [2]

		    }).catch(error_response => {
		      if(error_response){

		      }
		      else {

		      }
		    });
		},
		getDMRelatedData(parent,selectedDetails,items_for_open){
			let parentId = parent.id
			var data = {
				object_id: parent.object_id,
				object_revision: parent.object_version || "1",
				object_type_code: "DM",
				env_id: this.env_id
			};
			var _this = this;

			_this.tableListLoader = true;
			return postToServer(
				_this,
				config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA,
				data
			)
			.then(response => {
				let res = response.object_json;
				console.log('response.object_json',response.object_json)
				let	object_list = _.filter(res.graph_data.cells,function (obj){
					return obj.table_name
				})
				object_list = _.sortBy(object_list,'table_name')
				object_list.forEach((obj,index)=>{
					obj.parentId = parentId
					obj.id = "dmobj"+parentId+index.toString()
					obj.isDMObj = true

					if(obj.table_type === this.boObjectType){
						obj.type=this.boObjectType
						obj.object_name = obj.table_name
					}
					else{
						obj.type="tbl"
						obj.isDmTable = true
					}
					obj.name = obj.table_name
					if(obj.type==="tbl" && obj.table_description)
						obj.name = obj.name +' '+obj.table_description
					obj.parent_edc_type = _this.dmObjectType
					obj.parent_edc_id = parent.object_id
					obj.children = []
				})

				parent.otherInfo = {"join_data":res.business_data.joins,"used_object_details_for_dm":res.dragged_tbl_list_with_its_type}

				parent.children = object_list
				if(selectedDetails){
					parent.children.forEach((obj,index)=>{
						if(obj.type === _this.boObjectType)
							_this.getBORelatedTableData(obj)
						_this.treeDefaultOpenIds = items_for_open
					})
				}
			})
			.catch(error_response => {

			});
		},
		getBORelatedTableData(parent,request_for,items_for_open){
		    var _this = this
		    var parentId = parent.id
		    var data_to_send = {
		      'object_id':parent.object_id,
		      'env_id':this.env_id,
		      'object_name':parent.object_id,
		      'request_for':request_for,
			  'datasource_id':_this.allDatasourceList[parent.datasource_index].business_data.datasource_id
		    }
		    var get_bo_details_url = config.PUBLISHER_URL + '/get_object_details_for_dataviewer'
		    return postToServer(_this, get_bo_details_url, data_to_send).then(response => {
		      if(response){
		        if(!request_for){
		        	let merge_tables_detail = response.merge_tables_detail || []
		        	parent.merge_tables_detail = merge_tables_detail
		        	let sorted_tables = _.sortBy(response.only_table_cells,'table_name')
		        	for(let i=0;i<sorted_tables.length;i++){
		        		let currentCell = sorted_tables[i]
		        		currentCell.id = "tbl"+parentId+i
		        		currentCell.parentId = parentId
		        		currentCell.isBoTable = true
		        		currentCell.type="tbl"
		        		currentCell.name = currentCell.merged_label_text || currentCell.table_name
		        		currentCell.parent_edc_type = _this.boObjectType
		        		currentCell.parent_edc_id = parent.object_id
						currentCell.datasource_index = parent.datasource_index

		        		if (currentCell.table_description)
		        			currentCell.name = currentCell.name +" - "+ currentCell.table_description
		        		currentCell.children = []
		        	}
		           parent.children = sorted_tables
		           parent.otherInfo = response
		        }
		       if(items_for_open)
			    	_this.treeDefaultOpenIds = items_for_open

		      }
		    }).catch(error_response => {

		    });

		},
		getTableColumns(item){
			var _this = this
			let dataForColumns = _this.getAgentJsonForColumns(item.table_name,item.datasource_index)
			let parent_edc_id = ''

			if (item.parent_edc_id)
				parent_edc_id = item.parent_edc_id

			return postToServer(this,config.AGENT_API_URL+'/check_catalog_table',dataForColumns).then(
				response =>{
				if(response){
					console.log('column fetched successfully')
					let column_list = JSON.parse(response.result.result)
					console.log('column parsed successfully')
					let column_with_description = []
					let revised_col_list = []
					if (response.description)
						column_with_description = response.description
					column_list.forEach((obj,index)=>{
						obj.type = "col"
						obj.isSelected = false
						obj.table_name = item.table_name
						obj.parentId = item.id
						obj.edc_business_object_id = parent_edc_id
						obj.id = "col"+item.id+index
						obj.unique_id_by_parent = index
						obj.isColumn = true
						obj.name = obj.column_name
						obj.datasource_index = item.datasource_index
						if(obj.description)
							obj.name = obj.column_name + " - "+obj.description
						revised_col_list.push(obj)
					})
					item.children = revised_col_list
				}
			}).catch(error=>{
				alert('column fetching failed')
			});
		},
		nodeRadioSelectionChange(item){
			this.selectedColumnList = []
		},
		performSelectColumns(children,is_recursive=false,insertAt=-1){
			let _this = this
			if(!is_recursive)
				children = this.selectedNode.children
			if(!children)
				return true
			for(let i=0;i<children.length;i++){
				if(children[i].isColumn && children[i].isSelected){
					let item = _this.getNewColumnForSelection(children[i])
					if(insertAt === -1){
						_this.selectedColumnList.push(item)
					}
					else{
						_this.selectedColumnList.splice(insertAt, 0, item)
						insertAt++
					}
					children[i].isSelected = false
				}
				else{

					_this.performSelectColumns(children[i].children,true,insertAt)
				}

			}
			return true
		},
		getNewColumnForSelection(item){
			let alias_name = this.getUniqueAlias(item.column_name.trim())

			return {
				"column_name":item.column_name.trim(),
				"column_display_name":item.name.trim(),
				"table_name":item.table_name,
				"col_alias":alias_name,
				"edc_business_object_id":item.edc_business_object_id,
				"datasource_index":item.datasource_index
			}
		},
		getUniqueAlias(alias,itemIndex){
			if(!alias)
				return ''
			let _this = this
			alias = alias.toUpperCase()
			let original_alias = alias
			_this.selectedColumnList.forEach((obj,index)=>{
				if(itemIndex === index)
					return
				if(obj.col_alias.toLowerCase() === alias.toLowerCase())
					alias = original_alias.toUpperCase()+"_"+(index+1)
			})
			if (itemIndex > -1)
				_this.selectedColumnList[itemIndex].col_alias = alias
			return alias
		},
		removeSelectedItem(item_index){
			this.selectedColumnList.splice(item_index,1)
		},
		performSearch(searchText,tagId){
			if(tagId === "objectTree"){
				this.tree_search = searchText
				return
			}
	        var columnList = document.getElementById(tagId)
	        var list_items = columnList.getElementsByClassName('custom-list-item')
	        var filter = searchText.toUpperCase()
	        for(var i = 0; i < list_items.length; i++){
	            if (list_items[i].innerText.trim().toUpperCase().indexOf(filter) > -1)
	              list_items[i].style.display = "";
	            else
	              list_items[i].style.display = "none";
	        }
	    },
	    saveBusinessView(){
			let _this = this
			if (_this.object_revision == "N/A"){
				var object_revision = "1"
			}else{
				var object_revision = _this.object_revision
			}
	    	let businessData = {
	    		"object_json":_this.getBusinessJson(),"object_name":this.businessViewName,"object_id":this.businessViewId,"object_type_code":"BV", 
				"client_id":this.clientId,"added_by":this.loggedInUser,"object_revision":object_revision?object_revision:'1',
				"description":this.businessViewDescription,"env_id":this.env_id,"env_name":this.env_name, 
				'erp_code' : this.erp_code, 'erp_version' : _this.erp_version, template:this.isTemplate
	    	}
	    	_this.loader = true
	    	postToServer(_this,config.REPOSITORY_API_URL + '/manage_object',businessData).then(response => {
				_this.loader = false
				_this.object_revision= response.object_revision
	    		this.businessViewId = response.object_id;
	    	}).catch(error_response=>{
	    		_this.loader = false
	    		alert(error_response.toString())
	    	})
	    },
	    getBusinessJson(){
	    	let businessJson = {"selected_object_type_code":this.selectedNode.type,"selected_object_id":this.selectedNode.object_id,"selected_object_version":this.selectedNode.object_version,"selected_object_name":this.selectedNode.object_name, "columns_for_view":this.selectedColumnList,"show_distinct_records":this.showDistinctRecords}
	    	if(this.selectedNode.type === this.boObjectType)
				businessJson['datasource_index'] = this.selectedNode.datasource_index
			return businessJson
		},
	    getDataSourcesDetails(response){
	    	console.log('datasource=>' ,response)
		  	var _this = this
			var datasourceInfo = {}
			if(response.business_data){
				datasourceInfo['business_data'] = _.cloneDeep(response.business_data)
				datasourceInfo['engine_data'] = _.cloneDeep(response.business_data)
			}
			datasourceInfo['dictionary_data'] ={}
			datasourceInfo['object_librarian_data']={}
			datasourceInfo['control_data'] ={}
			datasourceInfo['acceleration_type']=''
			let acceleratorDetails = response.accelerator_details
			if(acceleratorDetails){
			if(acceleratorDetails.dictionary_datasource)
				datasourceInfo['dictionary_data'] = acceleratorDetails.dictionary_datasource

			if(acceleratorDetails.object_librarian_datasource)
				datasourceInfo['object_librarian_data'] = acceleratorDetails.object_librarian_datasource

			if(acceleratorDetails.control_datasource)
				datasourceInfo['control_data'] = acceleratorDetails.control_datasource

				datasourceInfo['acceleration_type'] = acceleratorDetails.accelerator_type
			}
			datasourceInfo['db_type']=datasourceInfo['business_data']['database_type']
			datasourceInfo['database_type'] = datasourceInfo['db_type']
			return datasourceInfo
		},
		showPreview(){
			if(!this.gridPreview)
				return
			var _this = this
			let job_data = _this.getAllMandatoryInfo()
			job_data['all_available_ds'] = job_data['ds_info'].all_available_ds
			job_data['all_available_datasources'] = job_data['ds_info'].all_available_ds
			job_data['is_column_details_required'] = true
			job_data['business_view_details'] = _this.getBusinessJson()
			job_data['business_view_details']['is_preview_request'] = true
			job_data['is_super_admin'] = true
			if(_this.selectedNode.type === _this.dmObjectType){
				job_data['business_view_details']["used_object_details_for_dm"] = _this.selectedNode.otherInfo.used_object_details_for_dm
			}
			else{
				job_data['business_view_details']['merge_tables_detail_for_bv'] = _this.selectedNode.merge_tables_detail
			}
			job_data['business_view_details']['join_data'] = this.selectedNode.otherInfo.join_data
			job_data['business_view_details']['show_distinct_records'] = _this.showDistinctRecords
			let url = config.DATA_VIEWER_URL + '/preview_business_view_data'
			_this.loader = true
			postToServer(_this, url,job_data).then(response => {
				// This was fresh entry. So need add this to mainDataList.

			    var new_mainlist_obj = _.cloneDeep(_this.tableList)
			      var column_list =  response.columns_details
			     // var column_list =  obj.data.columns_details.split(',')
			     console.log('column_list',column_list)
			      _.forEach(column_list,function(colObj){
			        colObj = JDEFormatterObj.formatColumn(colObj,true,job_data.ds_info.business_data.database_type)
			        })
			       new_mainlist_obj['itemkey'] = "rn"
			       new_mainlist_obj.total_count = 0
			      if(response.data.length > 0){
			        new_mainlist_obj.total_count = response.data[0].total_count;
			      }

		        new_mainlist_obj.headers = column_list
		        _this.assignRowForcefully(new_mainlist_obj,response.data)
			    _this.tableList = new_mainlist_obj
				_this.loader = false
			}).catch(error=>{
				_this.loader = false
				alert("failed to load data for grid view")
				_this.gridPreview = false
			})
		},
		assignRowForcefully(gridObj,rows){
	    	let _this = this
	    	_this.$nextTick(function () {
	          gridObj.rows = rows
	        })
	  	},
		getAllMandatoryInfo(){
			let datasourceInfo = this.getDataSourcesDetails(_.cloneDeep(this.allDatasourceList[0]))
			let allDatasourceInfo = []
			for(let i=0;i<this.allDatasourceList.length;i++)
				allDatasourceInfo.push(this.getDataSourcesDetails(_.cloneDeep(this.allDatasourceList[i])))
			datasourceInfo['all_available_ds'] = allDatasourceInfo
			let mandatoryInfo = {"ds_info": datasourceInfo}
			mandatoryInfo["client_id"] = this.clientId
			mandatoryInfo['db_type'] = datasourceInfo.business_data.database_type
			mandatoryInfo['env_id'] = this.env_id
			mandatoryInfo['user_id'] = this.user_id
			return mandatoryInfo
		},
		getBusinessViewForEdit(create_new_version){
			let _this = this;
			_this.loader = true
			if(_this.$session.get('create_new_version') || _this.$route.query.create_new_version || create_new_version){
				var create_new_version = true
			}
			else{
				var create_new_version = false
			}
			var data = {
				object_id: this.businessViewId,
				object_revision: _this.object_revision ? _this.object_revision : '1',
				object_type_code: 'BV',
				create_new_version : create_new_version,
				env_id: _this.env_id,
				env_name: _this.env_name
			};
			postToServer(
				_this,
				config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA,
				data
			).then(response => {
				_this.loader = false
				_this.object_revision = response.object_revision
				_this.businessViewName = response.object_name
				_this.businessViewDescription = response.description
				_this.manageObjectJson(response.object_json)
				if(response.erp_code){
					this.erp_code = response.erp_code
					this.erp_version = response.erp_version
				}
				this.isTemplate = response.template
			}).catch(error=>{
				_this.loader = false
				alert('failed to load business view')
			})
		},

		copyGraph() {
			var _this = this;
			var data = {
				object_id: this.$route.params.business_view_id,
				object_revision:_this.object_revision
			};

			_this.tableListLoader = true;
			postToServer(
					this,
					config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA,
					data
				)
				.then(response => {
					var res = response;
					_this.auto_increment_id = res.id ? res.id : 0;
					_this.businessViewName = "Copy of " + res.object_name;
					_this.manageObjectJson(res.object_json)
					if(res.erp_code){
						this.erp_code = res.erp_code
						this.erp_version = res.erp_version
					}
					this.isTemplate = res.template
				})
				.catch(error_response => {
					// this is for avoid saving on error on redesign
					_this.is_request_in_process = true
					_this.tableListLoader = false;
					if (error_response) {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = error_response;
						this.loader = null;
					} else {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = SERVER_ERROR;
					}
				});

			// }
		},


		manageObjectJson(business_json){
			var _this = this
			console.log(business_json)
			_this.selectedColumnList = business_json.columns_for_view
			_this.showDistinctRecords = business_json.show_distinct_records
			_this.editObjectDetails = business_json
			// here i am considering 0th item index is BO
			if(business_json.selected_object_type_code === _this.dmObjectType){
				_this.items = [_this.default_items[1]]
				_this.getDataMapList(_this.items[0],business_json)
			}
			else{
				_this.items = [_this.default_items[0]]
				_this.GetBusinessObjectList(_this.items[0],business_json)
			}
			// setTimeout(function(){
			// 	_this.$session.remove('bv_for_edit')
			// },20000)
		},
		getAgentJsonForColumns(table_name,datasource_index){
			let ds_info = this.getDataSourcesDetails(this.allDatasourceList[datasource_index])
			let dictionary_data_schema = ds_info.dictionary_data.schema_name
		    let dictionary_conn_str = ds_info.dictionary_data.connection_string
		    let dictionary_data_id = ds_info.dictionary_data.datasource_id
		    let dictionary_mapped_agent_id = ds_info.dictionary_data.mapped_agent_id
		    let acceleration_type = ds_info.acceleration_type
		    let object_lib_schema = ds_info.object_librarian_data.schema_name
		    let object_lib_conn_str = ds_info.object_librarian_data.connection_string
		    let object_lib_id = ds_info.object_librarian_data.datasource_id
			return {"job_id": 1,
                  "env_id": this.env_id,
                  "mapped_agent_id": ds_info.business_data.mapped_agent_id,
                  "connection_str": ds_info.business_data.connection_string,
                  "ds_id": ds_info.business_data.datasource_id,
                  "dictionary_data_schema": dictionary_data_schema,
                  "dictionary_conn_str": dictionary_conn_str,
                  "dictionary_data_id": dictionary_data_id,
                  "dictionary_mapped_agent_id": dictionary_mapped_agent_id,
                  "acceleration_type": acceleration_type,
                  "object_lib_conn_str": object_lib_conn_str,
                  "object_lib_schema": object_lib_schema,
                  "object_lib_id": object_lib_id,
                  "client_id": this.clientId,
                  "column_desc_req": true,
                  "get_columns_details_only": true,
                  "cache_ops_allowed": true,
                  "agent_data": {"table_name": table_name,
                                 "db_type": ds_info.business_data.database_type,
                                 "schema_name": ds_info.business_data.schema_name},
                  "is_table_schema": true}
		},
		toggleSelectedCol(item,event){
			var _this = this

			// item isSelected comes as a undefined by default. So we need to make it boolean
			if(typeof(item.isSelected) != 'boolean')
				item.isSelected = false
			if(_this.isBelongToSelectedParent(item.parentId))
				item.isSelected = !item.isSelected
			else{
				item.isSelected = false
				return
			}

			/*
				**Multiselect functionality implementation.There has to all below assumptions should be TRUE
				1) There has to be event object and event.shiftKey = True
				2) Last selected item should be selected true
				3) Current item and Last selected item should be child of same parent

				**Logic
				If above assumptions are true , then make select all columns which come between current and last selected. For traversing, go from lower to upper index.
			*/

			if(event && event.shiftKey && _this.lastSelectedColumn.isSelected && _this.lastSelectedColumn.parentId === item.parentId){
				let lowerIndex = _this.lastSelectedColumn.unique_id_by_parent
				let upperIndex = item.unique_id_by_parent
				if(lowerIndex > item.unique_id_by_parent){
					lowerIndex = item.unique_id_by_parent
					upperIndex = _this.lastSelectedColumn.unique_id_by_parent
				}
				let parentItem = _this.getItemById(item.parentId,_this.items)
				if(!parentItem)
					return
				for(let i=lowerIndex;i<=upperIndex;i++)
					parentItem.children[i].isSelected = true
			}
			_this.lastSelectedColumn = item
		},
		isBelongToSelectedParent(parentId){
			// this function is to check is selected column belongs to the selected object for business view
			// this is recursion function to reach to column parent based on the hierarchy.
			// [1,2] ids belong to DM and BO as a root
			var _this = this
			if(!parentId || !this.selectedNode || [1,2].indexOf(parentId)>-1)
				return false
			if(this.selectedNode.id === parentId)
				return true
			let parent_item = _this.getItemById(parentId,_this.items)
			if(_.isEmpty(parent_item))
				return false
			return _this.isBelongToSelectedParent(parent_item.parentId)

		},
		getItemById(item_id,items){
			// this function to fetch item details by id. Each item can contain child so ,we have to write recursive function to check at each hierarchy.
			var _this = this
			var requestedItem = {}
			for(var i=0;i<items.length;i++){
				let item = items[i]
				if(item.id === item_id){
					return item
				}
				else{
					if(item.children && item.children.length){
						requestedItem = _this.getItemById(item_id,item.children)
						if(!_.isEmpty(requestedItem)){
							return requestedItem
						}
					}
				}
			}
			return {}
		},
		startDrag(event,source){
			// console.log(event.item.dataset)
			var _this = this
			console.log('source',event)
			_this.dragObject = {}
			if(event.item.dataset)
				Object.assign(_this.dragObject, event.item.dataset)

		},
		onMove ({relatedContext, draggedContext}) {

		},
		dragEnd(event){
			var _this = this
			if(!event || !event.from || !event.to || !_this.dragObject || !_this.dragObject.itemId || event.from.id != 'fromDrag' || event.to.id !='toDrag')
				return
			let dragged_item = _this.getItemById(_this.dragObject.itemId,_this.items)
			if(!dragged_item || !dragged_item.isColumn)
				return
			// now make make it toogle.toggleSelectedCol function will make it selected if not selected
			if(!dragged_item.isSelected)
				_this.toggleSelectedCol(dragged_item)

			// now simple call performSelectColumns
			_this.performSelectColumns([],false,event.newIndex)
		},
		templateOptionChanged(value){
			this.isTemplate = value
			this.saveBusinessView()
		}
	}
}
</script>
