import * as anonymousObj from './anonymousFunctions.js'
import * as constObj from './constants.js'
var highlighterRubberbandCell =constObj.CELL_HIGHLIGHTER

export function drawRubberRect(that,rubberRectId,rect,position,event){
	let startX = position.x
	let startY = position.y
	let endX = event.offsetX
	let endY = event.offsetY
	if(startX > endX){
		startX = endX
		endX = position.x
	}
	if(startY > endY){
		startY = endY
		endY = position.y
	}

	if(startX < 5)
		startX = 5
	if(endX > that.paperWidth)
		endX = that.paperWidth-5
	if(startY < 5)
		startY = 5
	if(endY > that.paperHeight)
		endY = that.paperHeight-5

	let rubberCell = that.edcGraph.getCell(rubberRectId)
	if(rubberCell){
		rubberCell.position(startX,startY)
		rubberCell.size(endX-startX,endY-startY)
	}
	else{
		rect.position(startX,startY)
		rect.size(endX-startX,endY-startY)
		rect.addTo(that.edcGraph)
	}
}

export function resizeRubberRect(that,rubberRectId,joint,mainPaper,rectAreaCells){
	let rubberCell = that.edcGraph.getCell(rubberRectId)
	if(!rectAreaCells)
		rectAreaCells = []
	if(rubberCell){
		rectAreaCells = []
		let rectbBox = joint.g.rect(that.edcGraph.getCell(rubberRectId).getBBox());
		let models = that.edcGraph.findModelsInArea(rectbBox);
		if(models.length <=1){
			removeRubberBandRect(that,rubberRectId,mainPaper,rectAreaCells)
			return rectAreaCells
		}
		let lowerX = 0
		let lowerY = 0
		let widthX = 0
		let heightY = 0
		let firstElementFound =false
		models.forEach((model)=>{
			if(model.prop('is_rubber')|| model.get('type') === 'link' || model.prop('is_embed'))
				return rectAreaCells
			let modelId = model.id
			rectAreaCells.push(modelId)
			mainPaper.findViewByModel(anonymousObj.getEmbeddedCell(model)).unhighlight(null,highlighterRubberbandCell)
			mainPaper.findViewByModel(anonymousObj.getEmbeddedCell(model)).highlight(null,highlighterRubberbandCell)
			let bBox = that.edcGraph.getCell(modelId).getBBox()
			if(bBox){
				if(!firstElementFound){
					firstElementFound = true
					lowerX = bBox.x
					lowerY = bBox.y
					widthX = bBox.x + bBox.width
					heightY = bBox.y +bBox.height
				}
				else{
					if(lowerX > bBox.x)
						lowerX = bBox.x
					if(lowerY > bBox.y)
						lowerY = bBox.y
					if(widthX < (bBox.x + bBox.width))
						widthX = bBox.x + bBox.width
					if(heightY < (bBox.y + bBox.height))
						heightY = bBox.y + bBox.height
				}
			}
		})
		// code for keep some space between component and the 
		lowerX = lowerX>15? lowerX-15:1;
		lowerY = lowerY>10? lowerY-5:1;
		widthX =  widthX<that.paperWidth? widthX+10:that.paperWidth-5;
		heightY = heightY<that.paperHeight? heightY+30:that.paperHeight-10;
		
		lowerX = anonymousObj.getNearestGridSnapPosition(lowerX)
		lowerY = anonymousObj.getNearestGridSnapPosition(lowerY)
		widthX = anonymousObj.getNearestGridSnapPosition(widthX)
		heightY = anonymousObj.getNearestGridSnapPosition(heightY)

		rubberCell.position(lowerX,lowerY)
		rubberCell.resize(widthX-lowerX,heightY-lowerY)
		return rectAreaCells
	}
}

export function removeRubberBandRect(that,rubberRectId,mainPaper,rectAreaCells){
	if(that.edcGraph.getCell(rubberRectId)){
		rectAreaCells = []
		that.edcGraph.removeCells([that.edcGraph.getCell(rubberRectId)])
		let models = that.edcGraph.getCells();
			models.forEach((model)=>{
				mainPaper.findViewByModel(anonymousObj.getEmbeddedCell(model)).unhighlight(null,highlighterRubberbandCell)
			})
	}
}

export function moveRubberBandRect(that,joint, rubberRectId,mainPaper,rectAreaCells,event){
	if(!rectAreaCells)
		rectAreaCells = []
	let rect = joint.g.rect(that.edcGraph.getCell(rubberRectId).getBBox());
	let models = that.edcGraph.findModelsInArea(rect);
	models.forEach((model)=>{
		if(model.prop('is_rubber')|| model.get('type') === 'link' || model.prop('is_embed'))
			return
		if(rectAreaCells.indexOf(model.id)>-1)
			model.translate(event.tx,event.ty,{ restrictedArea: that.edcGraph.getCell(mainPaper.getArea()) })
	})
}