/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'colapse_v2': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M12 32v20h40V33" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M32 39l6-8 6-8H20l6 8z"/><path pid="2" d="M32 8v24" _fill="none" _stroke="#000" stroke-width="4" stroke-linecap="round"/>'
  }
})
