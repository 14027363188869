<template>
    <v-card elevation-1>
        <v-card-title style="background:#494949;color:white;padding: 0px 7px;">
            <v-list style="background:#494949;color:white;">
                <v-list-tile-content>
                    <v-list-tile-title>{{heading}}</v-list-tile-title>
                </v-list-tile-content>
            </v-list>
            <v-spacer></v-spacer>
            <v-icon @click="onCancel" color="white">close</v-icon>
        </v-card-title>
        <v-card-text>
            <label>{{label}}</label>
        </v-card-text>
        <v-card-actions>
            <vc-button :itemText="lblSuccess" @click.native="onSuccess"></vc-button>
            <v-spacer></v-spacer>
            <vc-button :itemText="lblCancel||'No'" @click.native="onCancel"></vc-button>
        </v-card-actions>
    </v-card>
</template>
<script>

export default {
    name: 'dataTable',
    data: function () {
        return {

        }
    },
    props: ['heading', 'label', 'lblSuccess', 'lblCancel'],
    methods: {
        onSuccess(){
            this.$emit('onSuccess');
        },
        onCancel(){
            this.$emit('onCancel');
        }
    }
}
</script>
