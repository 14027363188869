<template>
	<div id="workflowdesigner">
		<!-- <v-container class="EDC-Row breadcrumbBackground">
			<v-row class="EDC-Row">
				<v-col class="EDC-Col text-truncate" align="left" cols="11">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col" align="left" cols="1">
                            <span class="override-font" style="float:left;">{{ticket_name}}</span>
                        </v-col>
                        <v-col class="EDC-Col" align="left" cols="1">
                            <span class="override-font" style="float:left;">Ticket Status:</span><span class="override-font" style="float:left;">{{ticket_status}}</span>
                        </v-col>
                    </v-row>

					<span v-if="!isReadOnly" style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>

					<span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
					(Readonly Mode)</span>
					<span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{dataSourceError||redoundoMsg}}</span>
				</v-col>
			</v-row>
		</v-container> -->
		<v-layout raw wrap>
			<div style="width:100%;height:100%;overflow: hidden;" id="main_container" ref="main_container"  :style="leftColStyles">
				<edc-graph :mainPaperDimentions="paperDimentions" :newObject="newCell" @sendElementEvent="receiveElementEvent" :showMinimap="false" @sendLinkEvent="receiveLinkEvent" @sendGraphEvent="receiveGraphEvent" :graphFor="graphFor" :graphLoader="tableListLoader" :cellToRemove="cellToRemove" :dataForGraphRedesign="dataForGraphRedesign" :validationGraphObj="validationGraphObj" :isReadOnly="isReadOnly" :updatedStepData="updatedStepData" key="work_flow" :changedBOForPolicy="changedBOForPolicy" :updatedReferenceDetails="updatedReferenceDetails" @sendNonGraphEvent="receiveNonGraphEvent" :openCell="openCell" :closeCell="closeCell" :running_node="running_node_id" :completed_nodes="completed_node_list"></edc-graph>
			</div>
		</v-layout>

	</div>
</template>
<script>
	import config from '../../config.json'
	import { shapes, dia,layout } from 'jointjs'
	import edcGraph from '@/views/edcGraph/edcGraph.vue'
	import {getNewWorkflowCells} from "../../methods/flowcharts/shapes.js"
	import Simplert from 'vue2-simplert'

	import {validate_flowchart} from "../../methods/call_validation.js"
	import RichTextBox from "@/views/richTextEditor/index.vue";

	import {getDefinedBusinessObjectList} from "../../methods/flowcharts/shapes.js"
	import {getTableList, getTableColumns, getStoredProcedureList,getAccelerationDetails} from "../../methods/agent_api_call.js"
	import { ADD_GRAPH_DATA, GET_PROCESS_GRAPH_DATA, GET_ALL_WORKFLOW_OBJECTS,GET_POLICY_BY_ID_LIST,GET_PRIMARY_KEYS } from '../../data/url_constants.js'
	import { post as postToServer } from './../../methods/serverCall.js';
	import { get as getToServer } from './../../methods/serverCall.js';
	import {SERVER_ERROR} from '../../data/client_message.js'

	import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
	import condition from './condition_dialog.vue'
	// import edcemail from './send_email_dialog.vue'
	import * as paperObj from './../../methods/edcGraph/papers.js'
	import * as shapesObj from './../../methods/edcGraph/shapes.js'
	import * as commonObj from './../../methods/edcGraph/commonFunctions.js'
	import * as anonymousObj from './../../methods/edcGraph/anonymousFunctions.js'
	import * as edcGraphConstants from './../../methods/edcGraph/constants.js'
	import * as edcGraphObj from './../../methods/edcGraph/graph.js'
	import {mapActions} from 'vuex'

	export default {
		components:{
			'edc-graph':edcGraph
			// 'edc-sendemail':edcemail
		},
		data(){
			return {
                graphFor:'work_flow',
                paperDimentions:{
					'width':1600,
					'height':1600
				},
                json:{},
                ticket_name:'',
                ticket_status:'',
                completed_node_list:[],
                running_node_id:'',
                changedBOForPolicy:{},
				workflow_id:'',
				dark_value:false,
				workflowname:'Untitled Workflow',
				dataSourceError:'',
				savedstatus:'edited',
				is_error_occured:false,
				object_revision:'',
				nameTextboxSize:20,
				hide_steps_panel:false,
                dataForGraphRedesign:{},
				redoundoMsg:'',
                validationGraphObj:{},
				colorCode:COLOR_CODE,
				cellToRemove:{},
                tableListLoader:false,
				review_dialog:false,
				reviewobj:{},
                updatedStepData:{},
				leftColStyles: { },
                updatedReferenceDetails:{},
				isObjectFullyLoaded:false, // call getting send to backend before object get loaded fully. 
                edcGraph:new dia.Graph({},{ cellNamespace: shapes }),
				WorkflowGraph:new dia.Graph({},{ cellNamespace: shapes }),
                isReadOnly:false,
                newCell:{},
                openCell:{},
				removeCell:{},
				accept_list:[],
				reject_list:[],
				showValidationDialog:false,
				mapping_dialog:false,
				selection_dialog:false,
				conditionobj:{},
				form_list:[],
				mappingobj:{},
				selectionobj:{},
				input_data:{},
				user_group_list:[],
				condition_dialog:false,
				// email_dialog:false,
				doubled_clicked_el:'',
				oldWFName:'',
				graphData:{},
				currentActiveStep:{},
                closeCell:{},
			}	
		},
		watch:{
		},
		mounted() {
            let _this = this
            this.setDesignerFor('Work Flow Designer')
			if(this.$route.params.workflow_json){				
				this.json = JSON.parse(this.$route.params.workflow_json)
                this.ticket_name = this.$route.params.ticket_name
                this.ticket_id = this.$route.params.ticket_id
				this.completed_node_list.push(this.$route.params.completed_node_id)
                this.running_node_id = this.$route.params.running_node_id
                console.log(this.running_node_id)
                 console.log(this.completed_node_list)
                var title = "Status For "+ this.ticket_name + " " + "("  + this.ticket_id + ")"
                this.setDesignerFor(title)
				this.hide_steps_panel = true
				this.edcGraph = {"cells": this.json.cells}
				this.dataForGraphRedesign = {
					"cells":this.json.cells,
					// "isAug2020ReleasedUI":res.object_json.isAug2020ReleasedUI
					"isAug2020ReleasedUI":true
				}
				if(this.$route.params.is_read_only)
		          this.isReadOnly = true
					
    
				
			}
			if(this.$route.query.json){				
				this.json = JSON.parse(this.$route.query.json)
                this.ticket_name = this.$route.query.ticket_name
                this.ticket_id = this.$route.query.ticket_id
				this.completed_node_list= JSON.parse(this.$route.query.completed_node_id_list)
                this.running_node_id = this.$route.query.running_node_id
                console.log(this.running_node_id)
                 console.log(this.completed_node_list)
                var title = "Status For "+ this.ticket_name + " " + "("  + this.ticket_id + ")"
                this.setDesignerFor(title)
				this.hide_steps_panel = true
				this.edcGraph = {"cells": this.json.cells}
				this.dataForGraphRedesign = {
					"cells":this.json.cells,
					// "isAug2020ReleasedUI":res.object_json.isAug2020ReleasedUI
					"isAug2020ReleasedUI":true
				}
				if(this.$route.query.is_read_only)
		          this.isReadOnly = true
					
    
				
			}

		},
		methods:{
            ...mapActions(['setDesignerFor']),
			receiveElementEvent(eventName,data){
				var _this = this
				let element = data.element
				_this.currentActiveStep = element
				// if(!element || !element.prop('is_embed'))
				// 	return
				// let _this = this
				// _this.currentActiveStep = element

				if(eventName === 'doubleclick'){
					_this.cellDoubleClicked(element,data.is_redo_undo)
				}
				this.changeSaveStatus()
				// if(data.stackObj)
				// 	_this.undoStack.push(data.stackObj)
				// if(eventName === 'add_element' || eventName === 'remove_element'){
				// 	_this.validateProcessDefinitionOnUserAction()
				// }
			},
			receiveLinkEvent(eventName,data){
				let link = data.link
				// if(data.stackObj)
				// 	this.undoStack.push(data.stackObj)
				// if(eventName === 'connect' || eventName ==='remove'){
				// 	this.validateProcessDefinitionOnUserAction()
				// }
			},
			receiveGraphEvent(eventName,data){
				let _this = this
				_this.graphData = data.graphData
				_this.edcGraph = data.edcGraph
				// if(edcGraphConstants.GRAPH_EVENTS_FOR_REDESIGN.indexOf(eventName)>-1){
				// 	_this.changeSaveStatus()
				// }
			},
			OnDefinationNameChange(){
				this.changeSaveStatus()

			},
			receiveNonGraphEvent(eventName,data){
				// if(eventName === 'step_name_changed'||eventName === 'step_name_changed_from_inside'){
				// 	this.reverseStepChangeData(data,eventName)
				// }
				// else if(eventName === 'definition_name_change')
				// 	this.reverseDefinitionName(data)
				// else if(eventName === 'dragged_table'){
				// 	data['user_action'] = 'add_table'
				// 	data['action_to_take'] = 'remove_table'
				// }
				// else if(eventName === 'remove_table'){
				// 	data['user_action'] = eventName
				// 	data['action_to_take'] = 'add_table'
				// }
				// else if(eventName === 'join_link_deleted'){
				// 	data['user_action'] = eventName
				// 	data['action_to_take'] = 'add_join_link'
				// }
				// else if(eventName === 'add_column'){
				// 	data['user_action'] = eventName
				// 	data['action_to_take'] = 'remove_column'
				// } else if(eventName === 'remove_column'){
				// 	data['user_action'] = eventName
				// 	data['action_to_take'] = 'add_column'
					
				// 	let isExistAlready = _.filter(this.undoStack,(obj)=>{return obj.step_id === data.step_id && obj.action_to_take==='add_column' && obj.column.column_name === data.column.column_name && obj.column.id === data.column.id})
				// 	if(isExistAlready.length)
				// 		return
				// } 
				// else if(eventName ==='step_limit_change'){
				// 	data['user_action'] = eventName
				// 	data['action_to_take']=eventName
				// } else if(eventName === 'run_on_target_change'){
				// 	data['user_action'] = eventName
				// 	data['action_to_take']=eventName
				// } else if(eventName === 'driver_table_change'||eventName === 'business_obj_change'||eventName==='new_table_dragged' || eventName ==='table_replaced' || eventName ==='selection_filter_change'){
				// 	data['user_action'] = eventName
				// 	data['action_to_take']=eventName
				// 	this.reverseOldNew(data)
				// }
				// else
				// 	return

				// this.undoStack.push(data)
			},
			cellDoubleClicked(cell,is_redo_undo,calledTime = 0){
				var _this = this
				this.doubled_clicked_el = cell
				if(this.doubled_clicked_el.prop('steptype') === 'Validate'  || this.doubled_clicked_el.prop('steptype') === 'Review'){
					this.showValidationDialog = true
				}
				if(this.doubled_clicked_el.attributes.stepname == 'Condition'){
				this.accept_list = []
				this.reject_list = []
				var links = this.edcGraph.getConnectedLinks(this.doubled_clicked_el, { inbound: true })
				if(links[0] && links[0].get('source')){
					var source_id = links[0].get('source').id
				}else{
					var source_id = null
				}
				if(!source_id)
					return false
				var out_links = this.edcGraph.getConnectedLinks(this.doubled_clicked_el, { outbound: true })
				debugger
				for (var i = 0; i <= out_links.length - 1; i++) {
					var link_id = out_links[i].id
					var target_id = out_links[i].get('target').id
					var target = this.edcGraph.getCell(target_id)
					let isKeytarget_id = this.accept_list.some((elem) => target_id === elem.cell_id);
					this.reject_list.push({"cell_name":target.attributes.attrs.text.text,"cell_id":target_id, "link_id": link_id})
					this.accept_list.push({"cell_name":target.attributes.attrs.text.text,"cell_id":target_id, "link_id": link_id})
				}
				var data_to_pass = {
					"cell_id": this.doubled_clicked_el.id,
					"cell_obj": this.doubled_clicked_el,
					"is_condition": true,
					"accept_list": this.accept_list,
					"reject_list": this.reject_list,
					"on_accept": this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').on_accept: '',
					"accept_link": this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').accept_link: '',
					"on_reject":this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').on_reject: '',
					"reject_link": this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').reject_link: '',
				}
				this.conditionobj = data_to_pass
				this.condition_dialog = true;

			}
			// if(this.doubled_clicked_el.attributes.stepname == 'Send Email Request'){
			// 	this.email_dialog= true
			// }
			if(this.doubled_clicked_el.attributes.stepname == 'Form Mapping'){
				var data_to_pass = {
					"input_form_id": this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').input_form_id: '',
					"output_form_id": this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').output_form_id:'',
					"mapping_details": this.doubled_clicked_el.prop('input_data')?this.doubled_clicked_el.prop('input_data').mapping_details:'',
					"form_list":this.form_list
				}
				this.mappingobj = data_to_pass
				this.mapping_dialog= true
			}
			if(this.doubled_clicked_el.attributes.stepname == 'Form Selection'){
				var data_to_pass = {
					"form_list":this.form_list,
					"selected_form":this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').selected_form:'',
					"action":this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').action:'',
				}
				this.selectionobj = data_to_pass
				this.selection_dialog= true
			}
			if(this.doubled_clicked_el.attributes.stepname == 'Review'){
				var data_to_pass = {
					"user_group_list": this.user_group_list,
					"selected_user_group_list": this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').selected_user_group_list:'',
					"minimum_group_approver_count": this.doubled_clicked_el.prop('input_data')?this.doubled_clicked_el.prop('input_data').minimum_group_approver_count:'',
					
				}
				this.reviewobj = data_to_pass
				this.review_dialog= true
			}
			},
			save_data(data){
				this.workflowdialog = false;
				var graph_data = this.edcGraph.toJSON();
				var accept_link_id = data.accept_link
				var reject_link_id = data.reject_link
				// link.prop('labels/0/attrs/text/text')
				if(data.is_condition){
					this.doubled_clicked_el.prop('on_accept',data.on_accept)
					this.doubled_clicked_el.prop('on_reject',data.on_reject)
					this.doubled_clicked_el.prop('reject_link',data.reject_link)
					this.doubled_clicked_el.prop('accept_link',data.accept_link)
					this.condition_dialog = false
					this.edcGraph.getCell(accept_link_id).prop('labels/0/attrs/text/text', 'Accept')
					this.edcGraph.getCell(reject_link_id).prop('labels/0/attrs/text/text', 'Reject')
					this.doubled_clicked_el.prop('on_accept',{"link_id":data.on_accept.link_id,"cell_id":data.on_accept.cell_id,"cell_name":data.on_accept.cell_name})
					this.doubled_clicked_el.prop('on_reject',{"link_id":reject_link.link_id,"cell_id":data.on_reject.cell_id,"cell_name":data.on_reject.cell_name})
					this.currentActiveStep.prop('input_data',data)
				}
				
				this.savedstatus = 'edited'
				this.changeSaveStatus()
			
		},
		// save_email_conf(data){
		// 	this.email_dialog = false;
		// 	var graph_data = this.edcGraph.toJSON();
		// 	this.currentActiveStep.prop('input_data',data)
		// 	this.savedstatus = 'edited'
		// 	this.changeSaveStatus()
		// },
		save_mapping(data){
			this.mapping_dialog = false;
			// var graph_data = this.edcGraph.toJSON();
			this.currentActiveStep.prop('input_data',data)
			this.savedstatus = 'edited'
			// this.changeSaveStatus()
		},
		save_selection(data){
			this.selection_dialog = false;
			this.currentActiveStep.prop('input_data',data)
			this.savedstatus = 'edited'
		},
		save_review(data){
			this.review_dialog = false;
			this.currentActiveStep.prop('input_data',data)
			if(data.user_group_list)
				this.user_group_list = user_group_list
			this.savedstatus = 'edited'
		},
		cancle_mapping(){
		this.mapping_dialog = false;
		},
		cancle_condition(){
		this.condition_dialog = false;
		},
		cancle_review(){
			this.review_dialog = false
		},
		cancle_selection(){
			this.selection_dialog = false;
		},
		changeSaveStatus(){
				this.is_error_occured = false
				this.savedstatus = 'edited'
				// logic to save document only if user stop editing
				clearInterval(this.timer)
				this.timer = setInterval(this.AutoSaveGraph,3000)
			},
		AutoSaveGraph(){
			// return
			if(this.savedstatus === 'edited'){
				let data_send_backend = []
				var data = this.graphData;
				// var data = this.edcGraph.toJSON();
				var client_id = this.$session.get('client_id')
				var user = this.$session.get('email')
				data['isAug2020ReleasedUI'] = true
				var saved_data = {"object_json": data, 
					"erp_code": "N/A",
					"erp_version": "N/A",
					"object_type_code": "WF",
					"object_desc": "",
					"object_name": this.workflowname, 
					"object_revision": this.object_revision,
					"client_id": client_id, 
					"added_by": user, 
					"env_id": this.env_id, 
					"object_id": this.workflow_id, 
				}
				var url = config.IDE_API_URL+ADD_GRAPH_DATA
				postToServer(this, url, saved_data).then(response => {
					if(response){
						this.workflow_id = response.object_id
						this.workflowname = response.object_name
						this.object_revision = response.object_revision
						this.savedstatus = 'saved'
					}
				}).catch(error_response => {
					if(error_response){
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = error_response;
					}
					else {
						this.snackbar = true
						this.snackbartext = 'Something went wrong.Try Again';
						this.colorValue = 'error'
						
					}
				})
			}
		},
		RedesignGraph(create_new_version){
		     	if (this.$route.params.create_new_version || create_new_version) {
				var create_new_version = true;
			} else {
				var create_new_version = false;
			}

		     	// from object management page user click on create new version but env might not have create new version rights. So for that purpose added below check.

		     	var data = {
		     		"object_id": this.$route.params.workflow_id,
		     		"object_revision": this.$route.params.object_revision,
		     		"object_type_code": this.$route.params.object_type_code,
		     		"create_new_version": create_new_version,
		     		"env_id": this.$route.params.env_id,
		     		"env_name": this.$route.params.env_name
		     	}

		     	
		     	var _this = this;
		     	_this.tableListLoader = true;
		     	postToServer(this, config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA, data).then(response => {
	     			var res = response
					 _this.tableListLoader = false;
	     			_this.manageGraphResponse(res)
	     			if(create_new_version)
						this.isReadOnly = false
					// if(!this.timer)
					// 	this.timer = setInterval(this.AutoSaveProcessDesignerGraph,3000)
				}).catch(error_response => {
					_this.is_request_in_process = true
					_this.tableListLoader = false
					if(error_response){
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = error_response;
						this.loader = null 
					}
					else {
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = SERVER_ERROR;
					}
				})
			},
			manageGraphResponse(res,is_copy=false){
				if(is_copy){
					this.workflowname = 'Copy of ' +res.object_name
				}
				else{
					this.workflow_id = res.object_id
					this.workflowname = res.object_name
					this.oldWFName = res.object_name
				}
				
				this.object_revision = res.object_revision
				this.edcGraph = {"cells": res.object_json.cells}
				this.dataForGraphRedesign = {
					"cells":res.object_json.cells,
					// "isAug2020ReleasedUI":res.object_json.isAug2020ReleasedUI
					"isAug2020ReleasedUI":true
				}
				if(this.$route.params.is_read_only){
		          this.isReadOnly = true
		        }
		        else{
					this.startInitialSaveTimer(4000)
		        }

			},
			startInitialSaveTimer(timing){
	      	let _this = this
	      	// over come issue with load object before loadding anything, I am writing set timeout for 8 seconds
			setTimeout(()=>{
				_this.isObjectFullyLoaded = true
			},timing) 
	      },
		ShowObjDetails(){
	      },
		createNewVersion:function(record){
	            
	    },
		getAllUserGroups() {
            let _this = this;
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.USER_PROVISION_URL + '/get_all_group_details', request_data).then(response => {
            	_this.user_group_list = response;
               
            }).catch(error_response => {
            });
        },
		getFormList() {
          var user_id = this.$session.get('user_id')
                var client_id = this.$session.get('client_id')
          var get_workflow_list_url = config.IDE_API_URL + GET_ALL_WORKFLOW_OBJECTS
                var data = {"client_id": client_id, "object_type_code": "FRM"}
          postToServer(this, get_workflow_list_url, data).then(response => {
                        // this.taskList = response
                        this.form_list = response;
            }).catch(error_response => {
            // if(error_response){
            // 	this.snackbar = true
            // 	this.colorValue = 'error'
            // 	this.snackbartext = error_response;
            // }
            // else {
            // 	this.snackbar = true
            // 	this.colorValue = 'error'
            // 	this.snackbartext = SERVER_ERROR;
            // 	}
            });
		},
	}
}
</script>