/* eslint-disable */
require('./add_multiple_v2')
require('./Add_Multiple')
require('./add_v2')
require('./approval_approve_v2')
require('./approval_reject_v2')
require('./Approve')
require('./archive_dispose_v2')
require('./Archive_Dispose')
require('./Archive_Forget')
require('./archive_restore_solid')
require('./archive_restore_v2')
require('./Archive_Restore')
require('./archive_solid')
require('./archive_synchronize_v2')
require('./archive_unsynchronize_v2')
require('./archive_v2')
require('./Archive')
require('./autoarrange')
require('./Business Object 2')
require('./Business Object Delete')
require('./Business Object New')
require('./Business Object Sync')
require('./Business Object')
require('./business_object_v2')
require('./Business_Object')
require('./cancel_v2')
require('./Cancel')
require('./colapse_v2')
require('./columns_show_hide_v2')
require('./copy_v2')
require('./Copy')
require('./Database_Synonym')
require('./Database_Table')
require('./Database_View')
require('./Database_Worktable')
require('./datamap_v2')
require('./delete_v2')
require('./Delete')
require('./dependency_add')
require('./dependency_remove')
require('./Document_Delete')
require('./Document_Edit_old')
require('./document_edit_v2')
require('./Document_Edit')
require('./Document_Export')
require('./Document_Import')
require('./Document_New')
require('./document_publish_v2')
require('./document_report_view_v2')
require('./document_unpublish_v2')
require('./document_v2')
require('./Document')
require('./Download_CSV')
require('./Download_Excel')
require('./Download_PDF')
require('./Download')
require('./downloading_action')
require('./email_send_v2')
require('./Email_Send')
require('./email_v2')
require('./Email')
require('./forget_v2')
require('./Forget')
require('./history_v2')
require('./History')
require('./Information')
require('./item_activate')
require('./item_deactivate')
require('./job_details_v2')
require('./Job_Execute')
require('./job_run_v2')
require('./job_suspend_v2')
require('./Job_Suspend')
require('./job_terminate_v2')
require('./Job_Terminate')
require('./Menu_Approve_old1')
require('./Menu_Approve_old2')
require('./Menu_Approve')
require('./Menu_Configure')
require('./Menu_Data_Management')
require('./Menu_Develop')
require('./Menu_Download')
require('./Menu_Home')
require('./Menu_Run')
require('./More')
require('./move_down')
require('./move_up')
require('./New_old1')
require('./New')
require('./Notification')
require('./object_notes')
require('./Object_Publish')
require('./Object_Unpublish')
require('./Package')
require('./Password_Reset')
require('./ping_v2')
require('./Ping')
require('./Reject')
require('./report_download_v2')
require('./Report_Download')
require('./Report_New')
require('./Reverse_Operation')
require('./save_v2')
require('./Save')
require('./Schedule')
require('./search_v2')
require('./Search')
require('./Synchronize')
require('./time_v2')
require('./Time')
require('./User - Admin')
require('./User_Admin')
require('./user_password_reset_v2')
require('./user_unlock_v2')
require('./User_Unlock')
require('./validate')
require('./zoom_in')
require('./zoom_out')
