<template>
	<v-container class="EDC-Container">
		<v-card class="rounded-card EDC-Designer-Row">
			<v-row class="EDC-Row" justify="start">
          		<v-col class="EDC-Col text-truncate" cols="10" align="left">
					<ul class="breadcrumb" style="display:inline-flex;">
              			<li><a @click="savedata(true)">{{stepType}} </a></li>
              			<li>
							<input type="text" v-model="stepname" :title="stepname" :readonly="readonly" :size="nameTextboxSize"/>
						</li>
            		</ul>
				</v-col>
				<v-col class="EDC-Col" align="right">
					<v-icon style="font-size:20px;float:right;padding-left:12px !important;" class="v-align" @click="savedata(true)" title='close'>far fa-times-circle</v-icon>
				</v-col>
			</v-row>
			<v-row class="EDC-Row">
				<v-col cols="12">
					<v-stepper v-model="stepper" class="EDC-Stepper" non-linear>
						<v-stepper-header class="EDC-StepperHeader">
							<v-stepper-step step="1" class="EDC-StepperStep" editable>
								Form Selection
							</v-stepper-step>
							<v-divider class="EDC-StepperDivider"></v-divider>
							<v-stepper-step step="2" class="EDC-StepperStep" editable>
								Select {{ActionStepperName}}
							</v-stepper-step>
							<v-divider class="EDC-StepperDivider"></v-divider>
							<v-stepper-step step="3" class="EDC-StepperStep" :editable="showValidationStepper">
								Set Validations
							</v-stepper-step>
						</v-stepper-header>
						<v-stepper-items>
                			<v-stepper-content class="EDC-Stepper-Content" step="1" elevation="0">
								<v-row class="EDC-Row" style="padding-top:8px !important;">
									<v-col class="EDC-Col colPadding" cols="4" v-if="isReviewStep">
										<v-autocomplete label="Select Form" :items="form_list" item-text="object_name" 
										return-object v-model="selected_form" :rules="formSelectionRule" dense hide-details @change="resetFormDependentFields();getUserGroupRoles(selected_form);savedata()"></v-autocomplete>
									</v-col>
									<v-col class="EDC-Col colPadding" cols="4" v-if="isApprovalStep">
										<v-autocomplete label="Select Form" :items="form_list" item-text="object_name" 
										return-object v-model="approvalForms" multiple :rules="formSelectionRule" dense hide-details @change="resetFormDependentFields();savedata()"></v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="EDC-Row" style="padding-top:8px !important;" v-if="isReviewStep">
									<v-col class="EDC-Col colPadding" cols="4">
										<v-autocomplete label="Select Form Action" :items="formActionList" item-text="display_name" 
										item-value="name" v-model="selectedFormAction" dense hide-details @change="savedata()"></v-autocomplete>
									</v-col>
								</v-row>
							</v-stepper-content>
							<v-stepper-content class="EDC-Stepper-Content" step="2" elevation="0">
								<v-row class="EDC-Row">
									<v-col class="EDC-Col colPadding" cols="6">
										<v-card>
											<v-row class="EDC-Row">
												<v-col class="EDC-Col">
													<b>Available User Group List</b>
												</v-col>
											</v-row>
											<v-row class="EDC-Row">
												<v-col class="EDC-Col colPadding listCol style-2scroll">
													<draggable element="span"  v-model="user_group_list" :options="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false" @change="updateGroup($event)">
														<transition-group type="transition" :name="'flip-list'">
															<li class="list-group-item" v-for="(each, index) in user_group_list"
																:key="index+'available'">
																{{each.display_name}} 
															</li>
														</transition-group>
													</draggable>
												</v-col>
											</v-row>
										</v-card>
									</v-col>
									<v-col class="EDC-Col colPadding" cols="6">
										<v-card>
											<v-row class="EDC-Row">
												<v-col class="EDC-Col">
													<b>Selected User Group List</b>
												</v-col>
											</v-row>
											<v-row class="EDC-Row">
												<v-col class="EDC-Col colPadding listCol style-2scroll">
													<draggable element="span" v-model="selected_user_group_list" :options="dragOptions" :move="onMove"
														@change="updateGroup($event)">
														<transition-group type="transition" :name="'flip-list'">
															<li class="list-group-item" v-for="(each, index) in selected_user_group_list"
																:key="index+'selected'">
															{{each.display_name}} 
															</li>
														</transition-group>
													</draggable>
												</v-col>
											</v-row>
										</v-card>
									</v-col>
								</v-row>
								<v-row class="EDC-Row" v-if="isApprovalStep">
									<v-col class="EDC-Col colPadding" cols="8">
										<v-text-field type="number" min="1" v-model="minimum_group_approver_count" label="Minimum group approvers" :rules="[(v) => !!v || 'Field is required', (v) => v > 0 && v <= selected_user_group_list.length || 'The number must be from 1 to '+(selected_user_group_list.length)+'.']" ref="approval_count" :disabled="selected_user_group_list.length <= 0" dense hide-details @change="savedata()"></v-text-field>
									</v-col>
								</v-row>
							</v-stepper-content>
							<v-stepper-content class="EDC-Stepper-Content" step="3" elevation="0">
								<v-row class="EDC-Row" style="padding-top:8px !important;">
									<v-col class="EDC-Col colPadding" cols="4">
										<v-autocomplete label="Select Validation Type" :items="validationTypeList" item-text="display_name" 
										item-value="name" v-model="selectedValidationType" dense hide-details @change="addValidationDetails"></v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="EDC-Row" v-if="validationDetails">
									<v-col class="EDC-Col" cols="12">
										<v-card>
											<template v-for="(val,ind) in validationDetails">
												<v-row class="EDC-Row" style="padding-top:4px !important;" :key="ind+'val'">
													<v-col class="EDC-Col colPadding" cols="3" v-if="!isGeneralValidation">
														<v-autocomplete label="Select Validation" :items="selectedValitionTypeDetails" 
														item-text="display_name" item-value="type" v-model="val.type" 
														dense hide-details @change="savedata()">
														</v-autocomplete>
													</v-col>
													<v-col class="EDC-Col colPadding" cols="3">
														<v-autocomplete label="Error Type" :items="errorTypes" 
														item-text="display_name" item-value="name" v-model="val.error_type" 
														dense hide-details @change="savedata()">
														</v-autocomplete>
													</v-col>
													<v-col class="EDC-Col colPadding" :cols="isGeneralValidation?8:5">
														<v-autocomplete label="Form Fields" multiple :items="selectedFormFields" 
														item-text="label" item-value="fieldId" v-model="val.fields_to_validation" 
														dense hide-details return-object @change="savedata()">
														</v-autocomplete>
													</v-col>
													<v-col class="EDC-Col colPadding" cols="1">
														<svgicon class="svg-icon-grid svg-fill-grid" name="add_v2"  
														v-if="ind===validationDetails.length-1" @click="addValidationRow(val)"></svgicon>
														<svgicon class="svg-icon-grid svg-fill-grid" name="delete_v2" v-else @click="removeValidationRow(ind)" @change="savedata()"></svgicon>
													</v-col>
												</v-row>
											</template>
										</v-card>
									</v-col>
								</v-row>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>
<style scoped>
	.colPadding{
		padding: 8px !important;
	}

	.list-group-item {
  		cursor: move;
  		padding: 4px !important;
	}

	.list-group-item i{
  		cursor: pointer;
	}
	.listCol{
		height: 40vh;
		overflow-y: scroll;
	}

</style>
<script>
	import draggable from 'vuedraggable';
	import config from '../../config.json';
	import { post as postToServer } from '../../methods/serverCall.js';
	import sortBy from 'lodash/sortBy';
	export default {
		name:'RevisedReviewStep',
		components: {
        	draggable,
    	},
		data(){
			return{
				selectedFormFields:[],
				stepType:'form_review',
				stepname:'Form Review',
				nameTextboxSize:20,
				stepper:1,
				ActionStepperName:'Reviewer',
				selectedFormAction:'',
				formActionList:[],
				validationTypeList:[],
				selectedValidationType:'',
				validationDetails:{},
				selectedValitionTypeDetails:[],
				errorTypes:[
					{"display_name":"Error","name":"error"},
					{"display_name":"Warning","name":"warning"}
				],
				validationDataJson:{
					"type":"",
					"error_type":"error",
					"fields_to_validation":[]
				},
				readonly:true,
				form_list:[],
				selected_form:[],
				formSelectionRule: [(v) => !!v || 'Field is required'],
				isDragging:false,
				valid: '',
	            approver_count: '',
	            tableObj: {},
	            form_list:[],
	            selected_form:'',
				approvalForms:[],
	            action:'',
	            isDragging: false,
	            user_group_list:[],
	            selected_user_group_list:[],
	            minimum_group_approver_count:'',
	            cell_name: null,
	            CellNameRule: [(v) => !!v || 'Field is required'],
	            isApprovalStep:false,
	            dialogHeader:'',
				stepLoadedFully:false,
			}
		},
		props:{
			reviewobj:{
				type:Object
			},
			formValidationJsonByType:{
				type:Object
			}
		},
		computed: {
	        dragOptions() {
	            return {
	                animation: 0,
	                group: 'description',
	                ghostClass: 'ghost'
	            };
	        },
			isReviewStep(){
				return this.stepType === 'form_review'
			},
			isGeneralValidation(){
				return this.selectedValidationType === 'general'
			},
			showValidationStepper(){
				return this.isReviewStep
			}
	    },
	    watch: {
        	reviewobj(newvalue,o){
	            this.initiallize(newvalue)
	        },
			formValidationJsonByType:{
				handler(newValue,oldValue){
					this.selectedValitionTypeDetails =  []
					if(_.isEmpty(newValue) || !this.selectedValidationType || !newValue[this.selectedValidationType])
						return
					this.selectedValitionTypeDetails = newValue[this.selectedValidationType]
				}
			},
			selected_form:{
				handler(newValue,oldValue){
					if(_.isEmpty(newValue) || _.isEmpty(newValue.object_json) || this.isApprovalStep)
						return
					this.selectedFormFields = _.filter(newValue.object_json.fieldList,(obj)=>{
						return obj.edcType === 'field'
					})
				}
			},
			selected_user_group_list:{
				handler(newValue){

				}
			}
	    },
	    mounted(){
	    	if(this.isApprovalStep){
		    	 if(this.$refs.approval_count.$el)
		            var txt_box = this.$refs.approval_count.$el
		        else
		            var txt_box = this.$refs.approval_count
		        	txt_box.addEventListener('keypress',function(evt) {
		                var charCode = (evt.which) ? evt.which : evt.keyCode;
		                if (charCode > 31 && (charCode < 48 || charCode > 57)){
		                    evt.preventDefault();
		                }
		                return true; 
		        });
	    	}
			this.initiallize(this.reviewobj)
			this.getUserGroupRoles()
	    },
	    methods:{
	    	updateGroup(event) {
	            let _this = this;
	            if (event.added)
	                _this.tableObj.availableColumn.push(event.moved.element);
	            if (event.removed) {
	                let index = _this.tableObj.availableColumn.findIndex((item, index) => {
	                    return (item.full_name == event.removed.element.full_name)
	                });
	                _this.tableObj.availableColumn.splice(index, 1);
	            }
	            this.orderList();
	        },
			getUserGroupRoles(form){
				var _this = this
				_this.resetUserGroupNameMapping();
				var form_id = form?form.object_id:null
				var data_to_send = {"client_id": _this.$session.get('client_id'),
									"mapping_by_group": true,
									"form_id": form_id}
				var url = config.Security_URL + '/get_user_group_role_mapping_by_role_type'
				postToServer(_this, url, data_to_send).then(response =>{
					if(response && form_id != null){
						var mapping_resp = response
						var i = 0
						for (i; i<_this.user_group_list.length;i++){
							var actions_lst = _.find(mapping_resp, ['group_id', _this.user_group_list[i].id])
							if (actions_lst){
								actions_lst = actions_lst.actions
								var role_name_str = actions_lst.toString()
								_this.user_group_list[i].display_name = _this.user_group_list[i].group_name+" ("+role_name_str+")"
							}
						}
					}
				}).catch(error_response => {
					
            	});
			},
			resetUserGroupNameMapping(){
				var _this = this
				_this.user_group_list.forEach(function(obj){
					obj.display_name = obj.group_name
				})
			},
	        onMove({
	            relatedContext,
	            draggedContext
	        }) {
	            const relatedElement = relatedContext.element;
	            const draggedElement = draggedContext.element;
	            return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
	        },
	        savedata(is_close=false){
                let _this = this
				if(!this.stepLoadedFully)
					return 
                var data = {'selected_user_group_list': this.selected_user_group_list, 'minimum_group_approver_count': this.minimum_group_approver_count,
				'selected_form': this.selected_form?this.selected_form.object_id:'','output_form':this.selected_form,
				'action':this.selectedFormAction}
				if(this.isApprovalStep){
					data['selected_form'] = []
					data['output_form'] = this.approvalForms
					if(this.approvalForms.length){
						data['selected_form'] = _.map(this.approvalForms,"object_id")
					}	
				}
				let validationDetailsJson = {"validation_details":[]}
				if(this.selectedValidationType && !_.isEmpty(this.selected_form)){
					validationDetailsJson.validation_details.push( {"validation_for":this.selectedValidationType,"validation_data":this.validationDetails})
					data['validation_details'] = validationDetailsJson.validation_details
				}
                _this.$emit('update-object', data,is_close)
	        },
	        orderList() {
	            let _this = this;
	            _this.worktableColumn = sortBy(_this.worktableColumn, ['group']) //this.optionColumn.sort((one,two) =>{return one.order-two.order; })
	        },
	        cancel(){
				this.stepper=1;
				// this.savedata(true);
            	this.$emit('cancel')
			},
			newUserList(selectedUsers){
				var _this = this
				var newUserLst = []
				_this.user_group_list.forEach(function(obj){
					if(!_.find(selectedUsers,["id", obj.id])){
						newUserLst.push(obj)
					}
				})
				_this.user_group_list = newUserLst
			},
	        initiallize(newvalue){
				this.stepLoadedFully = false
				this.stepper = 1
				this.approvalForms = []
				this.dialogHeader = newvalue.stepname
				this.stepname = newvalue.stepname
				this.stepType = newvalue.step_type
	            if(newvalue.user_group_list){
	                this.user_group_list = newvalue.user_group_list
	            }else{
	                this.user_group_list = []
	            }
	            if(newvalue.form_list){
					this.form_list = newvalue.form_list
				}else{
					this.form_list = []
				}
	            if(newvalue.selected_user_group_list){
	                this.selected_user_group_list = newvalue.selected_user_group_list

					this.newUserList(this.selected_user_group_list)
	            }else{
	                this.selected_user_group_list = []
	            }
	            if(newvalue.minimum_group_approver_count){
	                this.minimum_group_approver_count = newvalue.minimum_group_approver_count
	            }else{
	                this.minimum_group_approver_count = ''
	            }
	            if(newvalue.action){
					this.action = newvalue.action
				}else{
					this.action = []
				}
				if(newvalue.selected_form && !Array.isArray(newvalue.selected_form)){
					this.selected_form =  _.find(this.form_list,["object_id",newvalue.selected_form])
					this.getUserGroupRoles(this.selected_form)
				}else{
					this.resetUserGroupNameMapping();
					this.selected_form = null
				}
				if(newvalue.isReview)
					this.isApprovalStep = false
				else
					this.isApprovalStep = true
				if(this.isApprovalStep){
					if(newvalue.selected_form){
						var selectedForms = [newvalue.selected_form]
						if(Array.isArray(newvalue.selected_form))
							selectedForms = newvalue.selected_form
						for(let i=0;i<selectedForms.length;i++){
							let formObj =  _.find(this.form_list,["object_id",selectedForms[i]])
							if(formObj)
								this.approvalForms.push(formObj)
						}
						
					} else{
						this.approvalForms = []
					}
				}
				if(newvalue.formActionList)
					this.formActionList = newvalue.formActionList
				if(newvalue.formValidationList)
					this.validationTypeList = newvalue.formValidationList
				
				this.resetFormDependentFields()

				if(newvalue.action)
					this.selectedFormAction = newvalue.action
				if(newvalue.validation_details && newvalue.validation_details.length){
					let validationDetails = newvalue.validation_details[0]
					this.selectedValidationType = validationDetails.validation_for
					this.addValidationDetails(this.selectedValidationType, validationDetails.validation_data)
					this.validationDetails = validationDetails.validation_data
				}
				this.stepLoadedFully = true
	        },
			resetFormDependentFields(){
				this.selectedFormAction = ''
				this.selectedValidationType=''
				this.validationDetails={}
				this.selectedValitionTypeDetails = []
			},
			addValidationDetails(validationType,alreadyAddedJson={}){
				this.selectedValitionTypeDetails = []
				if(!this.isGeneralValidation)
					this.$emit('getSelectedValidationDetails',validationType)
					
				if(_.isEmpty(alreadyAddedJson)){
					this.validationDetails = [_.cloneDeep(this.validationDataJson)]
					this.savedata()
				}
			},
			addValidationRow(currentRow){
				if((!currentRow.type && !this.isGeneralValidation) || !currentRow.error_type || !currentRow.fields_to_validation.length){
					alert('Fill all the details for current row')
					return
				}
				this.validationDetails.push(_.cloneDeep(this.validationDataJson))
			},
			removeValidationRow(index){
				this.validationDetails.splice(index, 1)
			}
		}
	}
</script>