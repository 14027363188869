<template>
  <v-container :class="{EDCHeaderContainer:!isGrayTheme,EDCHeaderContainerGray:isGrayTheme}">
    <v-row class="mb-12 topLines" align="center" dense no-gutters>
      <v-col v-if="!isGrayTheme" class="EDC-PageHeader primary--text" cols="3" style="text-align: left !important;">
        <!-- <img src="../assets/images/essentio_logo_normal.png" alt="Essentio logo" style="width:70%;"> -->
        {{designerFor}}
      </v-col>
      <v-col v-else cols="3" class="EDC-PageHeader graythmprimary--text" style="text-align: left !important;">
        <!-- <img src="../assets/images/essentio_logo_white.png" alt="Essentio logo" style="width:70%;"> -->
        {{designerFor}}
      </v-col>
      <v-col cols="7">
       <!-- <v-row class="mb-12" dense no-gutters v-if="!hideHeader">
        <v-col :class="{normal:isSelected !== 'Home',focus:isSelected == 'Home'}" @click="routeUrl('/dashboard')">
          <v-menu offset-y class="menuCss">
            <template v-slot:activator="{ on }">
            <v-btn text small :class="{normal:isSelected !== 'Home',focus:isSelected == 'Home','primary--text':isSelected==='Home' && !isGrayTheme,'secondary--text':isSelected!='Home'  && !isGrayTheme,'graythmprimary--text':isSelected==='Home' && isGrayTheme,'graythmsecondary--text':isSelected!='Home' && isGrayTheme }" slot="activator" @click="isSelected='Home',routeUrl('/dashboard')" v-on="on">
              <svgicon name="Menu_Home" :original="true" style="margin-bottom:5%"></svgicon>
              <span> Home</span>
            </v-btn>
          </template>
          </v-menu>
        </v-col>
      </v-row> -->
    </v-col>

    <v-col cols="2">
      <v-row class="mb-12" dense no-gutters>
        <v-col cols="1"></v-col>
        <v-col cols="11" style="text-align: right !important; padding-right: 20px !important">
          <v-menu offset-y v-if="!hideHeader">
            <template v-slot:activator="{ on }">
              <v-btn :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" elevation="0" fab text x-small v-on="on" id="smallbtn">
                <i class="material-icons"  :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="My Notifications" id="smallicon">notifications_active</i>
              </v-btn>
            </template>
          </v-menu>
          <v-menu offset-y v-if="!hideHeader">
            <template v-slot:activator="{ on }">
              <v-btn fab text x-small v-on="on" id="smallbtn">
                <i class="material-icons" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="My Settings" id="smallicon">language</i>
              </v-btn>
            </template>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn fab text x-small v-on="on" id="smallbtn" :title="getUserTitle()">
                <!-- <i class="material-icons" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="Profile Settings" id="smallicon">person</i> -->
                <span id="profile" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}">{{initials}}</span>
              </v-btn>
            </template>
            <v-list style="cursor:pointer" class="EDC-MenuList">
               <v-list-item v-if="!hideHeader">
                <v-list-tile-title id="Edit Profile" @click="routeUrl('/userprofile')">Edit Profile</v-list-tile-title>
              </v-list-item>
              <v-list-item v-if="!hideHeader">
                <v-list-tile-title id="Change Password" @click="routeUrl('/changepassword')">Change Password</v-list-tile-title>
              </v-list-item>
              <v-list-item>
                <v-list-tile-title id="Sign out" @click="logout()">Sign out</v-list-tile-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-x v-if="!hideHeader" :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-btn fab text x-small v-on="on" id="smallbtn" style="border:0px !important">
                <i  class="material-icons" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="Applications" id="smallicon">apps</i>
              </v-btn>
            </template>
                <v-list style="cursor:pointer" class="EDC-MenuList">
               <v-list-item>
                <v-list-tile-title id="archivistproduct" @click="redirectProduct('Archivist')">Archivist</v-list-tile-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
       <!--  <v-col cols="3">

        </v-col>
        <v-col cols="3">

        </v-col>
        <v-col cols="3" style="padding-right: 10px">

        </v-col> -->
      </v-row>
    </v-col>

    <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'>
      {{ snackbartext }}
    </v-snackbar>
  </v-row>
</v-container>
</template>

<script>
var moment = require("moment-timezone")
import {
  post as postToServer
} from './../methods/serverCall.js';
import config from '../config.json'
import {
  SERVER_ERROR
} from '../data/client_message.js'
import {
  LOGOUT, UPDATE_LAST_USED_PRODUCT
} from '../data/url_constants.js'
import {
  DATASOURCE_SERVICE_ID,PUBLISH_SERVICE_ID,ENVIRONMENT_SERVICE_ID,REPOSITORY_SERVICE_ID,USER_PROVISIONING_SERVICE_ID,POLICY_SERVICE,SCHEDULER_SERVICE,ACCOUNT_MANAGEMENT_SERVICE,SECURITY_SERVICE,JOB_MANAGEMENT_SERVICE,JOB_CALENDAR_SERVICE, JOB_PLAN_SERVICE_ID
} from "../data/macros.js"
import {mapActions} from 'vuex'
import '../compiled-icons';
import {Archivist_Product,Workflow_Product,Dataviewer_Product} from '../constants/constants.js'


export default {
  name: "customHeader",
  watch:{
    '$store.state.notification':{
      handler(newValue){
        if(newValue === 'test')
          return false
        this.snackbar = true
        this.snackbartext = newValue
          //just to reset the last message
          this.setNotification('test')
        }
      },
      '$store.state.themeType':{
      handler(newValue){
        this.isGrayTheme = false
          if(newValue === 'dark')
            this.isGrayTheme = true
        }
      },
      '$store.state.designerFor':{
      handler(newValue){
        if(newValue){
          if(newValue.designerFor)
            this.designerFor = newValue.designerFor
          else
            this.designerFor = newValue
        }
        }
      }
    },
    data() {
      return {
        product_name:Archivist_Product,
        archivist_main_menu:['run','data management','develop','configurations','admin', 'configure'],
        workflow_main_menu:['approve','develop','admin', 'configurations'],
        isGrayTheme:false,
        snackbar:false,
        snackbartext:'',
        snackbartimeout:5000,
        colorValue:'green',
        userRole:this.$session.get('user_role_mapping'),
        have_account_right:this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE),
        configureMenu:false,
        isSelected:'Home',
        topNavigation: {},
          currenttime: '',
          interval: '',
          sinout_endpoint: '',
          user_name: this.$session.get('user_name'),
          time_zone:'',
          showRunMenu:this.getUserMenu(REPOSITORY_SERVICE_ID) || this.getUserMenu(JOB_MANAGEMENT_SERVICE) ||this.getUserMenu(SCHEDULER_SERVICE)|| this.getUserMenu(PUBLISH_SERVICE_ID) || this.getUserMenu(JOB_PLAN_SERVICE_ID),
          showDataManagementMenu:this.getUserMenu(JOB_MANAGEMENT_SERVICE),
          showDevlop: this.getUserMenu(REPOSITORY_SERVICE_ID) || this.getUserMenu(PUBLISH_SERVICE_ID),
          showConfigure: this.getUserMenu(DATASOURCE_SERVICE_ID) || this.getUserMenu(POLICY_SERVICE) ||this.getUserMenu(ENVIRONMENT_SERVICE_ID) ||  this.getUserMenu(JOB_MANAGEMENT_SERVICE) || this.getUserMenu(PUBLISH_SERVICE_ID) || this.getUserMenu(JOB_CALENDAR_SERVICE),
          showRuleMenu:this.getUserMenu(POLICY_SERVICE) ||  this.getUserMenu(JOB_MANAGEMENT_SERVICE) || this.getUserMenu(PUBLISH_SERVICE_ID)|| this.getUserMenu(JOB_CALENDAR_SERVICE),
          showAdmin:this.getUserMenu(USER_PROVISIONING_SERVICE_ID) || this.getUserMenu(SECURITY_SERVICE) || this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE),
          initials:'',
          fullName:'',
          designerFor:"EDC Object Designer",
        }
      },
      computed:{
        hideHeader() {
          return this.$route.path === '/businessobjectdesigner' || this.$route.path === '/Flowchart' || this.$route.path === '/workflowide'
        }
      },
      filters: {
        initCap: function (value) {
          if (!value) return ''
            let arr = value.split(' ');
          arr.map((string, index) => {
            string = string.toString()
            arr[index] = string.charAt(0).toUpperCase() + string.slice(1)
          })
          return arr.join(' ');
        }
      },
      beforeCreate(){
        this.userRole = this.$session.get('user_role_mapping');
      },
      mounted() {
        this.interval = setInterval(this.gettime, 1000);
        this.setthemeInfo(this.$session.get('theme_name'))
        this.product_name = window.sessionStorage.getItem('product') 
        this.topNavigation = this.getTopNavigation()       
        if(this.$session.get('last_name') && this.$session.get('last_name').length > 0){
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase() + this.$session.get('last_name').charAt(0).toUpperCase()
        }
        else{
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase()
         
        }
      },
      methods: {
        ...mapActions(['setNotification','setthemeInfo']),
        getTopNavigation(){
          return {
          'run':[
          {
            'name': "Job Status",
            'url': '/currentjoblist',
            role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
          },
          {
            'name': "Job List",
            'url': '/process_def_list_for_execution',
            role:this.getUserMenu(PUBLISH_SERVICE_ID)
          },
          {
            'name': "Job Plan Status",
            'url': '/jobplanstatus',
            role: this.getUserMenu(JOB_PLAN_SERVICE_ID)
          },
          {
            'name': "Job Plan",
            'url': '/jobplanlist',
            role: this.getUserMenu(JOB_PLAN_SERVICE_ID)
          },
          {
            'name': 'Scheduled Jobs',
            'url': '/scheduledJobList',
            role:this.getUserMenu(SCHEDULER_SERVICE)
          },
          // {
          //   'name': "Job Plan History",
          //   'url': '/jobplanhistory',
          //   role:this.getUserMenu(JOB_PLAN_SERVICE_ID)
          // },
          {
            'name': 'Scheduler History',
            'url': '/schedulejobhistory',
            role:this.getUserMenu(SCHEDULER_SERVICE)
          },
          ],
          'approve':[
          {
            name: 'My Approvals',
            url: '/managetaskforapprovallist',
            role:this.getUserMenu('default')
          },
          {
            name: 'My Requests',
            url: '/manageapprovalrequestlist',
            role:this.getUserMenu('default')
          },
          {
            name: 'Approval History',
            url: '/approvalhistorylist',
            role:this.getUserMenu('default')
          },
          ],
          'archive': [
          {
            'name': "Object Management",
            'url': '/processDefinitionList',
            role:((this.getUserMenu(PUBLISH_SERVICE_ID)||this.getUserMenu(REPOSITORY_SERVICE_ID)))
          },
          {
            'name': 'Workflow',
            'url': '/workflowrouteList',
            role:this.getUserMenu(REPOSITORY_SERVICE_ID)
          },
          {
            'name': "Publish History",
            'url': '/publish_history',
            role:this.getUserMenu(PUBLISH_SERVICE_ID)
          },
          {
            'name': "Publish Request List",
            'url': '/publish_request_list',
            role:this.getUserMenu(PUBLISH_SERVICE_ID)
          },
          ],
          'dataManagement':[
          {
            'name': 'Manage Archives',
            'url': '/archives',
            role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
          },
          {
            'name':'Business Object Activity',
            'url': '/allactivity',
            role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
          },
          {
            'name':'Activity By Table',
            'url': '/boactivitygraph',
            role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
          }
          ],
          'configure': [
          {
            name: 'Datasources',
            url: '/datasourcelist',
              role:this.getUserMenu(DATASOURCE_SERVICE_ID)  //this.userRole.is_superadmin || getUserMenu('datasource')
            },
            {
              'name': 'Environments',
              'url': '/Environmentlist',
              role:this.getUserMenu(ENVIRONMENT_SERVICE_ID) 
            },
            {
              'name': 'Policies',
              'url': '/policyList',
              role:this.getUserMenu(POLICY_SERVICE) 
            },
            {
              name: 'Job Calendar',
              url: '/calendar',
              role:this.getUserMenu(JOB_CALENDAR_SERVICE)   //this.userRole.is_superadmin || getUserMenu('datasource')
            }
            // ,
            // {
            //   'name': 'Notification Group',
            //   'url': '/get_all_notification_group',
            //   role:this.getUserMenu(JOB_CALENDAR_SERVICE)
            // }
            ],
            'notificationGroup':[
            {
              'name': 'Notification Group',
              'url': '/get_all_notification_group',
              role:this.getUserMenu(JOB_CALENDAR_SERVICE)
            },
            {
              'name': 'Map User Group',
              'url': '/get_all_notification_group_mapping',
              role:this.getUserMenu(JOB_CALENDAR_SERVICE)
            }
            ],

            'configure2':[
            {
              'name': 'Business Object Policy',
              'url': '/bopolicymapping',
              role:this.getUserMenu(POLICY_SERVICE)
            }
            ],
            'rule':[
            {
              name: 'Run Job',
              url: '/jobrulelist',
              role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
            },
            {
              name: 'Object Publishing',
              url: '/publishrulelist',
              role:this.getUserMenu(PUBLISH_SERVICE_ID)
            },
            {
              'name': 'Policy Change',
              'url': '/policyrulelist',
              role:this.getUserMenu(POLICY_SERVICE)
            },
            {
              name: 'Calendar Rule',
              url: '/processDefinitionrule',
              role:this.getUserMenu(JOB_CALENDAR_SERVICE)
            },
            {
              name: 'Notification Group Rule',
              url: '/get_all_notification_group_rule',
              role:this.getUserMenu(JOB_CALENDAR_SERVICE)
            },
            {
              name: 'Job Limit Rule',
              url: '/joblimitrule',
              role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
            },
            ],
            'agent':[
            // {
            //   name:'Download Agent',
            //   url: '/download_agent',
            //   role: false
            // },
            {
              name:'Agent List',
              url: '/agent_list',
              role: true
            },
            {
              name:'Agent Queue List',
              url: '/agent_queue_list',
              role: true
            }
            ],

            'admin': [
            {
              name: "Users",
              url: "/userlist",
              role:this.getUserMenu(USER_PROVISIONING_SERVICE_ID)
            },
            {
              name: 'User Roles',
              url: '/userroleset',
              role:this.getUserMenu(SECURITY_SERVICE)
            },            
            {
              name: 'Role Setup',
              url: '/rolelist',
              role:this.getUserMenu(SECURITY_SERVICE)
            },
            {
              name:'SMTP Settings',
              url:'/smtpsetting',
              role:this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE)
            },
            {
              name:'Client Settings',
              url:'/clientsettings',
              role:this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE)
              // role:this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE)
            },
            {
              name:'LDAP Settings',
              url:'/ldapsetting',
              role:this.getUserMenu(USER_PROVISIONING_SERVICE_ID)
              // role:this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE)
            },
            {
              name: 'Package Builder',
              url: '/package_builder',
              role:this.getUserMenu('joblist')
            },
            {
              name: 'Package List',
              url: '/PackageList',
              role:this.getUserMenu('default')
            },
            {
              name: 'Import Package',
              url: '/import_package',
              role:this.getUserMenu('joblist')
            },
            {
              name: 'Create Service Report',
              url: '/create_service_report',
              role:this.getUserMenu('joblist')
            }
            ]
          }
        },
        isArchivistProduct(){
          return true
          // return this.product_name === Archivist_Product
        },
        isWorkflowProduct(){
          return true
          // return this.product_name === Workflow_Product
        },
        isMenuBelongToCurrentProduct(menu){
          return true
          let menu_list = []
          if(this.product_name === Archivist_Product)
            menu_list = this.archivist_main_menu
          else if(this.product_name === Workflow_Product)
            menu_list = this.workflow_main_menu
          if(menu_list.indexOf(menu)>-1)
            return true
          return false
        },
        getUserMenu(serviceName){
          if(this.userRole.is_superadmin)
            return true
          if(this.userRole[serviceName])
            return true
          if (serviceName === 'default')
            return true
          return false;
        },
        routeUrl(path) {
          this.setthemeInfo(this.$session.get('theme_name'))
          this.$router.push(path);
        },
        gettime() {
          this.currenttime = moment().tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')
          this.time_zone = moment().tz(this.$session.get('time_zone_preference')).format('z')
        },
        logout() {
          var logout_endpoint = config.AUTHENTICATION_URL + LOGOUT
          var input_token = {
            'access_token': this.$session.get('access_token')
          }
          let protocol = this.$session.get('protocol')

          if(config.DOMAIN_NAME){
            this.$cookies.remove('access_token',null,config.DOMAIN_NAME)
            this.$cookies.remove('last_selected_env_id',null,config.DOMAIN_NAME)
          }
          
          postToServer(this, logout_endpoint, input_token).then(logout_response => {
            if (this.$cookies.isKey('Timeout')) {
              this.$cookies.remove('Timeout')
            }
            this.$session.destroy()
            window.open(protocol+'://'+window.location.hostname,"_self")
          }).catch(error_response => {
            if (this.$cookies.isKey('Timeout')) {
              this.$cookies.remove('Timeout')
            }
            this.$session.destroy()
            window.open(protocol+'://'+window.location.hostname,"_self")
          });
        },
        ReportRedirect(){
          this.$router.push({ name: 'ReportDownloadStatus',
            params:{"frompage": true}})
        },
        RedirectToApp(){
          return
          let routeData = this.$router.resolve({ name: 'approvalHistoryList'});
          // update last product_name as workflow
          var last_used_product_endpoint = config.USER_PROVISION_URL + UPDATE_LAST_USED_PRODUCT
          var inpute_data = {
            "user_id": this.$session.get('user_id'),
            "client_id": this.$session.get('client_id'),
            'product_name': Workflow_Product
          }
          postToServer(this, last_used_product_endpoint, inpute_data).then(last_used_product_response => {
            if (last_used_product_response) {
            }
          }).catch(error_response => {
          });
          window.open(routeData.href, '_blank');
        },
        redirectProduct(product_name){
          let _this = this
          let url = this.$session.get('protocol')+'://'
          let lProductName = product_name.toLowerCase()
          let queryString = '?product_key='+this.$session.get('access_token')+"&last_env="+this.$session.get('last_selected_env_id')
          let domain_name = this.$session.get('erp_cloud_host')
          if(config.DOMAIN_NAME)
            url = url +lProductName+'.'+config.DOMAIN_NAME+'/login'+queryString // for the server with domain
          else  if(lProductName == Archivist_Product.toLowerCase() && !config.ARCHIVIST_PORT)
            url = url + domain_name+":"+config.ARCHIVIST_PORT+'/login'+queryString // for client DEPLOYMENT.
          else if(lProductName == Archivist_Product.toLowerCase() && config.ARCHIVIST_PORT)
            url = url + domain_name+":"+config.ARCHIVIST_PORT+'/login'+queryString // for local DEVELOPMENT
          else if(lProductName == Dataviewer_Product.toLowerCase() && !config.DATAVIEWER_PORT)
            url = url + domain_name+":"+config.DATAVIEWER_PORT+'/login'+queryString // for client DEPLOYMENT.
          else if(lProductName == Dataviewer_Product.toLowerCase() && config.DATAVIEWER_PORT)
            url = url + domain_name+":"+config.DATAVIEWER_PORT+'/login'+queryString // for local DEVELOPMENT
          else{
            alert('selected product not configure')
            return
          }
          window.open(url, '_blank');    

          setTimeout(function(){
            _this.selectedproduct = ''
          },100)
        },
        getUserTitle(){
          if(this.$session.get('last_name') && this.$session.get('last_name').length > 0){
          this.fullName = this.$session.get('first_name') + " "+ this.$session.get('last_name') + ' | '+this.currenttime +' ('+this.time_zone+')'
          }
          else{
            this.fullName = this.$session.get('first_name') + ' | '+this.currenttime +' ('+this.time_zone+')'
          }
          return this.fullName
        }
      }
    }
</script>

<style>

    .normal{
      color:rgb(125, 125, 115) !important;
    }
    .focus{
      color:rgb(50,120,150) !important;
      font-weight: bold !important;
    }
    .width-60{
      width:60%
    }
    .menuCss{
      height:100%;
      padding-top: 0px;
      width:120px;
      font-size: 90%;

    }
    .topLine{
     background-color: white;
     color: #A0A0A0;
     line-height: 35px;
     position: fixed;
     top: 0px;
     width: 100%;
     margin: 0px !important;
     z-index: 20;
   }

   .main-header {
    background-color: RGB(51,51,51);
    width: 100%;
    z-index: 20;
    padding-top: 5px;
    margin: 0px;
    position: fixed;
    top: 30px;
    width: 100%;
    height: 66px;
  }

  .cust-logo {
    width: 90%;
    height: 80%;
  }

  .lightgray {
    color: rgb(125, 125, 115) ;
  }

  .v-list__tile:hover{
    background: rgb(51,51,51);
    color: white;
  }
  .activeLink {
    color: rgb(50,120,150) !important;
    font-weight: bold;
  }
  
  .ht-70 {
    height: 70px;
  }

  #menulistitemhover:hover {
    background-color:black;
    color:white !important;
  }

  .tabTitle {
    font-size: 90%;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  .v-tabs__container {
    height: 30px !important;
  }

  .v-btn{
    color:#A0A0A0 !important;
    padding:0 px;
    font-size:14px !important;
    text-transform: none;
  }

  .v-application .mb-12 {
    margin-bottom: 0px !important;
}

#smallbtn {
    height: 25px !important;
    width: 25px !important;
    color: #A0A0A0 !important;
    border: 1px solid #A0A0A0 !important;
    margin: 6px !important;
}

#smallicon {
    font-size: 16px;
}

</style>