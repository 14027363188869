// export const DATE_TIME_FORMAT = ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD', 'YYYY/DD/MM', 'DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM']
import _ from 'lodash'

export const DATE_TIME_FORMAT = [
{'user_format':'DD/MM/YYYY', 'python_format':'%d/%m/%Y'},
{'user_format':'YYYY/MM/DD', 'python_format':'%Y/%m/%d'},
{'user_format':'YYYY/DD/MM', 'python_format':'%Y/%d/%m'},
{'user_format':'DD-MM-YYYY', 'python_format':'%d-%m-%Y'},
{'user_format':'MM-DD-YYYY', 'python_format':'%m-%d-%Y'},
{'user_format':'YYYY-MM-DD', 'python_format':'%Y-%m-%d'},
{'user_format':'YYYY-DD-MM', 'python_format':'%Y-%d-%m'}]


// this function will give you the Ui date format , in backend we save python date format
export function GetUIDateFormat(python_format){
	var obj = _.find(DATE_TIME_FORMAT,["python_format",python_format])
	if(obj)
		return obj.user_format
	return 'YYYY/MM/DD'
}