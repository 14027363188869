<template>
    <v-card elevation-1>
        <v-card-title style="background:#494949;color:white;padding: 0px 7px;">
            <v-list style="background:#494949;color:white;">
                <v-list-tile-content>
                    <v-list-tile-title>{{heading}}</v-list-tile-title>
                </v-list-tile-content>
            </v-list>
            <v-spacer></v-spacer>
            <v-icon @click="onCancel" color="white">close</v-icon>
        </v-card-title>
        <v-card-text>
        <v-row class = "EDC-Row">
            <label style="float:left !important;">{{label}}</label>
        </v-row>
        <v-row class = "EDC-Row">
        <!-- <v-select
        v-model="selectedAction"
        item-text="text"
        item-value="value" @change="onChange"
        return-object
        :items="items" hide-details dense @input="onChange" style="margin:3px !important;"> -->
       <v-radio-group v-model="radios" @change="onChange" row hide-details dense>
                        <v-radio class="radioClass" label="Immediate" value="immediate"/>
                        <v-radio class="radioClass" label="After Running Job Finished" value="wait"/>
                      </v-radio-group>
        </v-select>
        </v-row>
        </v-card-text>
        <v-card-actions>
            <vc-button :itemText="lblSuccess" @click.native="onSuccess"></vc-button>
            <v-spacer></v-spacer>
            <vc-button :itemText="lblCancel||'No'" @click.native="onCancel"></vc-button>
        </v-card-actions>
    </v-card>
</template>
<script>

export default {
    name: 'dataTable',
    data: function () {
        return {
             items: [
                {"value": "immediate", "text":'Immediate'} ,
                 {"value":"wait", "text": 'After Running Job Finished'}],
                // selectedAction: {"value": "immediate", "text":'Immediate'}
                selectedAction:"wait"
        }
    },
    props: ['heading', 'label', 'lblSuccess', 'lblCancel'],
    methods: {
        onChange(record){
            this.selectedAction = record
        },
        onSuccess(){
            this.$emit('onSuccess',this.selectedAction);
        },
        onCancel(){
            this.$emit('onCancel');
        }
    }
}
</script>
