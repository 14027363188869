import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme:{
		themes:{
			light:{
				primary:'#607D8B',
				secondary:'#859AA4',
				datatablealternate:'#F5F5F5',
				graythmprimary:"#FFFFFF",
				graythmsecondary:"#BBBABA",
				apptext:"#636363"
			}
		},
		options: {
      customProperties: true,
    },
	}
});
