export function isValidNumber(keycode){
	let deleteKeyCode = 8;
  let backspaceKeyCode = 46;
  if ((keycode>=48 && keycode<=57) ||
         (keycode>=96 && keycode<=105)  || // for num pad numeric keys
         keycode === deleteKeyCode || // for delete key,
             keycode === backspaceKeyCode) // for backspace
         // you can add code for left,right arrow keys
    return true
  return false 
}