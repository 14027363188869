import Vue from 'vue'
import Router from 'vue-router'

import Login from '../components/authorization/login.vue'
import ForgotPassword from '../components/authorization/forgot_password.vue'
import ResendEmail from '../components/authorization/resend_email.vue'

import ResetPassword from '../components/authorization/reset_password.vue'
import ChangePassword from '../components/authorization/change_password.vue'
import ResetUserPassword from '../components/authorization/reset_user_password.vue'
import UserProfile from '../components/authorization/userProfile.vue'


import BusinessViewByBODM from '../components/business_view/business_view_by_bo_dm.vue'
import BusinessViewDesigner from '../components/business_view/business_view_designer.vue'
import DataMapDesigner from '../components/data_map/dataMapDesigner.vue'
import Businessobjectdesigner from '../components/business_object/edcBusinessObjectDesigner.vue'
import Flowchart from '../components/process_definition/edcProcessDesigner.vue'
import BusinessViewFor from '../components/business_view/business_view_for.vue'
import Businessobjectlanding from '../components/business_object/business_object_landing.vue'
import WorkflowDesigner from '../components/workflow/edcWorkflowDesigner.vue'
import WorkflowStatus from '../components/workflow/workflow_status.vue'


import designerLandingPage from '../views/designerLandingPage.vue'

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                breadcrumb: 'Home / Login'
            }
        },
        {
            path: '/forgotpassword',
            name: 'ForgotPassword',
            component: ForgotPassword,
            meta: {
                breadcrumb: 'Home / ForgotPassword'
            }
        },
        {
            path: '/forgot',
            name: 'ResetPassword',
            component: ResetPassword,
            meta: {
                breadcrumb: 'Home / ResetPassword'
            }
        },
        {
            path: '/changepassword',
            name: 'ChangePassword',
            component: ChangePassword,
            meta: {
                breadcrumb: 'Home / Change Password'
            }
        },
        {
            path: '/resetuserpassword',
            name: 'ResetUserPassword',
            component: ResetUserPassword,
            meta: {
                breadcrumb: 'Home / Reset User Password'
            }
        },
        {
            path: '/userprofile',
            name: 'userprofile',
            component: UserProfile,
            meta: {
                breadcrumb: 'Home / UserProfile'
            }
        },
        {
            path:'/businessviewbybodm',
            name:'businessviewbybodm',
            component:BusinessViewByBODM,
            meta: {
                breadcrumb: 'Home / BusinessViewByBODM'
            }

        },
        {
            path:'/businessviewdesigner',
            name:'businessviewdesigner',
            component:BusinessViewDesigner,
            meta: {
                breadcrumb: 'Home / BusinessViewGraph'
            }

        },
        {
            path:'/datamapdesigner',
            name:'datamapdesigner',
            component:DataMapDesigner,
            meta: {
                breadcrumb: 'Home / DataMapDesigner'
            }

        },
        {
            path: '/businessobjectdesigner',
            name: 'businessobject',
            component: Businessobjectdesigner,
            meta: {
                breadcrumb: 'Home / Business Object'
            }
        },
        {
            path: '/businessobjectdesigner',
            name: 'editbusinessobject',
            component: Businessobjectdesigner,
            meta: {
                breadcrumb: 'Home / Business Object'
            }
        },
        {
            path: '/Flowchart',
            name: 'processdefinition',
            component: Flowchart,
            meta: {
                breadcrumb: 'Home / Process Definition'
            }
        },
        {
            path: '/Flowchart',
            name: 'editprocessdef',
            component: Flowchart,
            meta: {
                breadcrumb: 'Home / Process Definition'
            }
        },
        {
            path:'/businessviewfor',
            name:'businessviewfor',
            component:BusinessViewFor,
            meta: {
                breadcrumb: 'Home / BusinessViewFor'
            }

        },
        {
            path: '/businessobjectlanding',
            name: 'businessobjectlanding',
            component: Businessobjectlanding,
            meta: {
                breadcrumb: 'Home / Business Object Landing'
            }
        },
        {
            path:'/designerlandingpage',
            name:'designerlandingpage',
            component:designerLandingPage,
            meta:{
                breadcrumb:'Home / Designer Landing Page'
            }
        },
        {
            path: '/workflowdesigner',
            name: 'WorkflowDesigner',
            component: WorkflowDesigner,
            meta: {
                breadcrumb: 'Home / Workflow Designer'
            }
        },
        {
            path: '/workflowstatus',
            name: 'WorkflowStatus',
            component: WorkflowStatus,
            meta: {
                breadcrumb: 'Home / Workflow Status'
            }
        },
    ]
})