<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
<!-- 			<v-row class="EDC-Row">
				{{createMainFilter()}}
			</v-row> -->
			<v-col cols="12" class="EDC-Col">
				<v-card class="EDC-CardSideMargin EDC-MainFilterCard">
					<v-container class="EDC-Container">
						<v-row class="EDC-Row">
							<v-col cols="6" class="EDC-Col">
								<span class="EDC-Heading">Main Group</span>				
							</v-col>
							<v-col cols="6" class="EDC-Col" align="right">
								<v-icon style="font-size:15px;float:right;" class="v-align" title='clear filter' @click="ClearFilter(1)">far fa-times-circle</v-icon>
							</v-col>			
						</v-row>
						<v-row class="EDC-Row">
							<edc-criteria key="maingrp" :policy_list="policy_list" :showpolicies="showpolicies" :columns_for_filter="new_columns_for_filter" :column_name_list="column_name_list" @savedata="saveCriteria" @new="add_new_filter_row" @delete="delete_filter_row" :acceleratorObj="acceleratorObj" :readonly='readonly' @addgroup="add_new_group" :level="1" :parentsteptype="parentsteptype" :restrictedColumnsForFilter="restrictedColumnsForFilter"></edc-criteria>
						</v-row>
						<v-row class="EDC-Row">
							<!-- Main Group Toggle -->
							<v-col cols="12" class="EDC-Col">
								<toggle-button v-if="criteriaObj.groupList.length" :labels="{checked: 'OR', unchecked: 'AND'}"
                style="margin-top:0px !important;margin-bottom:0px !important;" v-model="mainfilteroperation" :sync="true" :width="60" :color="{checked: '#75C791', unchecked: '#75C791', disabled: '#CCCCCC'}"
                :disabled="readonly" @change="createMainFilter()" />
							</v-col>
						</v-row>
						<v-row class="EDC-Row" v-for="(eachGrp,index) in criteriaObj.groupList" :key="index+ 'level1grp'">
							<v-card class="EDC-FilterCard">
								<v-container class="EDC-Row">
									<v-row class="EDC-Row">
										<v-col cols="6" class="EDC-Col">
											<span class="EDC-Heading">Group {{index+1}}</span>				
										</v-col>
										<v-col cols="6" class="EDC-Col" align="right">
											<v-icon style="font-size:15px;float:right;" class="v-align" @click="ClearFilter(2,index)" title='clear filter'>far fa-times-circle</v-icon>
										</v-col>			
									</v-row>
									<v-row class="EDC-Row">
										<edc-criteria :policy_list="policy_list" :showpolicies="showpolicies" :columns_for_filter="eachGrp.new_columns_for_filter" :column_name_list="column_name_list" @savedata="saveCriteria" @new="add_new_filter_row" @delete="delete_filter_row" :acceleratorObj="acceleratorObj" :readonly='readonly' :level="2" :grpindex="index" :key="index+ 'edclevel1grp'" @addgroup="add_new_group" :parentsteptype="parentsteptype" :restrictedColumnsForFilter="restrictedColumnsForFilter"></edc-criteria>
									</v-row>

									<v-row class="EDC-Row">
										<v-col cols="12" class="EDC-Col">
											<!-- Group  Child Toggle -->
											<toggle-button v-if="eachGrp.childs && eachGrp.childs.length > 0" :labels="{checked: 'OR', unchecked: 'AND'}"
					            style="margin-top:1px !important;margin-bottom:1px !important;" v-model="eachGrp.childfilteroperation" :sync="true" :width="60" :color="{checked: '#75C791', unchecked: '#75C791', disabled: '#CCCCCC'}"
					            :disabled="readonly" @change="createMainFilter()" />
										</v-col>
									</v-row>

									<v-row class="EDC-Row" v-for="(grpChild,grpchildindex) in eachGrp.childs" :key="'child'+grpchildindex+'grp'+index">
										<v-card class="EDC-FilterCard">
											<v-container class="EDC-Row">
												<v-row class="EDC-Row">
													<v-col cols="6" class="EDC-Col">
														<span class="EDC-Heading">Group {{index+1}} Child {{grpchildindex+1}}</span>				
													</v-col>
													<v-col cols="6" class="EDC-Col" align="right">
														<v-icon style="font-size:15px;float:right;" class="v-align" @click="ClearFilter(3,grpchildindex,index)" title='clear filter'>far fa-times-circle</v-icon>
													</v-col>			
												</v-row>
												<v-row class="EDC-Row">
													<edc-criteria :key="'child'+grpchildindex+'edcgrp'+index" :policy_list="policy_list" :showpolicies="showpolicies" :columns_for_filter="grpChild.new_columns_for_filter" :column_name_list="column_name_list" @savedata="saveCriteria" @new="add_new_filter_row" @delete="delete_filter_row" :acceleratorObj="acceleratorObj" :readonly='readonly' :level="3" :parentid="index" :grpindex="grpchildindex" :parentsteptype="parentsteptype" :restrictedColumnsForFilter="restrictedColumnsForFilter"></edc-criteria>
												</v-row>
											</v-container>
										</v-card>
										<v-row class="EDC-Row">
											<v-col cols="12" class="EDC-Col">
											<!-- Child Child Toggle -->
												<toggle-button v-if="grpchildindex < eachGrp.childs.length-1" :labels="{checked: 'OR', unchecked: 'AND'}"
			                	style="margin-top:1px !important;margin-bottom:1px !important;" v-model="grpChild.grp1filteroperation" :sync="true" :width="60" :color="{checked: '#75C791', unchecked: '#75C791', disabled: '#CCCCCC'}"
			                	:disabled="readonly" @change="createMainFilter()" />
											</v-col>
										</v-row>
									</v-row>
								</v-container>
							</v-card>
							<v-row class="EDC-Row">
								<v-col cols="12" class="EDC-Col">
									<!-- Group Group Toggle -->
									<toggle-button v-if="index < criteriaObj.groupList.length-1" :labels="{checked: 'OR', unchecked: 'AND'}"
	                style="margin-top:1px !important;margin-bottom:1px !important;" v-model="eachGrp.grp1filteroperation" :sync="true" :width="60" :color="{checked: '#75C791', unchecked: '#75C791', disabled: '#CCCCCC'}"
	                :disabled="readonly" @change="createMainFilter()" />
								</v-col>
							</v-row>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	import Criteria from './criteria'
	export default {
		name:'NewCriteria',
		components:{
			'edc-criteria':Criteria
		},
		data (){
			return {
				criteriaObj:{
					groupList:[],
				},
				auto_increment_id:1,
      	// new_columns_for_filter:[],
      	mainfilteroperation:false,
      	oldcriteriaObj:{
      		groupList:[]
      	},
      	oldMainFilter:[],
      	copyOfOldFilterObjDetails:{}
			}
		},
		props:{
			filtertablename:{
				type:String
			},
			columns_for_filter:{
				type:Array
			},
			column_name_list:{
				type:Array
			},
			policy_list:{
				type:Array
			},
			showpolicies:{
				type:Boolean
			},
			parentsteptype:{
				type:String
			},
			acceleratorObj:{
				type:Object
			},
			readonly:{
				type:Boolean
			},
			new_columns_for_filter:{
				type:Array
			},
			secondaryfilter:{
				type:Object
			},
			oldFilterObjDetails:{
				type:Object
			},
			redoUndoFilterObj:{
				type:Object
			},
			restrictedColumnsForFilter:{
				type:Array
			}
		},
		watch:{
      columns_for_filter:{
        handler(newvalue){
        	// this.designFilter(newvalue)
              // this.findBetween();
          console.log(newvalue)
        },
       	deep:true
      },
      column_name_list:{
        handler(newVal){

        },
        deep:true
      },
      acceleratorObj(newvalue){

      },
      readonly(newvalue){

      },
      policy_list(newvalue){
      	console.log('newcritpolicy',newvalue)
      },
      showpolicies(newvalue){
                
      },
      parentsteptype(newvalue){

      },
      new_columns_for_filter:{
      	handler(newvalue){
	      	if(!newvalue || !newvalue.length){
		      	this.designFilter(_.cloneDeep(this.columns_for_filter))
	      	}
	      },
	      deep:true
	    },
      secondaryfilter:{
      	handler(newvalue){
      	if(newvalue){
      		this.criteriaObj = newvalue
					this.mainfilteroperation = this.criteriaObj.mainfilteroperation
					if(!this.mainfilteroperation)
						this.mainfilteroperation = false
      	}
      	else
      		this.criteriaObj = {
      			groupList:[]
      		}
      	},
      	deep:true
      },
      redoUndoFilterObj:{
      	handler(newvalue){
      		if(!_.isEmpty(newvalue)){
      			this.new_columns_for_filter = newvalue.main_filterUI
      			this.criteriaObj = newvalue.secondary_filterUI
      			this.createMainFilter()
      		} else{
      			// this.new_columns_for_filter=[this.get_filter_column_tbl_obj()]
      		}
      	}
      },
      oldFilterObjDetails:{
      	handler(newvalue){
      		this.copyOfOldFilterObjDetails = _.cloneDeep(newvalue)
      	},
      	deep:true
      }
  	},
  	mounted(){
  		this.copyOfOldFilterObjDetails = _.cloneDeep(this.oldFilterObjDetails)
  		// this.add_new_filter_row(1)
  	},
  	methods:{
  		saveCriteria(filter,level,grpindex,parentid,isUserAction){
  			if(level === 1)
  				this.new_columns_for_filter = filter
  			else if(level === 2)
  			{
  			 	this.criteriaObj.groupList[grpindex]['new_columns_for_filter'] = filter
  			}
  			else{

  			}
  			this.createMainFilter(isUserAction)
  		},
  		createMainFilter(isUserAction){
  			var main_filter =this.manageFilter(_.cloneDeep(this.new_columns_for_filter))
  			
  			// here we need to emit empty current filter
  			if(!main_filter){
  				this.$emit('savedata',this.new_columns_for_filter,this.new_columns_for_filter,this.criteriaObj)
  				return 
  			}
  			if(!this.mainfilteroperation)
					this.mainfilteroperation = false

  			var main_filter_length = main_filter.length
  			var filter_group_list = _.cloneDeep(this.criteriaObj.groupList)
  			var filter_group_list_length = filter_group_list.length
  			var level2Filters = []
  			var level2Filters_length = 0
  			var level2FilterNumbers = 0
  			if(filter_group_list_length > 0){
  				/* This means user has defined sub query 
						1) Add AND/OR to last filter of main query
						2) check current filter has more than 1 filter. And if current filer has only 1 filter then it should have culumn_name (code in manageFilter function)
						3) Add '(' to first filter & ')' to last filter of each filter_group_list (code in manageFilter function)
						4) If filter_group_list has next filterlist then add currentgrouplist.filteroperation to running filter
  				*/
  				
  				for (var i = 0; i < filter_group_list.length; i++) {
  					let current_filter = _.cloneDeep(this.manageFilter(filter_group_list[i].new_columns_for_filter,true))
  					if(!current_filter)
  						continue

  					level2FilterNumbers++
  					if(level2Filters_length!=0)
  						level2Filters[level2Filters_length-1]['operation'] = 
									filter_group_list[i-1].grp1filteroperation

						level2Filters = _.concat(level2Filters,current_filter)
  					level2Filters_length = level2Filters.length


  					/* level 3 section start - comment level3 section in case of error*/
  					var childs = filter_group_list[i].childs
  					var level3Filters = []
  					var level3Filters_length = 0
  					var level3FilterNumbers=0
  					if(childs.length>0){
  						for(var j=0;j<childs.length;j++){
  							let current_child_filter = _.cloneDeep(this.manageFilter(childs[j].new_columns_for_filter,true))
  							if(!current_child_filter)
  								continue
  							level3FilterNumbers++
  							if(level3Filters_length!=0)
  								level3Filters[level3Filters_length-1]['operation']=childs[j-1].grp1filteroperation

  							level3Filters = _.concat(level3Filters,current_child_filter)
  							level3Filters_length = level3Filters.length
  							
  						}
  					}//child for loop ends

  					if(level3Filters_length>0){
  						level2Filters[level2Filters_length-1]['operation']=filter_group_list[i].childfilteroperation
  						level2Filters[level2Filters_length-1]['e_parentheses'] = ""

  						level3Filters[level3Filters_length-1].e_parentheses = level3Filters[level3Filters_length-1].e_parentheses+')'

  						if(level3FilterNumbers>1){
  							level3Filters[0].s_parentheses = level3Filters[0].s_parentheses+'('
								level3Filters[level3Filters_length-1].e_parentheses = level3Filters[level3Filters_length-1].e_parentheses + ')'
  						}
  						level2Filters = _.concat(level2Filters,level3Filters)
  						level2Filters_length = level2Filters.length
  					} /* level 3 section end - comment level3 section in case of error*/

  				} //level2 for loop end

					if(level2Filters_length > 0){
						 main_filter[main_filter_length-1]['operation'] = this.mainfilteroperation
						 if(level2FilterNumbers > 1){
							level2Filters[0].s_parentheses = level2Filters[0].s_parentheses+'('
							level2Filters[level2Filters_length-1].e_parentheses = level2Filters[level2Filters_length-1].e_parentheses+')'
						}
						main_filter = _.concat(main_filter,level2Filters)
					}
				}
  			console.log("newfilter",main_filter)
  			this.criteriaObj.mainfilteroperation = this.mainfilteroperation
  			this.$emit('savedata',main_filter,this.new_columns_for_filter,this.criteriaObj)

  			if(isUserAction){
  				this.emitRedoUndo()
  			}
  			return main_filter
  		},
  		emitRedoUndo(){
  			var _this = this
  			let newFilterObjDetails = {"main_filterUI":_.cloneDeep(this.new_columns_for_filter),"secondary_filterUI":_.cloneDeep(this.criteriaObj)}

				_this.$emit('redoUndoFilterUpdate',{"new_value":_.cloneDeep(newFilterObjDetails),"old_value":_.cloneDeep(_this.copyOfOldFilterObjDetails)})
				_this.copyOfOldFilterObjDetails = _.cloneDeep(newFilterObjDetails)
  			},
  		manageFilter(current_filter,add_paranthesis){
				let current_filter_length = current_filter.length
				if(current_filter_length === 0)
					return false
				// if(!current_filter[current_filter_length-1].column_name || !current_filter[current_filter_length-1].operator)
				if(!current_filter[current_filter_length-1].column_name && !current_filter[current_filter_length-1].operator)
				{
					current_filter.splice(current_filter_length-1,1)
					if(current_filter.length===0)
						return false
					current_filter_length = current_filter.length
				}
				if(add_paranthesis){
					current_filter[0].s_parentheses = '('
					current_filter[current_filter_length-1].e_parentheses = ')'
				}
				return current_filter

  		},
  		add_new_filter_row(level,grpindex,parentid,isUserAction){
  			if(level === 1){
  				this.add_operation_to_filter()
  				this.new_columns_for_filter.push(this.get_filter_column_tbl_obj())
  			}else if(level === 2){
  				this.criteriaObj.groupList[grpindex]['new_columns_for_filter'].push(this.get_filter_column_tbl_obj())
  			}else{
  				this.criteriaObj.groupList[parentid]['childs'][grpindex]['new_columns_for_filter'].push(this.get_filter_column_tbl_obj())
  			}
  			if(isUserAction)
  				this.emitRedoUndo()
  		},
  		delete_filter_row(index,level,grpindex,parentid){
  			let current_filter_length = 0
  			var filters = []
  			if(level === 1){
  				 filters = this.new_columns_for_filter
  			}
  			else if(level === 2){
  				filters = this.criteriaObj.groupList[grpindex]['new_columns_for_filter']
  			}else {
  				filters = this.criteriaObj.groupList[parentid]['childs'][grpindex]['new_columns_for_filter']
  			}
  			current_filter_length = filters.length
  				if(current_filter_length > 1 && current_filter_length-1 ===  index)
	        delete filters[index-1]['operation']
	      	filters.splice(index, 1)
	      if(current_filter_length > 1 && current_filter_length-1 ===  index)
	        delete filters[index-1]['operation']
	      
	      if(filters.length === 0){
	        var obj = _.cloneDeep(this.get_filter_column_tbl_obj());
	        filters.push(obj);
	      }
	      this.createMainFilter()
	      this.emitRedoUndo()
  		},
  		add_operation_to_filter(){
  			if(!this.new_columns_for_filter)
  				return
  			var index = this.new_columns_for_filter.length-1
		      if(index >= 0){
			  		if(!this.new_columns_for_filter[index]['operation'])
					    this.new_columns_for_filter[index]['operation'] = false
		      }
  		},
  		add_new_group(level,grpindex,isUserClicked){
  			if(level === 1)
  			this.criteriaObj.groupList.push({"new_columns_for_filter":[this.get_filter_column_tbl_obj()],"grp1filteroperation":false,'childs':[],"childfilteroperation":false})
  			else{
  				this.criteriaObj.groupList[grpindex]['childs'].push({
  					"new_columns_for_filter":[this.get_filter_column_tbl_obj()],"grp1filteroperation":true
  				})
  			}
  			if(isUserClicked)
  				this.emitRedoUndo()
  		},
  		get_filter_column_tbl_obj(){
  			return {
        's_parentheses': '',
        'column_name': '',
        'operator': '',
        'v_type1': 'Value',
        'v_type2': 'Value',
        'value1': '',
        'value2':'',
        'e_parentheses': '',
        'is_drv_table': false,
        'ref_row_id':'',
        'ref_step_id':'',
        'id':this.get_new_id()
      	}
  		},
  		ClearFilter(level,grpindex,parentid){
  			if(level === 1)
  			{
  				this.criteriaObj={
						groupList:[],
					}
					this.new_columns_for_filter = []
					this.add_new_filter_row(1)
  			}
  			else if(level ===2){
  				this.criteriaObj.groupList.splice(grpindex,1)
  			}
  			else{
  				this.criteriaObj.groupList[parentid]['childs'].splice(grpindex,1)
  			}
  			this.createMainFilter()
  		},
  		get_new_id(){
      return (++this.auto_increment_id)+300;
    },
    designFilter(existingfilter){

    	if(!existingfilter || existingfilter.length === 0)
    	{
    		this.criteriaObj={
					groupList:[],
				}
				if(this.new_columns_for_filter.length === 0)
				this.add_new_filter_row(1)
				return
    	}

    	// first check any filter has s_parentheses value
    	let obj = _.find(existingfilter,['s_parentheses','('])
    	if(!obj || existingfilter.length === 1)
    		// if there is not parenthesis or there is only one filter, its means there is no sub query
    		this.new_columns_for_filter = existingfilter

    	else{
    		
    		/*
					Logic for creating filter from existing desinged process definitions

					1) first check count of ) and (. It should match.
					2) start traversing each row until got first (.
					3) if ( got at first place means group start at first line so main group will be at end
					4) There should not be indepedent lines between the groups. Then throw error
    		*/
    		
    		// let openbfilters = _.filter(existingfilter,function(obj){
    		// 	return obj['s_parentheses'].includes('(')
    		// })

    		// let endbfilters = _.filter(existingfilter,function(obj){
    		// 	return obj['e_parentheses'].includes(')')
    		// })

    		// if(openbfilters.length != endbfilters.length){
    		// 	return false
    		// }
    		
    		var mainFilterList = []
				var grpFilterList = []
				var mainfilteroperation=false
				var mainFilterAtFirst = false
				if(!existingfilter[0]['s_parentheses'])
					mainFilterAtFirst = true
				for(var i=0;i<existingfilter.length;i++){
					if(existingfilter[i]['s_parentheses']){
						let grpFilter = this.getGrpFilter(existingfilter,i)
						i = grpFilter.group.i_value
						delete grpFilter.group.i_value
						grpFilterList.push(grpFilter.group)
					}
					else{
						mainFilterList.push(existingfilter[i])
					}
				}

				if(mainFilterList.length === 0){

					// this code is return to handle migration of Direct ship order PD 
					// step- check PO details. That process definition filters are like 
					// 1st( 2nd ( ..... 5th)) 6th( 7th).
					// Logic is remove brackets from the last 6th and 7th and pass it again to the above code
					if(existingfilter.length === 7 && existingfilter[5]['s_parentheses'] && existingfilter[6]['e_parentheses']){
						existingfilter[5]['s_parentheses'] = ''
						existingfilter[6]['e_parentheses'] = ''
						grpFilterList = []
						for(var i=0;i<existingfilter.length;i++){
							if(existingfilter[i]['s_parentheses']){
								let grpFilter = this.getGrpFilter(existingfilter,i)
								i = grpFilter.group.i_value
								delete grpFilter.group.i_value
								grpFilterList.push(grpFilter.group)
							}
							else{
								mainFilterList.push(existingfilter[i])
							}
						}
					}
					else{
						alert('Wrong filters group defined')
						return
					}
				}
				mainfilteroperation = mainFilterList[mainFilterList.length-1]['operation']
				if(!mainFilterAtFirst){
					mainFilterList = _.reverse(mainFilterList)
					debugger;
					let lastGrpChild= grpFilterList[grpFilterList.length-1].childs
					if(lastGrpChild && lastGrpChild.length > 0){
						let lastchild = lastGrpChild[lastGrpChild.length-1]["new_columns_for_filter"]
						mainfilteroperation= lastchild[lastchild.length-1]['operation']
					}
					else{
						let last_grp = grpFilterList[grpFilterList.length-1]['new_columns_for_filter']
						mainfilteroperation = last_grp[last_grp.length-1]['operation']
					}
					// now we have done reverse so need to switch operations
					for (var i = 0; i < mainFilterList.length-1; i++) {
						mainFilterList[i]['operation'] = mainFilterList[i+1]['operation']
					}
				}
				this.new_columns_for_filter = mainFilterList
				this.secondaryfilter = {"groupList":grpFilterList,"mainfilteroperation":mainfilteroperation}
				this.mainfilteroperation = mainfilteroperation
	    }
    },
    getGrpFilter(existingfilter,startindex){
			var grpFilter=[]
			var startParenthesis=0
			var childs = []
			let childfilteroperation=false // fiter between grp and first child
			let operationbetweengrp = false
			let is_first_child_found = false
			for(let i=startindex;i<existingfilter.length;i++){
				let currentFilter = existingfilter[i]
				if(currentFilter['s_parentheses'] === '(')
					startParenthesis++
				if(currentFilter['e_parentheses'] === ')')
					startParenthesis--
				if(startParenthesis === 2){
					// child found. So call get child method
					if(!is_first_child_found){
						childfilteroperation = existingfilter[i-1]['operation']
						is_first_child_found = true
					}
					let childGrpDetails = this.getChildFilter(existingfilter,i)
					i = childGrpDetails.i_value
					delete childGrpDetails.i_value
					operationbetweengrp = childGrpDetails.filters[childGrpDetails.filters.length-1]['operation']
					startParenthesis = childGrpDetails.startParenthesis
					childs.push({"new_columns_for_filter":childGrpDetails.filters})
				}
				else
				grpFilter.push(currentFilter)

				if(startParenthesis === 0){
					if(childs.length === 0)
						operationbetweengrp = grpFilter[grpFilter.length-1]['operation']
					return {"group":{'new_columns_for_filter':grpFilter,"i_value":i,'childs':childs,'childfilteroperation':childfilteroperation,'grp1filteroperation':operationbetweengrp}}
				}
			}
		},
		getChildFilter(existingfilter,startindex){
			var filters = []
			var startParenthesis = 1
			for(let i=startindex;i<existingfilter.length;i++){
				let current_filter = existingfilter[i]
				if(current_filter['s_parentheses']==='(')
					startParenthesis++
				if(current_filter['e_parentheses'] === ')')
					startParenthesis--
				if(current_filter['e_parentheses'] === '))')
					startParenthesis = startParenthesis -2
				filters.push(current_filter)
				if(startParenthesis!=2){
					return {
						"filters":filters,'startParenthesis':startParenthesis,'i_value':i
					}
				}
			}
		}
 	}
}
</script>