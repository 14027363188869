import {separate_operator, stripDateFormat} from '@/methods/commonFunc.js'

var moment = require("moment-timezone")
export default function(self, date){
    // let timeZone = self.$session.get('time_zone_preference');
    // let format = new Date(date).toLocaleString("en-US", {timeZone: timeZone});
    return date;
}

export function getJDEDate(text,format='DD-MM-YYYY'){
	// first check valid date
	text = separate_operator(text)
	let date = moment(text,format,true)
	let striped_date_format = stripDateFormat(format)
	let format_list = []
	if(striped_date_format === "DDMMYYYY")
		format_list = ['DD-MM-YYYY','DD/MM/YYYY','DDMMYYYY','MMMM D,Y','MMM D,Y']	
	else if(striped_date_format === "MMDDYYYY")
		format_list = ['MM/DD/YYYY','MM-DD-YYYY','MMDDYYYY','MMMM D,Y','MMM D,Y']
	else if(striped_date_format === "YYYYMMDD")
		format_list = ['YYYY/MM/DD','YYYY-MM-DD','YYYYMMDD','MMMM D,Y','MMM D,Y']
	else if(striped_date_format === "YYYYDDMM")
		format_list = ['YYYY/DD/MM','YYYY-DD-MM','YYYYDDMM','MMMM D,Y','MMM D,Y']
	else
		format_list = ['MMMM D,Y','MMM D,Y']
	// format_list = ['DD-MM-YYYY','YYYY/MM/DD','YYYY/DD/MM','DD/MM/YYYY','MM/DD/YYYY','MM-DD-YYYY','YYYY-MM-DD','YYYY-DD-MM','YYYYMMDD','YYYYDDMM','DDMMYYYY','MMDDYYYY','MMMM D,Y']
	for(let i=0; i <format_list.length; i++){
		date = moment(text,format_list[i], true)
		if(date.isValid()){
			break
		}
		if(i === format_list.length-1){
			if(!date.isValid()){
				alert('input date is not valid')
				return false
			}
		}
	}
	let year = date.year()
	let century = Math.floor(year/100)
	let jdeC = century-19
	let onlyYY = year%100
	let dayOfYear = date.dayOfYear()
	let jdedate = ""+jdeC+_.padStart(onlyYY,2,'0')+_.padStart(dayOfYear,3,'0')
	let numericDate = parseInt(jdedate);
	return numericDate

}

export function convertToUserNativeDate(text,user_format='DD-MM-YYYY'){
	let format='DD-MM-YYYY'
	let operator = separate_operator(text,true)
	text = separate_operator(text)
	let date = moment(text,format,true)
	let format_list = ['DD-MM-YYYY','YYYY/MM/DD','YYYY/DD/MM','DD/MM/YYYY','MM/DD/YYYY','MM-DD-YYYY','YYYY-MM-DD','YYYY-DD-MM','YYYYMMDD','YYYYDDMM','DDMMYYYY','MMDDYYYY','MMMM D,Y','MMM D,Y']
	for(let i=0; i <format_list.length; i++){
		date = moment(text,format_list[i], true)
		if(date.isValid()){
			format = format_list[i]
			break
		}
		
	}
	// moment(text).format(format)
	// moment(text, format).format(user_format)
	if (operator)
		return ""+ operator + moment(text, format).format(user_format)
	else
	return moment(text, format).format(user_format)
}