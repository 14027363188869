<template>
	<div id="edcWorkflowDesigner">
		<v-container class="EDC-Row breadcrumbBackground">
			<v-row class="EDC-Row">
				<v-col class="EDC-Col text-truncate" align="left" cols="11">
					<ul class="breadcrumb" style="display:inline-flex;padding-left:0px !important;">
						<li>{{workspace_name}}</li>
						<li><input type="text" v-model='workflowname' :title="workflowname" :readonly="isReadOnly" :size="nameTextboxSize" @blur="OnDefinationNameChange"/></li>
					</ul>
					<!-- <span style="color:rgba(0,0,0,0.90);font-weight:400;font-size:13px;margin-left:5px;" v-if="process_def_id">
						<span style="cursor: pointer;" @click="ShowObjDetails">
							Ver. {{object_revision}}
						</span>
					</span> -->

					<span v-if="!isReadOnly" style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>

					<span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
					(Readonly Mode)</span>

					<span v-if="!isReadOnly" style="padding-left:4px !important;">
						<toggle-button class="saveTypeToggle" :labels="{checked: 'Auto Save', unchecked: 'Auto Save'}" v-model="isAutoSave" style="margin-top:0px !important;margin-bottom:0px !important;border-radius:20px !important;padding-right:2px !important;" :sync="true" :width="96" :color="{checked: '#75C791', unchecked: '#859AA4', disabled: '#CCCCCC'}" @change="setUserPreferences"/>
						<!-- <vc-button itemText="Save" :xSmall='true' :disabled="!saveType"></vc-button> -->
					</span>
					<span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{dataSourceError||redoundoMsg}}</span>

				</v-col>
				
				<v-col class="EDC-Col">
					<v-row class="EDC-Row">
						<v-col class="EDC-Col" cols="4">
							<!-- <v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
							<svgicon v-if="!validateProgress" class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;"  title="Validate" @click="Validateflowchart"></svgicon> -->
						</v-col>
						<v-col class="EDC-Col" cols="4">
							<!-- <v-chip small :color="chipcolor" style="margin-top:2px !important;height:20px !important;">{{errorListCount}}</v-chip> -->
						</v-col>
						<v-col class="EDC-Col" cols="4">
							<svgicon class="svg-icon-grid svg-fill-grid" name="cancel_v2"  @click="showClosePopUp()" style="float:right;"  title="Close Tab"></svgicon>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row class="EDC-Row">
				<v-col class="EDC-Col" align="left">
					<edc-graph-toolbar :redoCount="undoStack.length" :undoCount="redoStack.length" @performUndoRedo="keyupHandler" @showDescription="showDescription()" key="workflow-designer" @createNewVersion="createNewVersion" @onSave="toolbarSaveClicked"></edc-graph-toolbar>
				</v-col>
			</v-row>
		</v-container>
		<v-layout raw wrap>
			<div role="tablist" style="width:25%;" id="tablist" ref="tablistref">
				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-btn block href="#" v-b-toggle.accordion1><font color='white'>Basic Shapes </font> </b-btn>
					</b-card-header>
					<b-collapse id="accordion1" visible accordion="my-accordion" role="tabpanel" 
						style="overflow-y: auto;height:300px !important;">
						<b-card-body>
							<div id="stencil" style="width:100%"></div>   
						</b-card-body>
					</b-collapse>
				</b-card>
				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab" >
						<b-btn block href="#" v-b-toggle.accordion2 > <font color='white'>Form Shapes</font></b-btn>
					</b-card-header>
					<b-collapse id="accordion2" visible accordion="my-accordion" role="tabpanel"
					style="overflow-y: auto;height:300px !important;">
						<b-card-body class="cardbody">
							<div id="formStencil" style="width:100%"></div>
						</b-card-body>
					</b-collapse>
				</b-card>
				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab" >
						<b-btn block href="#" v-b-toggle.accordion3 > <font color='white'>JDE Shapes</font></b-btn>
					</b-card-header>
					<b-collapse id="accordion3" visible accordion="my-accordion" role="tabpanel"
					style="overflow-y: auto;height:300px !important;">
						<b-card-body class="cardbody">
							<div id="jdeStencil" style="width:100%"></div>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>
			<div id="collasible" style="width:1%;"> 
				<v-icon id="close_tablelist_icon" @click.stop="perform_collapse('close')" title="Close Control Panel" class="glyphicon" ref="closetablelisticon">chevron_left</v-icon> 

				<v-icon id="open_tablelist_icon" @click.stop="perform_collapse('open')" title="Open Control Panel" style="display:none;position: relative;font-size:25px;" ref="opentablelisticon">chevron_right</v-icon>
			</div>
			<div style="width:74%;" id="main_container" ref="main_container">
				<edc-graph key="edc_workflow" :graphFor="graphFor" :mainPaperDimentions="paperDimentions" :newObject="newCell" @sendElementEvent="receiveElementEvent" @sendLinkEvent="receiveLinkEvent" :dataForGraphRedesign="dataForGraphRedesign" :isReadOnly="isReadOnly" @sendNonGraphEvent="receiveNonGraphEvent" :openCell="openCell" :closeCell="closeCell" @sendGraphEvent="receiveGraphEvent" :graphLoader="tableListLoader" :linkToModify="linkDetails"></edc-graph>
			</div>
		</v-layout>
		<v-dialog v-model="email_dialog" width="500" height="500" style="overflow-y:none">
            <edc-sendemail :emailObj='emailObj' @update-object="save_email( ...arguments)" @cancel="email_dialog=false" style="width:100%;background-color:white"></edc-sendemail>
        </v-dialog>
		<v-dialog v-model="condition_dialog" width="500" height="500" style="overflow-y:none">
		    <edc-condition :conditionobj='conditionobj' @update-object="save_data( ...arguments)" @cancle="condition_dialog=false" style="width:100%;background-color:white"
			:onSuccessLabel="onSuccessLabel" :onFailedLabel="onFailedLabel"></edc-condition>
		</v-dialog>
        <v-dialog v-model="review_dialog" persistent width="900" max-width="900" max-height="500" style="overflow-y:auto;">
			<edc-review :reviewobj='reviewObj' @update-object="save_review( ...arguments)" @cancel="review_dialog=false"
			 style="width:100%;background-color:white" @getSelectedValidationDetails="getSelectedValidationDetails"
			 :formValidationJsonByType="formValidationJsonByType">
			</edc-review>
        </v-dialog>

        <v-overlay
          :value="mapping_dialog"
          :dark="false"
        >	
			<edc-form-mapping :mappingobj='mappingObj' @update-object="save_mapping( ...arguments)"  @cancel-mapping="mapping_dialog=false" style="width:100%;background-color:white"></edc-form-mapping>
        </v-overlay>
        <simplert :useRadius="true"
	    :useIcon="true"
	    ref="simplert">
	  </simplert> 
	</div>

</template>
<style scoped>
	
</style>
<script>
	import edctoolbar from './../../views/edctoolbar.vue'
	import {mixinEdcGraph} from '../../mixins/edcGraph.js'
	import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
	import Simplert from 'vue2-simplert'
	import config from '../../config.json'
	import { post as postToServer } from '../../methods/serverCall.js';

	import { ADD_GRAPH_DATA, GET_PROCESS_GRAPH_DATA,GET_ALL_WORKFLOW_OBJECTS, 
		GET_FORM_ACTIONS, GET_FORM_VALIDATIONS, GET_FORM_VALIDATION_DETAILS } from '../../data/url_constants.js'
	import { shapes, dia,layout } from 'jointjs'
	import * as paperObj from './../../methods/edcGraph/papers.js'
	import * as shapesObj from './../../methods/edcGraph/shapes.js'
	import * as edcGraphConstants from './../../methods/edcGraph/constants.js'
	import * as edcCommonObj from './../../methods/edcGraph/commonFunctions.js'
	import sendEmail from './send_email_dialog.vue'
	import condition from './condition_dialog.vue'
	import edcreview from './revisedReviewStep.vue'
	import edcformmapping from './revisedFormMapping.vue'

	export default{
		name:"EdcWorkflow",
		mixins:[mixinEdcGraph],
		components:{
			'edc-sendemail':sendEmail,
			'edc-review': edcreview,
			'edc-condition': condition ,
			'edc-form-mapping':edcformmapping,
			'simplert':Simplert,
		},
		props:{

		},
		data(){
			return{
				workspace_name:'My Workspace',
				workflowname:'Untitled Workflow',
				nameTextboxSize:20,	
				object_revision:"",
				dataSourceError:'',
				redoundoMsg:'',
				errorListCount:0,
				validateProgress:false,
				isReadOnly:false,
				savedstatus:'edited',
				is_error_occured:false,
				undoStack:[],
				redoStack:[],
				isAutoSave:false,
				colorCode:COLOR_CODE,
				paperDimentions:{
					'width':1600,
					'height':1600
				},
				graphFor:"edcWorkflow",
				newCell:{},
				edcGraph:new dia.Graph({},{ cellNamespace: shapes }),
				graphData:{},
				saveTimer:null,
				isObjectFullyLoaded:false,
				workflow_id:'',
				closeCell:{},
				openCell:{},
				dataForGraphRedesign:{},
				tableListLoader:false,
				doubled_clicked_el:{},
				email_dialog:false,
				condition_dialog:false,
				emailObj:{},
				form_list:[],
				currentActiveStep:{},
				review_dialog:false,
				reviewObj:{},
				user_group_list:[],
				mappingObj:{},
				mapping_dialog:false,
				conditionobj:{},
				formActionList:[],
				formValidationList:[],
				formValidationJsonByType:{},
				linkDetails:{},
				onSuccessLabel:"On Send",
				onFailedLabel:'On Cancel'
			}
		},
		mounted(){
			var _this = this
			this.getAllUserGroups()
			var stencilGraph = new dia.Graph({},{ cellNamespace: shapes })
			var stencilPaper = paperObj.getStencilPaper('#stencil',stencilGraph,200)
			stencilGraph.addCells(shapesObj.getEdcWorkflowSteps())
			stencilPaper.on('cell:pointerdown', function(cellView, e, x, y) {
				if(!_this.isReadOnly){
					console.log('test')
					_this.newCell = {
						'element':cellView.model,
						'edc_type':_this.graphFor,
						'e':e,
						'x':x,
						'y':y,
						'is_element':true,
						'is_dragged':true
					}
				}
			});

			var formStencilGraph = new dia.Graph({},{ cellNamespace: shapes })
			var formStencilPaper = paperObj.getStencilPaper('#formStencil',formStencilGraph,200)
			formStencilGraph.addCells(shapesObj.formBasedShapes())

			formStencilPaper.on('cell:pointerdown', function(cellView, e, x, y) {
				if(!_this.isReadOnly){
					console.log('test')
					_this.newCell = {
						'element':cellView.model,
						'edc_type':_this.graphFor,
						'e':e,
						'x':x,
						'y':y,
						'is_element':true,
						'is_dragged':true
					}
				}
			});

			var jdeStencilGraph = new dia.Graph({},{ cellNamespace: shapes })
			var jdeStencilPaper = paperObj.getStencilPaper('#jdeStencil',jdeStencilGraph,200)
			jdeStencilGraph.addCells(shapesObj.jdeBasedShapes())

			jdeStencilPaper.on('cell:pointerdown', function(cellView, e, x, y) {
				if(!_this.isReadOnly){
					console.log('test')
					_this.newCell = {
						'element':cellView.model,
						'edc_type':_this.graphFor,
						'e':e,
						'x':x,
						'y':y,
						'is_element':true,
						'is_dragged':true
					}
				}
			});

			if(!_this.$route.params.workflow_id){
				_this.startInitialSaveTimer(5000)
			}
			else{
				if(this.$route.params.copy){
					_this.isReadOnly = false
					_this.CopyGraph()
				}
				else{
					_this.redesignWorkflow()
					if(this.$route.params.is_read_only){
						this.isReadOnly = true
					}
					else{
						var data = {
			     			"object_id": this.$route.params.workflow_id,
			     			"object_revision": this.$route.params.object_revision,
			     			"object_type_code": this.$route.params.object_type_code,
			     			"env_id": this.$route.params.env_id,
			     			"env_name": this.$route.params.env_name
			     		}
						_this.workflow_id = _this.$route.params.workflow_id
						_this.object_revision =_this.$route.params.object_revision
					}
				}
			}
			_this.getFormList()
			_this.getFormActions()
			_this.getFormValidationList()
		},
		methods:{
			OnDefinationNameChange(){
				this.changeSaveStatus()
			},
			showClosePopUp(){

			},
			setUserPreferences(){

			},
			createNewVersion(){

			},
			toolbarSaveClicked(){
				let confirmFn = function () {
		        	this.$parent.autoSaveWorkflow(true)
		        }
		        let obj = {
		          title: 'Save',
		          message: 'All changes will get saved, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          customConfirmBtnText:'Save',
		          customCloseBtnText:'Cancel',
		          onConfirm: confirmFn,
		          showXclose:true
		        }
		        this.$refs.simplert.openSimplert(obj)
			},
			showDescription(){

			},
			keyupHandler(){

			},
			receiveElementEvent(eventName,data){
				var _this = this
				let element = data.element
				_this.currentActiveStep = element
				if(eventName === 'doubleclick'){
					_this.cellDoubleClicked(element,data.is_redo_undo)
				}
				_this.changeSaveStatus()
			},
			cellDoubleClicked(cell,is_redo_undo,calledTime = 0){
				var _this = this
				_this.doubled_clicked_el = cell
				var input_data = {}
				if(!_.isEmpty(this.doubled_clicked_el.prop('input_data')))
					input_data = this.doubled_clicked_el.prop('input_data')
				// some time graph not get immidiately updated after connecting link.for that purpose we have recall this function after one 1.We will do this only once thats why used calledTime variable (Avoid infine loop in case of unhandled scenario).
				if(calledTime===0){
					calledTime = calledTime+1
					setTimeout(function(){
						_this.cellDoubleClicked(cell,is_redo_undo,calledTime)
					},800)
					return
				}

				let revisedFormList = edcCommonObj.getRevisedFormListForWorkFlow(this.edcGraph,this.doubled_clicked_el,_.cloneDeep(this.form_list))
				let actualFormMapping = edcCommonObj.getActualFormMapping(this.edcGraph)

				if(this.doubled_clicked_el.attributes.stepname == 'Condition'){
					this.accept_list = []
					this.reject_list = []
					
					var links = this.edcGraph.getConnectedLinks(this.doubled_clicked_el, { inbound: true })
					if(links[0] && links[0].get('source')){
						var source_id = links[0].get('source').id
					}else{
						var source_id = null
					}
					if(!source_id)
						return false

					var sourceObj = this.edcGraph.getCell(source_id)
					let isForApproval = false
					if(sourceObj.prop('steptype') === 'approval')
						isForApproval = true
					if(sourceObj.prop('steptype') === 'approval'){
						this.onSuccessLabel = "On Approve"
						this.onFailedLabel = 'On Reject'
					} else if (sourceObj.prop('steptype') === 'form_review'){
						this.onSuccessLabel = "On Success"
						this.onFailedLabel = 'On Failure'
					} else{
						this.onSuccessLabel = "On Send"
						this.onFailedLabel = 'On Cancel'
					}
					var out_links = this.edcGraph.getConnectedLinks(this.doubled_clicked_el, { outbound: true })

					for (var i = 0; i <= out_links.length - 1; i++) {
						var link_id = out_links[i].id
						var target_id = out_links[i].get('target').id
						var target = this.edcGraph.getCell(target_id)
						let isKeytarget_id = this.accept_list.some((elem) => target_id === elem.cell_id);
						this.reject_list.push({"cell_name":target.attributes.stepname,"cell_id":target_id, "link_id": link_id})
						this.accept_list.push({"cell_name":target.attributes.stepname,"cell_id":target_id, "link_id": link_id})
					}
					var data_to_pass = {
						"cell_id": this.doubled_clicked_el.id,
						"cell_obj": this.doubled_clicked_el,
						"is_condition": true,
						"accept_list": this.accept_list,
						"reject_list": this.reject_list,
						"on_accept": this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').on_accept: '',
						"accept_link": this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').accept_link: '',
						"on_reject":this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').on_reject: '',
						"reject_link": this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').reject_link: '','isForApproval':isForApproval
					}
					this.conditionobj = data_to_pass
					this.condition_dialog = true;

				}
				if(this.doubled_clicked_el.prop('steptype') === 'send_email'||this.doubled_clicked_el.prop('steptype') === 'send_form_link'){
					
					let availableEmailOptions = edcCommonObj.getAvailableEmailOptions(this.edcGraph,this.doubled_clicked_el)
					this.email_dialog= true

					var data_to_pass = {
						"form_list":revisedFormList,
						"selected_form":this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').selected_form:'',
						"stepType":this.doubled_clicked_el.attributes.steptype,
						"availableEmailOptions":availableEmailOptions,
						"sendEmailDetails":this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').sendEmailDetails:''
						// "action":this.doubled_clicked_el.prop('input_data')? this.doubled_clicked_el.prop('input_data').action:'',
					}
					this.emailObj = data_to_pass
				}
				else if(this.doubled_clicked_el.prop('steptype') === 'form_review'||this.doubled_clicked_el.prop('steptype') === 'approval'){
					var data_to_pass = {
						"stepname":this.doubled_clicked_el.prop('stepname'),
						"step_type":this.doubled_clicked_el.prop('steptype'),
						"user_group_list": this.user_group_list,
						"selected_user_group_list": input_data.selected_user_group_list,
						"minimum_group_approver_count": input_data.minimum_group_approver_count,
						"form_list":revisedFormList,
						"isReview":this.doubled_clicked_el.prop('steptype') === 'form_review'?true:false,
						"selected_form":input_data.selected_form,
						"formActionList":this.formActionList,
						"formValidationList":this.formValidationList,
						"action":input_data.action,
						"validation_details":input_data.validation_details
					}
					this.reviewObj = data_to_pass
					this.review_dialog= true
				}
				else if(this.doubled_clicked_el.prop('steptype') === 'form_data_transfer'){
					var data_to_pass = {
						"input_form_id": this.doubled_clicked_el.prop('input_data') && !_.isEmpty(this.doubled_clicked_el.prop('input_data'))? this.doubled_clicked_el.prop('input_data').input_form_id: '',
						"output_form_id": this.doubled_clicked_el.prop('input_data') && !_.isEmpty(this.doubled_clicked_el.prop('input_data'))? this.doubled_clicked_el.prop('input_data').output_form_id:'',
						"mapping_details": this.doubled_clicked_el.prop('input_data')&& !_.isEmpty(this.doubled_clicked_el.prop('input_data'))?this.doubled_clicked_el.prop('input_data').mapping_details:'',
						"form_list":revisedFormList,
						"workflowname":this.workflowname,
						"workspacename":this.workspace_name,
						"stepname":this.doubled_clicked_el.prop('stepname')
					}
					this.mappingObj = data_to_pass
					this.mapping_dialog= true
				}
			},
			save_data(data){
				var _this = this
				this.workflowdialog = false;
				var accept_link_id = data.accept_link
				var reject_link_id = data.reject_link
				if(data.is_condition){
					this.doubled_clicked_el.prop('on_accept',data.on_accept)
					this.doubled_clicked_el.prop('on_reject',data.on_reject)
					this.doubled_clicked_el.prop('reject_link',data.reject_link)
					this.doubled_clicked_el.prop('accept_link',data.accept_link)
					this.condition_dialog = false
					let onSend = _.find(this.graphData.cells,["id",accept_link_id])
					// onSend.label1 = "On Send"
					this.linkDetails = {"linkObj":onSend,"type":'editLabel', 'labelText':this.onSuccessLabel}
					var onReject = _.find(this.graphData.cells,["id",reject_link_id])
					// onReject.label1 = "On Cancel"
					setTimeout(()=>{
						_this.linkDetails = {"linkObj":onReject,"type":'editLabel', 'labelText':this.onFailedLabel}
					},100)
					this.doubled_clicked_el.prop('on_accept',{"link_id":data.on_accept.link_id,"cell_id":data.on_accept.cell_id,"cell_name":data.on_accept.cell_name})
					this.doubled_clicked_el.prop('on_reject',{"link_id":reject_link.link_id,"cell_id":data.on_reject.cell_id,"cell_name":data.on_reject.cell_name})
					this.currentActiveStep.prop('input_data',data)
				}
				
				this.savedstatus = 'edited'
			
		},
			save_email(data){
				this.email_dialog = false;
				this.currentActiveStep.prop('input_data',data)
				this.savedstatus = 'edited'
			},
			save_mapping(data){
				this.mapping_dialog = false;
				this.currentActiveStep.prop('input_data',data)
				this.savedstatus = 'edited'
			},
			save_review(data,is_close=false){
				this.currentActiveStep.prop('input_data',data)
				this.savedstatus = 'edited'
				if(is_close)
					this.review_dialog = false;
			},
			cancle_review(){
				this.review_dialog = false
			},
			receiveLinkEvent(){

			},
			receiveNonGraphEvent(){

			},
			getAllUserGroups() {
	            let _this = this;
	            var request_data = {
	                'client_id': this.$session.get('client_id'),
	                'product_name': window.sessionStorage.getItem('product')}
	            postToServer(_this, config.USER_PROVISION_URL + '/get_all_group_details', request_data).then(response => {
					response.forEach(function(obj){
						obj.display_name = obj.group_name
					})
					_this.user_group_list = response;
	               
	            }).catch(error_response => {
	            });
	        },
			receiveGraphEvent(eventName,data){
				let _this = this
				_this.graphData = data.graphData
				_this.edcGraph = data.edcGraph
				if(edcGraphConstants.GRAPH_EVENTS_FOR_REDESIGN.indexOf(eventName)>-1){
					_this.changeSaveStatus()
				}
			},
			startInitialSaveTimer(timing){
		      	let _this = this
		      	// over come issue with load object before loadding anything, I am writing set timeout for 8 seconds
				setTimeout(()=>{
					_this.isObjectFullyLoaded = true
				},timing) 
		    },
			changeSaveStatus(){
				this.is_error_occured = false
				this.savedstatus = 'edited'
				// logic to save document only if user stop editing
				clearInterval(this.timer)
				this.timer = setInterval(this.autoSaveWorkflow,3000)
			},
			autoSaveWorkflow(isSaveClicked,closeTabAfterSave){
				// If autoSave is off, then save only when user click on save button
				if(!isSaveClicked && !this.isAutoSave)
					return
				if(!this.isObjectFullyLoaded)
					return

				if(this.isReadOnly)
					return false
				var _this = this
				if(this.savedstatus !== 'saved'){
					let data_send_backend = []
					var data = this.graphData;
					var client_id = this.$session.get('client_id')
					var user = this.$session.get('email')
					data['isAug2020ReleasedUI'] = true
					var saved_data = {"object_json": data, 
						"erp_code": "N/A",
						"erp_version": "N/A",
						"object_type_code": "WF",
						"object_desc": "",
						"object_name": this.workflowname, 
						"object_revision": this.object_revision,
						"client_id": client_id, 
						"added_by": user, 
						"env_id": this.env_id, 
						"object_id": this.workflow_id, 
					}
					var url = config.REPOSITORY_API_URL+ADD_GRAPH_DATA
					postToServer(this, url, saved_data).then(response => {
						if(response){
							this.workflow_id = response.object_id
							this.workflowname = response.object_name
							this.object_revision = response.object_revision
							this.savedstatus = 'saved'
						}
					}).catch(error_response => {
						if(error_response){
							this.snackbar = true
							this.colorValue = 'error'
							this.snackbartext = error_response;
						}
						else {
							this.snackbar = true
							this.snackbartext = 'Something went wrong.Try Again';
							this.colorValue = 'error'
							
						}
					})
				}
			},
			redesignWorkflow(env_has_allow_create,create_new_version){
		     	if (this.$route.params.create_new_version || create_new_version) {
					var create_new_version = true;
				} else {
					var create_new_version = false;
				}

		     	// from object management page user click on create new version but env might not have create new version rights. So for that purpose added below check.

		     	if(env_has_allow_create === 'No')
					create_new_version = false

		     	var data = {
		     		"object_id": this.$route.params.workflow_id,
		     		"object_revision": this.$route.params.object_revision,
		     		"object_type_code": this.$route.params.object_type_code,
		     		"create_new_version": create_new_version,
		     		"env_id": this.$route.params.env_id,
		     		"env_name": this.$route.params.env_name
		     	}

		     	
		     	var _this = this;
		     	_this.tableListLoader = true;
		     	postToServer(this, config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA, data).then(response => {
	     			var res = response
	     			_this.manageGraphResponse(res)
	     			if(create_new_version)
						this.isReadOnly = false
					_this.tableListLoader = false
					// if(!this.timer)
					// 	this.timer = setInterval(this.AutoSaveProcessDesignerGraph,3000)
				}).catch(error_response => {
					_this.is_request_in_process = true
					_this.tableListLoader = false
					if(error_response){
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = error_response;
						this.loader = null 
					}
					else {
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = SERVER_ERROR;
					}
				})
			},
			CopyGraph(){
				var data = {
					"object_id": this.$route.params.workflow_id,
					"object_revision": this.$route.params.object_revision,
					"object_type_code": this.$route.params.object_type_code
				}
				this.workflow_id = ''
				var _this = this;
				_this.tableListLoader = true;
				postToServer(this, config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA, data).then(response => {
					_this.tableListLoader = false
					var res = response
					_this.manageGraphResponse(res,true)
				}).catch(error_response => {
					_this.tableListLoader = false
					if(error_response){
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = error_response;
						this.loader = null 
					}
					else {
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = SERVER_ERROR;
					}
				})

			},
			manageGraphResponse(res,is_copy=false){
				if(is_copy){
					this.workflowname = 'Copy of ' +res.object_name
				}
				else{
					this.workflow_id = res.object_id
					this.workflowname = res.object_name
					// this.oldWFName = res.object_name
				}
				this.object_revision = res.object_revision
				var graphdata = {"cells": res.object_json.cells}
				this.dataForGraphRedesign = {
					"cells":res.object_json.cells,
					"isAug2020ReleasedUI":res.object_json.isAug2020ReleasedUI
				}
				if(this.$route.params.is_read_only){
		          this.isReadOnly = true
		        }
		        else{
					this.startInitialSaveTimer(4000)
		        }
			},
			getFormList() {
	          	var user_id = this.$session.get('user_id')
	            var client_id = this.$session.get('client_id')
	          	var get_workflow_list_url = config.REPOSITORY_API_URL + GET_ALL_WORKFLOW_OBJECTS
	            var data = {"client_id": client_id, "object_type_code": "FRM"}
	          	postToServer(this, get_workflow_list_url, data).then(response => {
	                        this.form_list = response;
	                        console.log('this.form_list',this.form_list)
	            }).catch(error_response => {
	            
	            });
			},
			getFormActions(){
				var client_id = this.$session.get('client_id')
	          	var get_form_validation_list = config.WORKFLOW_URL + GET_FORM_ACTIONS
	            var data = {"client_id": client_id}
				postToServer(this, get_form_validation_list, data).then(response => {
					this.formActionList = response;
	            }).catch(error_response => {
	            
	            });
			},
			getFormValidationList(){
				var client_id = this.$session.get('client_id')
	          	var get_form_validation_list = config.WORKFLOW_URL + GET_FORM_VALIDATIONS
	            var data = {"client_id": client_id}
				postToServer(this, get_form_validation_list, data).then(response => {
					this.formValidationList = response;
	            }).catch(error_response => {
	            
	            });
			},
			getSelectedValidationDetails(validationFor){
				var copyOfvalidationJsonByType = _.cloneDeep(this.formValidationJsonByType)
				if(validationFor && this.formValidationJsonByType[validationFor]){
					this.formValidationJsonByType = copyOfvalidationJsonByType
					return
				}
				var client_id = this.$session.get('client_id')
	          	var get_form_validation_list = config.WORKFLOW_URL + GET_FORM_VALIDATION_DETAILS
	            var data = {"client_id": client_id,"validation_for":validationFor}
				postToServer(this, get_form_validation_list, data).then(response => {
					copyOfvalidationJsonByType[validationFor] = response;
					this.formValidationJsonByType = copyOfvalidationJsonByType
	            }).catch(error_response => {
					alert(error_response)
	            
	            });
			}
		}
	}
</script>