<template>
	<v-container>
		<v-row class="EDC-Row">
	      <v-col cols="8" class="EDC-Col">
	        <v-row class="EDC-Row breadcrumbRow">
	          <v-col cols="12" class="EDC-Col">
	            <ul class="breadcrumb breadcrumbUL">
	            	<li @click="cancel">
	                	<b>{{workspace_name}}</b>
	              	</li>
	              	<li @click="cancel">
	                	<b>{{workflow_name}}</b>
	              	</li>
	                <li><b>{{step_name}}</b></li>
	            </ul>
	          </v-col>
	        </v-row>
	      </v-col>
	      <v-col cols="4"></v-col>
	    </v-row>
		<v-row class="EDC-Row" style="padding-top:8px">
			<v-col class="EDC-Col">
				<v-card height="98vh" width="100vw">
              		<v-container class="EDC-Container">
              			<v-row class="EDC-Row">
              				<v-col cols="4" class="EDC-Col EDC-ColsSpacing">
			                  <v-autocomplete label="Select From Form" :items="form_list" item-text="object_name" return-object v-model="input_form" dense hide-details></v-autocomplete>
			                </v-col>
			                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
			                  <v-autocomplete label="Select To Form" :items="form_list" item-text="object_name" return-object v-model="output_form" dense hide-details></v-autocomplete>
			                </v-col>
              			</v-row>
              			<v-row class="EDC-Row" v-for="(n, index) in mapping_list" :key="n.id" style="padding-top:20px !important">
			                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
			                  <v-autocomplete label="From Field" :items="input_form_json.fieldList" item-text="label" item-value="fieldId" v-model="n.input_field_id" dense hide-details></v-autocomplete>
			                </v-col>
			                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
			                  <v-autocomplete label="To Field" :items="output_form_json.fieldList" item-text="label" item-value="fieldId" v-model="n.output_field_id" dense hide-details></v-autocomplete>
			                </v-col>
			                <v-col cols="1" class="EDC-Col EDC-ColsSpacing" align="left">
			                  <v-icon title="Add New" @click="add()" v-if="index == (mapping_list.length - 1)" :original="true" style="margin-bottom:1%"> add</v-icon> 
			                  <v-icon title="remove" @click="remove(index)" v-else :original="true" style="margin-bottom:1%">remove</v-icon>
			                </v-col>
		              	</v-row>
		              	<v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="start">
		                	<vc-button itemText="Save" @click.native="submit"></vc-button>
		                	<vc-button itemText="Cancel" @click.native="cancel"></vc-button>
		              	</v-row>
              		</v-container>
            	</v-card>
            </v-col>
		</v-row>
	</v-container>
</template>
<style scoped>
	
</style>
<script>
	export default{
		name:"EdcFormMapping",
		props:{
			mappingobj:{
				type:Object
			}
		},
		data(){
			return{
				form_list:[],
				input_form_json:{},
				output_form_json:{},
				input_form:{},
        		output_form:{},
        		mapping_list:[{"input_field_id":'', "output_field_id":''}],
        		workflow_name:'test',
        		step_name:'Form Data Transfer',
        		workspace_name:''
			}
		},
		watch:{
			mappingobj:{
				handler(newvalue){
					if(newvalue.mapping_details){
						this.mapping_list = newvalue.mapping_details
					}else{
						this.mapping_list = [{"input_field_id":'', "output_field_id":''}]
					}
					this.initialize(newvalue)
				}
			},
    		input_form (newvalue,o){
				if(newvalue.object_json){
					this.input_form_json = newvalue.object_json
				}
    		},
    		output_form (newvalue,o){
				if(newvalue.object_json){
					this.output_form_json = newvalue.object_json
				}
    		},
		},
		mounted(){
			this.initialize(this.mappingobj)
		},
		methods:{
			add(){
				if(!this.validData)
					return
	            this.mapping_list.push({"input_field_id":'', "output_field_id":''})
	        },
	        validData(){
	        	let lengthOfMappedVal = this.mapping_list.length
				if(!this.mapping_list[lengthOfMappedVal-1].input_field_id || !this.mapping_list[lengthOfMappedVal-1].output_field_id){
					alert('Please fillup all values')
					return false
				}
				return true
	        },
	        remove(index){
	            if(this.mapping_list.length > 1)
	                this.mapping_list.splice(index,1)
	        },
	        submit(){
	        	if(!this.validData)
					return
                let _this = this
                var data = { 'input_form_id': this.input_form.object_id, 'output_form_id': this.output_form.object_id, 'mapping_details': this.mapping_list,'output_form':this.output_form}
                _this.$emit('update-object', data)
	        },
			 cancel(){
          	 	this.$emit('cancel-mapping')
        	},
		    initialize (newvalue){
		    	this.workspace_name = newvalue.workspacename
		    	this.workflow_name = newvalue.workflowname
		    	this.step_name = newvalue.stepname
				if(newvalue.mapping_details){
					this.mapping_list = newvalue.mapping_details
				}else{
					this.mapping_list = [{"input_field_id":'', "output_field_id":''}]
				}
		      	if(newvalue.form_list){
		        	this.form_list =  newvalue.form_list
		      	}
		      	if(newvalue.input_form_id){
		        	this.input_form =  _.find(this.form_list,["object_id",newvalue.input_form_id])
		      	}
		      	if(newvalue.output_form_id){
		        	this.output_form =  _.find(this.form_list,["object_id",newvalue.output_form_id])
		     	}
			},
		}
	}
</script>