import { shapes, dia } from 'jointjs'
import * as edcConstantsObj from './constants.js'

export function createBusinessObjHierarchy(cells){
	// console.log('cells',cells)

	let graph = new dia.Graph({},{ cellNamespace: shapes })
	graph.fromJSON(_.cloneDeep({"cells":cells}));

	let rootEle =graph.getSources()[0]	
	rootEle.prop('layer',1)
	let element_list =generateGraphObj(graph,rootEle,[rootEle.attributes])
	// element_list.forEach(element=>{
	// 	console.log(element.prop('table_name')+" - id "+element.id +" , layer "+element.prop('layer')+
	// 		" weight "+element.prop('weight'),", parent_id "+element.prop('parent_id'))
	// })
	// console.log("cells",element_list)
	let revisedCellList = createHierarchyForBO(element_list,rootEle.attributes)
	console.log('revisedCellList',revisedCellList)
	return revisedCellList
}


export function createHierarchyForBO(cells,rootEle,parent_id,currentRow,cellPerRow,parentLayer={},revisedCellList=[],elementMatrix=[]){
	if(!currentRow)
		currentRow = 0
	if(!cellPerRow)
	  	cellPerRow = 5
	elementMatrix = createElementMatrix(currentRow,cellPerRow,elementMatrix)
	let positionX = edcConstantsObj.CENTER_OF_BO_GRAPH_PAPER
	let positionY = 10
	if(parent_id){
		let positionXY = getPositionXY(currentRow,cellPerRow,elementMatrix)
		positionX = positionXY.positionX
		positionY = positionXY.positionY	
		elementMatrix = positionXY.elementMatrix
	}
	
	rootEle.position.x = positionX
	rootEle.position.y = positionY

	revisedCellList.push(rootEle)
	let childs = getChildsOrderByWeightDesc(cells,rootEle.id)
	if(childs.length){
		parentLayer[rootEle.id] = currentRow
		currentRow = currentRow+1
	}
	else{
		currentRow = parentLayer[parent_id]+1
	}
	parent_id = rootEle.id
	childs.forEach(child=>{
		createHierarchyForBO(cells,child,parent_id,currentRow,cellPerRow,parentLayer,revisedCellList,elementMatrix)
	})
	return revisedCellList
}

function getChildsOrderByWeightDesc(cells,parent_id){
	let childs = []
	cells.forEach(child=>{
		if(child.parent_id === parent_id)
			childs.push(child)
	})
	let orderedChild = _.reverse(_.orderBy(childs,"weight"))
	return orderedChild
}

function getPositionXY(currentRow,cellPerRow,elementMatrix){
	let positionXY = {}
	let currentRowIncrement=0
	while (true){
		let searchAt = Math.floor(cellPerRow/2)
		if(!elementMatrix[currentRow])
			elementMatrix = createElementMatrix(currentRow,cellPerRow,elementMatrix)
		let nextDirection = "left"
		let directionIndex = 1
		while(searchAt>-1 && searchAt<cellPerRow){
			if(elementMatrix[currentRow][searchAt].isOccupied){
				if(nextDirection==="left"){
					searchAt = Math.ceil(cellPerRow/2) - directionIndex
					nextDirection = "right"
				}
				else{
					searchAt = Math.floor(cellPerRow/2) + directionIndex
					nextDirection = "left"
					directionIndex=directionIndex+1
				}
			}else{
				elementMatrix[currentRow][searchAt].isOccupied = true
				positionXY = elementMatrix[currentRow][searchAt]
				break;
			}
		}
		if(positionXY.positionX)
			break;
		currentRow = currentRow+1
	}
	positionXY["elementMatrix"] = elementMatrix
	return positionXY
}

function createElementMatrix(currentRow,cellPerRow,elementMatrix){
	console.log(currentRow)
	if(elementMatrix[currentRow])
		return elementMatrix
	else{
		elementMatrix.push({})
		for(let i=0;i<cellPerRow;i++){
			elementMatrix[currentRow][i]=setXY(currentRow,cellPerRow,i)
			elementMatrix[currentRow][i].isOccupied = false
		}
	}
	return elementMatrix
}

function setXY(currentRow,cellPerRow,currentColumn){
	let positionX = 25
	let positionY = 10
	let spaceBetweenX = 100
	let spaceBetweenY = 60
	let elementWidth = 120
	let elementHeight = 60
	positionX = positionX + (currentColumn*elementWidth) + (currentColumn*spaceBetweenX)
	
	positionY = positionY + (currentRow*elementHeight) +(currentRow*spaceBetweenY)
	return {"positionX":positionX, "positionY":positionY}
}

export function generateGraphObj(graph,element,elementlist){
	let neighbors = graph.getNeighbors(element,{outbound:true})
	if(!neighbors || !neighbors.length)
		return elementlist
	let layer = element.prop('layer')+1
	for (var i = 0; i < neighbors.length; i++) {
		neighbors[i].prop('layer',layer)
		neighbors[i].prop('weight',0)
		neighbors[i].prop('parent_id',element.id)
		elementlist.push(neighbors[i].attributes)
		let weight=1
		if(element.prop('weight'))
			weight = element.prop('weight')+1
		element.prop('weight',weight)
		generateGraphObj(graph,neighbors[i],elementlist)
	}
	return elementlist
}