<template>
  <v-container>
    <v-row class="EDC-Row">
      <v-col class="EDC-Col">
        <v-toolbar dark dense>
          <v-flex class="text-md-center">Send Email
            <v-icon class="text-lg-left" @click="cancel" style="color:#dedede;height:18px;float:right">fa-times-circle</v-icon>
          </v-flex>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row class="EDC-Row" style="padding-top:8px !important;">
      <v-col class="EDC-Col colPadding" cols="6">
        <v-autocomplete label="Select Form" :items="form_list" item-text="object_name" return-object v-model="selected_form" :rules="formRules" dense hide-details></v-autocomplete>
      </v-col>
    </v-row>
    <!-- <v-row class="EDC-Row" style="padding-top:8px !important">
      <v-col class="EDC-Col" cols="6">
        <v-autocomplete label="Select Email Details" :items="emailOptions" item-text="selectedOption" return-object v-model="selectedEmailOption" :rules="emailInputRule" dense hide-details></v-autocomplete>
      </v-col>
    </v-row> -->
    <v-row class="EDC-Row" style="padding-top:8px !important" v-if="selectedEmailOption && selectedEmailOption.isManual">
      <v-col class="EDC-Col" cols="12">
        <v-form v-model="form" ref="form">
          <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="6">
              <v-text-field dense label="Recipient Company" :rules="recipientCompanyReq" v-model="company_name"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="6">
              <v-text-field dense label="Recipient First Name" :rules="recipientFirstNameReq" v-model="recipient_first_name"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="6">
              <v-text-field dense label="Recipient Last Name" :rules="recipientLastNameReq" v-model="recipient_last_name"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="6">
              <v-text-field dense label="Recipient Email" v-model="recipent_email" :rules="emailInputRule"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="6">
              <v-text-field dense label="Email Subject" v-model="email_subject" :rules="emailSubjectRule"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="6">
              <v-textarea dense label="Email Body" v-model="email_content" :rules="emailBodyRule" :rows="2" :auto-grow="true"></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="start">
      <vc-button itemText="Save" :disabled="fieldsFilled" @click.native="submit"></vc-button>
      <vc-button itemText="Cancel" @click.native="cancel"></vc-button>
    </v-row>
  </v-container>    
</template>

<script>
import _ from 'lodash'
import config from '../../config.json'
import { GET_ALL_WORKFLOW_OBJECTS } from '../../data/url_constants.js'
import { post as postToServer } from './../../methods/serverCall.js';
export default {
  props:['emailObj'],

  data () {
  	return {
      form_list:[],
      action:'',
      selected_form:'',
      subject: '',
      content: '',
      email_data:'',
      valid: '',
      fieldsFilled: false,
      action_list:[{'name':'Add','Value':'Add'},
                  {'name':'Update','Value':'Update'}],
      actionRules: [
        v => !!v || 'Action is required',
      ],
      formRules: [
        v => !!v || 'Form is required',
      ],
      emailInputRule: [
        v => !!v || 'Email source is required',
      ],
      recipientFirstNameReq:[
        v => !!v || 'Recipient First Name is required',
      ],
      recipientLastNameReq:[
        v => !!v || 'Recipient Last Name is required',
      ],
      emailSubjectRule:[
        v => !!v || 'Email Subject is required',
      ],
      emailBodyRule:[
        v => !!v || 'Email Body is required',
      ],
      recipientCompanyReq:[
        v => !!v || 'Recipient Company Name is required',
      ],
      stepType:'',
      selectedEmailOption:{"selectedVal":"0001","selectedOption":"Manual Entry","isManual":true},
      emailOptions:[],
      defaultEmailOption:{"selectedVal":"0001","selectedOption":"Manual Entry","isManual":true},
      recipient_first_name:'',
      recipient_last_name:'',
      recipent_email:'',
      email_subject:'',
      email_content:'',
      company_name:''
  	}
  },
  watch: {
    emailObj(newvalue,o){
		 	this.manageEmailObj(newvalue)
		}
	},
  mounted() {
    this.selectedEmailOption = this.defaultEmailOption
    this.manageEmailObj(this.emailObj)
  },
	methods: {
        submit(){
          this.selectedEmailOption = this.defaultEmailOption
          if(this.selectedEmailOption.isManual && !this.$refs.form.validate())
            return
            if (this.selected_form && this.selected_form.object_id){
              let _this = this
              let filledInfo = {}
              if(this.selectedEmailOption.isManual)
                filledInfo = {"recipient_first_name":this.recipient_first_name, "recipient_last_name": this.recipient_last_name,
                "recipent_email": this.recipent_email, "email_subject": this.email_subject, "email_content": this.email_content,"company_name":this.company_name}
              var data = {'selected_form': this.selected_form.object_id,
                "sendEmailDetails":{"selectedOption":this.selectedEmailOption,'filledInfo':filledInfo},
                'output_form':this.selected_form
              }
              _this.$emit('update-object', data)
            }

        },
        cancel(){
           this.$emit('cancel')
        },
        manageEmailObj(newvalue){
          this.stepType = newvalue.stepType
          if(newvalue.form_list){
            this.form_list = newvalue.form_list
          }else{
            this.form_list = []
          }
          // if(newvalue.action){
          //  this.action = newvalue.action
          // }else{
          //  this.action = []
          // }
          if(newvalue.selected_form){
            this.selected_form =  _.find(this.form_list,["object_id",newvalue.selected_form])
          }else{
            this.selected_form = null
          }
          // if(newvalue.availableEmailOptions){
          //   this.emailOptions = newvalue.availableEmailOptions
          // }
          this.selectedEmailOption = this.defaultEmailOption
          this.recipient_first_name = ''
          this.recipient_last_name = ''
          this.recipent_email = ''
          this.email_subject = ''
          this.company_name = ''
          this.email_content = ''
          if(newvalue.sendEmailDetails){
            this.selectedEmailOption = newvalue.sendEmailDetails.selectedOption
            if(this.selectedEmailOption.isManual && newvalue.sendEmailDetails.filledInfo){
              this.recipient_first_name = newvalue.sendEmailDetails.filledInfo.recipient_first_name
              this.company_name = newvalue.sendEmailDetails.filledInfo.company_name
              this.recipient_last_name = newvalue.sendEmailDetails.filledInfo.recipient_last_name
              this.recipent_email = newvalue.sendEmailDetails.filledInfo.recipent_email
              this.email_subject = newvalue.sendEmailDetails.filledInfo.email_subject
              this.email_content = newvalue.sendEmailDetails.filledInfo.email_content
            }
          }
            this.emailOptions.push(this.defaultEmailOption)
        }
	 }
}
</script>
<style type="text/css">
.v-radio label{
  font-size: 15px;
}
.v-radio i{
  font-size: 20px;
}
</style>