export function performZoomActions(that,action,paper_container,lens){
		var current_scall = that.mainPaper.scale()
		if(!paper_container)
			var paper_container = document.getElementById('paper')
		if(!lens)
			var lens = document.getElementById('lens')
		if(action==='zoom_in')
				zoom_in(that,paper_container,lens)
		else if(action==='zoom_out')
			zoom_out(that,paper_container,lens)

		else
			zoom_reset(that,paper_container,lens)
	}

function zoom_in(that,paper_container,lens){
		that.mainPaperScale +=0.1
		that.lensScale -=0.06
		if(that.mainPaperScale <= 1.8){
			paper_container.style.transform = "scale("+that.mainPaperScale+")"
			lens.style.transform = "scale("+that.lensScale+")"
		}
	}

function zoom_out(that,paper_container,lens){
		that.mainPaperScale -=0.1
		that.lensScale +=0.06
		if(that.mainPaperScale >= 0.2){
			paper_container.style.transform = "scale("+that.mainPaperScale+")"
			lens.style.transform = "scale("+that.lensScale+")"
		}
	}


function zoom_reset(that,paper_container,lens){
		paper_container.style.transform = "scale(1)"
		lens.style.transform = "scale(1)"
		that.mainPaperScale = 1
		that.lensScale =1
	}
