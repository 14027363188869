export const MONTH_CODE_MAPPING={
    'January':'Jan',
    'February':"Feb",
    'March':'Mar',
    'April':'Apr',
    'May':'May',
    'June':'Jun',
    'July':'Jul',
    'August':'Aug',
    'September':'Sept',
    'October':'Oct',
    'November':'Nov',
    'December':'Dec'
}
export const CODE_TO_MONTH_MAPPING={
    'Jan':'January',
    "Feb":'February',
    'Mar':'March',
    'Apr':'April',
    'May':'May',
    'Jun':'June',
    'Jul':'July',
    'Aug':'August',
    'Sept':'September',
    'Oct':'October',
    'Nov':'November',
    'Dec':'December'
}
export const DAY_OF_WEEKS = [{
        'text': 'Sun',
        'fullname':'Sunday',
        value: true
    }, {
        'text': 'Mon',
        'fullname':'Monday',
        value: true
    }, {
        'text': 'Tue',
        'fullname':'Tuesday',
        value: true
    },
    {
        'text': 'Wed',
        'fullname':'Wednesday',
        value: true
    }, {
        'text': 'Thu',
        'fullname':'Thursday',
        value: true
    }, {
        'text': 'Fri',
        'fullname':'Friday',
        value: true
    }, {
        'text': 'Sat',
        'fullname':'Saturday',
        value: true
    }
];
export const DAY_OF_MONTH_ARRAY = [{
        'text': 'Jan',
        'fullname':'January',
        value: true
    }, {
        'text': 'Feb',
        'fullname':'February',
        value: true
    }, {
        'text': 'Mar',
        'fullname':'March',
        value: true
    },
    {
        'text': 'Apr',
        'fullname':'April',
        value: true
    }, {
        'text': 'May',
        'fullname':"May",
        value: true
    }, {
        'text': 'Jun',
        'fullname':'June',
        value: true
    },
    {
        'text': 'Jul',
        'fullname':'July',
        value: true
    }, {
        'text': 'Aug',
        'fullname':'August',
        value: true
    }, {
        'text': 'Sep',
        'fullname':'September',
        value: true
    },
    {
        'text': 'Oct',
        'fullname':'October',
        value: true
    }, {
        'text': 'Nov',
        'fullname':'November',
        value: true
    }, {
        'text': 'Dec',
        'fullname':'December',
        value: true
    }
];
export const WEEK_ARRAY = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
export const DAY_ARRAY = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17',
    '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', 'last'
];
export const DAY_OF_WEEK_ARRAY = [{text:'First',value:"1st"}
                                , {text:'Second',value:"2nd"},
                                 {text:'Third',value:"3rd"},
                                 {text:'Fourth',value:'last'}];

export const JOB_OBJECT = {
    "scheduled_datetime": undefined,
    "schedule_id": "",
    "params": {
        "name": "",
        "start_from": "",
        "start_time": null,
        "end_point": "",
        "service_id": "",
        "client_id": "",
        "created_by": "",
        "is_recurring": false,
        "timezone": "",
        "execution_environment": {},
        "workflow_details": {},
        "category":'Job',
        "recurring": {
            "recurrence_pattern": {
                "recurrence_pattern_type": "weekly",
                "every_xth_days": "",
                "every_xth_weeks": "",
                "week_array": [],
                "is_day_of_month": false,
                "day_of_month": "",
                "which_day_of_week": "",
                "selected_week_day": "",
                "month_array": [],
                "date_of_month":""
            },
            "range_of_recurrence": {
                "end_on": false,
                "end_after_times": "",
                "until_date": null
            }
        }
    }
};

export const DATABASE_NUMERIC_DATATYPE = ["bigint","int","smallint","tinyint","bit","decimal","numeric",
                                        "float", "real", "integer", "shortinteger","longinteger",
                                        "decimal","shortdecimal","number","bigint","double","decfloat"]

export const TWELVE_HOURS_CLOCK = [ "12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM",
                                    "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM",
                                    "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM",
                                    "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
                                    "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM",
                                    "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM",
                                    "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM",
                                    "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"]


export const TWENTY_FOUR_HOURS_CLOCK = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30",
                                        "03:00", "03:30", "04:00", "04:30", "05:00", "05:30",
                                        "06:00", "06:30", "07:00", "07:30", "08:00", "08:30",
                                        "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
                                        "12:00", "12:30", "13:00", "13:30", "14:00", "14:30",
                                        "15:00", "15:30", "16:00", "16:30", "17:00", "17:30",
                                        "18:00", "18:30", "19:00", "19:30", "20:00", "20:30",
                                        "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"]

export const POLICY_VALUE_TYPE = [{'name':"Days",'value':'Days'},
                                  {'name':'Months','value':'Months'},
                                  {'name':'Quarters','value':'Quarters'},
                                  {'name':'Years','value':'Years'},
                                  {'name':'Actual Date','value':'Actual Date'}]

export const POLICY_VALUE_TYPE_WITH_RETENTION = [{'name':"Days",'value':'Days'},
                                  {'name':'Months','value':'Months'},
                                  {'name':'Quarters','value':'Quarters'},
                                  {'name':'Years','value':'Years'},
                                  {'name':'Actual Date','value':'Actual Date'},
                                  {'name':"Permanent",'item_value':"permanent"}]