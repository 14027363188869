<template>
    <v-container class="EDC-Container">
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>
<style scoped>
    /* radio label styling */
    .radioClass >>> label{
        top: 5px !important;
        /* margin: 7px !important; */
    }
    #flatteningdiv .v-label {
    margin-bottom:0.0rem !important;
    }
</style>

<script>
import config from "../../config.json";
import { post as postToServer } from '../../methods/serverCall.js';
import {GET_PROCESS_GRAPH_DATA} from '../../data/url_constants.js';
import {
		CHECKBOX_COLOR,COLOR_CODE
    } from "@/data/macros.js";
import {
		fetch_all_erp_designer,
		fetch_erp_version_for_designer
	} from "../../methods/erp_details.js";

export default {
	data(){
			return {
                business_view_for_list: [{
						id: "BO",
						name: "Business Object"
					},
					{
						id: "DM",
						name: "Datamap"
					},
					{
						id: "T",
						name: "Table"
					}
                ],
                bv_selected_for:"BO",
                isReadOnly:false,
                checkboxColor: CHECKBOX_COLOR,
                erpDetails:true,
			    erp_code:"",
			    erp_version:"",
			    cur_erp_code:"",
                cur_erp_version:"",
                object_revision:"1",
                loader:false,
			    erp_list:[],
			    erp_version_list:[],
			    erp_nameRules: [v => !!v || 'ERP Name is required.'],
			    erp_versionRule: [v => !!v || 'ERP Version is required.']
            }
    },
    mounted(){
        var _this = this
        _this.loader = true
        var client_id = this.$session.get("client_id")
        setTimeout(function () {
                _this.$router.push({ name: 'businessobject', query: _this.$route.query})
            }, 90);
        
    },
}
</script>
