const jointObj = require('jointjs')

import * as constantObj from './constants.js'

export function addShadowToElement(){
	return {
        name: 'dropShadow',
        args: {
            dx: 1,
            dy: 1,
            blur: 2
        }
	}
}

export function getWrappedText(text,width,joint,lines=1,fontsize=10){

	 let revisedText = joint.util.breakText(text, {
            width: (width*2)+ (width*.3),
        	height: 25*lines
        },
        	{maxLineCount:lines},
        	{ellipsis:true},
            {'font-size':fontsize}
        );
    return revisedText
}

export function getNearestGridSnapPosition(position){
    // this function will give you nearest snap position for the control.
    // for example if position= 454 then function will return 460 if Grid size is 10, 455 if grid size is 5.
    // WE MIGHT NEED TO CONSIDER BORDER LINE.BUT NOW ITS NOT NECCESORY

    if(!position)
        return constantObj.GRID_SIZE
    let snapMisedBy = (position % constantObj.GRID_SIZE)
    if(snapMisedBy === 0)
        return position
    let newPosition = position + (constantObj.GRID_SIZE-snapMisedBy)
    return newPosition

}

export function getSuitableFontSize(text){
    var fontSize = 10
    var wordLength = text.length
    if(wordLength <= 10)
        return fontSize = 14
    if(wordLength<=12)
        return fontSize = 13
    if(wordLength <=14)
        return fontSize = 12
    if(wordLength <=16)
        return fontSize = 11
    return fontSize
}

export function getEmbeddedCell(element){
    let embeddedCells = element.getEmbeddedCells()
    if(!embeddedCells.length)
        return element
    return embeddedCells[0]
}


export async function PerformSmartRouting(graph,cell,paper,paperSmall){
    console.log("smart routing called")
    var listOfElements = graph.getElements()
    var listOfLinks = graph.getLinks()
    if (_.indexOf(listOfElements, cell) > -1) {
        _.forEach(listOfLinks, function(link) {
                paper.findViewByModel(link).update();
                if(paperSmall)
                    paperSmall.findViewByModel(link).update();
        });
    }
}

export function updateParentWrapperText(element,text){
    if(!text)
        text = element.prop('stepname')
    let parentCell = element.getParentCell()
    parentCell.attr('label/title',text)
    let wrappedText = getWrappedText(text, element.size().width,jointObj,2,10)
    parentCell.attr('label/text',wrappedText)
}

export function addTableDescription(description_list,graph){
    let elementList = graph.getElements()
    elementList.forEach(element=>{
        if(element.prop('is_special_cell') || !element.prop('is_embed') || element.prop('is_drv_table') || 
            element.prop('table_name') != element.prop('table_description') 
            || element.prop('table_type') !='table')
            return
        
        let descObj = _.find(description_list,['title',element.prop('table_name')])
        if(!_.isEmpty(descObj)){
            element.prop('table_description',descObj.description)
            updateParentWrapperText(element,descObj.description)
        }
    })
}