var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{EDCHeaderContainer:!_vm.isGrayTheme,EDCHeaderContainerGray:_vm.isGrayTheme}},[_c('v-row',{staticClass:"mb-12 topLines",attrs:{"align":"center","dense":"","no-gutters":""}},[(!_vm.isGrayTheme)?_c('v-col',{staticClass:"EDC-PageHeader primary--text",staticStyle:{"text-align":"left !important"},attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.designerFor)+" ")]):_c('v-col',{staticClass:"EDC-PageHeader graythmprimary--text",staticStyle:{"text-align":"left !important"},attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.designerFor)+" ")]),_c('v-col',{attrs:{"cols":"7"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{staticClass:"mb-12",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticStyle:{"text-align":"right !important","padding-right":"20px !important"},attrs:{"cols":"11"}},[(!_vm.hideHeader)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"elevation":"0","fab":"","text":"","x-small":"","id":"smallbtn"}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"My Notifications","id":"smallicon"}},[_vm._v("notifications_active")])])]}}],null,false,2810276592)}):_vm._e(),(!_vm.hideHeader)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","text":"","x-small":"","id":"smallbtn"}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"My Settings","id":"smallicon"}},[_vm._v("language")])])]}}],null,false,2224655002)}):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","text":"","x-small":"","id":"smallbtn","title":_vm.getUserTitle()}},on),[_c('span',{class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"id":"profile"}},[_vm._v(_vm._s(_vm.initials))])])]}}])},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[(!_vm.hideHeader)?_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Edit Profile"},on:{"click":function($event){return _vm.routeUrl('/userprofile')}}},[_vm._v("Edit Profile")])],1):_vm._e(),(!_vm.hideHeader)?_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Change Password"},on:{"click":function($event){return _vm.routeUrl('/changepassword')}}},[_vm._v("Change Password")])],1):_vm._e(),_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Sign out"},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Sign out")])],1)],1)],1),(!_vm.hideHeader)?_c('v-menu',{attrs:{"offset-x":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"border":"0px !important"},attrs:{"fab":"","text":"","x-small":"","id":"smallbtn"}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"Applications","id":"smallicon"}},[_vm._v("apps")])])]}}],null,false,4279851092)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"archivistproduct"},on:{"click":function($event){return _vm.redirectProduct('Archivist')}}},[_vm._v("Archivist")])],1)],1)],1):_vm._e()],1)],1)],1),_c('v-snackbar',{attrs:{"right":true,"top":true,"timeout":_vm.snackbartimeout,"color":_vm.colorValue},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbartext)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }