<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>User Profile</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form ref="form" v-model="valid">
        <v-card elevation-1>
          <v-container class="EDC-Container">
            <v-col cols="12" class="EDC-Col">
              <v-row class="EDC-Row" style="padding-top:20px !important">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="First Name" v-model="userData.first_name" :rules="firstNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Last Name" v-model="userData.last_name" :rules="lastNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Email" v-model="userData.username" disabled dense></v-text-field>
                </v-col>
              </v-row>

              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-autocomplete clearable label="Time Zone" v-model="userData.time_zone_preference" :rules="timeZoneRules" :items="timeZoneItems" dense></v-autocomplete>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-autocomplete clearable label="Date Format" v-model="userData.date_format" :rules="dateFormatRules" :items="date_formatitems" item-value="python_format" item-text="user_format" dense></v-autocomplete>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Role" v-model="user_role" disabled dense></v-text-field>
                </v-col>
              </v-row>

              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-autocomplete multiple label="Environment list" v-model="env_list" :items="mapped_env_list" item-text="name" disabled dense></v-autocomplete>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-row class="EDC-Row">
                    <v-col cols="2" class="EDC-Col EDC-RadioCheckboxLabelPadding">
                      <label class="v-label" style="float:left" dense>Theme:</label>
                    </v-col>
                    <v-col cols="2" class="EDC-Col">
                      <toggle-button :labels="{checked: 'Normal', unchecked: 'Dark'}" :color="{'checked':'#9B9B9B','unchecked':'#607D8B'}" :width="70" :height="28" v-model="theme_name" style="font-size:10px !important; margin-top: 3px !important" :sync="true" @change="themeChanged"></toggle-button>
                    </v-col>
                    <v-col cols="8" class="EDC-Col"></v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" class="EDC-Col"></v-col>
              </v-row>

              <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
                <vc-button itemText="Save" @click.native="submit"></vc-button>
                <vc-button itemText="Cancel" @click.native="$router.go(-1)"></vc-button>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-form>
    </v-container>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import config from '../../config.json'
import Breadcrumb from "../Breadcrumbs.vue"
import {
  post as postToServer
} from './../../methods/serverCall.js';
import {
  get as getToServer
} from './../../methods/serverCall.js';
import {
  GET_USER, UPDATE_PROFILE,UPDATE_TOKEN_DETAILS, GET_USER_ROLE
} from '../../data/url_constants.js'
import {
  SERVER_ERROR
} from '../../data/client_message.js'
import {
  COLOR_CODE,
  BTN_COLOR
} from '../../data/macros.js'
import {
  DATE_TIME_FORMAT,GetUIDateFormat
} from '@/data/manage_account_constants.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {mapActions} from 'vuex'
var moment = require("moment-timezone");
export default {
  name: 'UserProfile',
  mounted() {
    this.getUserByid();
    this.getUserRoleByidClientid();
    this.mapped_env_list = this.$session.get('env_list')
    this.env_list = this.mapped_env_list
  },
  data() {
    return {
      theme_name: true,
      date_formatitems: DATE_TIME_FORMAT,
      outlineColor: BTN_COLOR,
      colorCode: COLOR_CODE,
      testConnectionLoader: false,
      valid: false,
      snackbar: false,
      snackbartext: '',
      snackbartimeout: Snackbar_Default_Timeout,
      colorValue: 'error',
      loader: "loading",
      loading: false,
      timeZoneItems: moment.tz.names(),
      userData: {
        'username': this.$session.get('email'),
        'client_id': this.$session.get('client_id'),
        'first_name': "",
        'last_name': "",
        'userid': this.$session.get('user_id'),
        'full_name': "",
        'time_zone_preference': "",
        'date_format': "DD-MM-YYYY"
      },
      firstNameRules: [v => !!v || 'First Name is required.'],
      lastNameRules: [v => !!v || 'Last Name is required.'],
      timeZoneRules: [v => !!v || 'TimeZone is required.'],
      dateFormatRules: [v => !!v || 'Date Format is required.'],
      user_role: '',
      env_list: [],
      mapped_env_list: []
    }
  },
  components: {
    Breadcrumb
  },
  props: {
    msg: String
  },
  methods: {
    ...mapActions(['setthemeInfo']),
    themeChanged(item){
      if(item.value)
      this.setthemeInfo('light')
      else
        this.setthemeInfo('dark')
    },
    getUserByid() {
      getToServer(this, config.USER_PROVISION_URL + GET_USER + this.$session.get('user_id')).then(response => {
        this.userData = response;
        if(response.theme_name == 'light'){
          this.theme_name = true
        }else{
          this.theme_name = false
        }
      }).catch(e => {
        console.log('ERROR occured')
        console.log(e)
      })
    },
    getUserRoleByidClientid() {
      let url = config.Security_URL + GET_USER_ROLE;
      var data = {'client_id': this.$session.get('client_id'), 'user_id': this.$session.get('user_id')}
        postToServer(this, url, data).then(response  => {
          this.user_role = response.charAt(0).toUpperCase()+response.slice(1)
          if(this.user_role == "Superadmin"){
            this.mapped_env_list = ["All"]
            this.env_list = this.mapped_env_list
          }
      }, (response) => {
      }).catch(e => {
        console.log('error occured')
        console.log(e)
      })
    },
    onCancel() {
      this.$router.push("/dashboard")
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.updateUserData();
      }
    },
    updateUserData() {
        var data_to_update = {"user_id": this.userData.user_id, "other_user_name": this.userData.other_user_name, "first_name": this.userData.first_name, "last_name": this.userData.last_name, "time_zone_preference": this.userData.time_zone_preference, "date_format": this.userData.date_format}
        this.userData.full_name = this.userData.first_name +' '+this.userData.last_name;
        var theme_details = this.theme_name ? 'light':'dark';
        this.userData.theme_name = theme_details;
        let url = config.USER_PROVISION_URL + UPDATE_PROFILE;
        postToServer(this, url, this.userData
        ).then(response  => {
          this.$session.set('user_name', response.full_name)
          this.$session.set('date_format', response.date_format)
          this.$session.set('time_zone_preference', response.time_zone_preference)
          // in backend we save python date format. To convert that date format into UI date format we use below function
          var UI_date_format = GetUIDateFormat(response.date_format)
          this.$session.set('UI_date_format',UI_date_format)
          this.$session.set('theme_name', response.theme_name)
          this.UpdateTokenDetails()
      }).catch(e => {
        console.log('error occured')
        console.log(e)
      })
    },
    UpdateTokenDetails(){
      var data ={
        "time_zone_preference": this.$session.get('time_zone_preference'),
        "clientId": this.$session.get('client_id'),
        "phone":this.$session.get('phone'),
        "access_token":this.$session.get('access_token'),
        "userId":this.$session.get('user_id'),
        "clientName": this.$session.get('client_name'),
        "username":this.$session.get('email'),
        "date_format":this.$session.get('date_format'),
        "lastName": this.userData.last_name,
        "firstName": this.userData.first_name,
      }
       postToServer(this, config.AUTHENTICATION_URL + UPDATE_TOKEN_DETAILS, data
        ).then(response  => {

        })
          setTimeout(function(){
            window.location.href = "/dashboard"
          }, 1000);
    },
  }
}
</script>

<style>
  .label-margin label{
    margin-top:5%;
  }
</style>
