/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Delete': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g _fill="none" _stroke="#000" stroke-width="2"><path pid="0" d="M5.04 14.2H58.8" stroke-linecap="round"/><path pid="1" d="M22.96 14.2V5.24h17.92v8.96" stroke-linejoin="round"/><path pid="2" d="M14 23.16V59h35.84V23.16" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" d="M25.2 27.64v22.4M38.64 27.64v22.4"/></g>'
  }
})
